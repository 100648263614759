import { FC, CSSProperties } from 'react';

import { Toaster } from 'react-hot-toast';

interface Props {
   containerStyle?: CSSProperties;
}

/* istanbul ignore next */
export const NotificationProvider: FC<Props> = (props) => <Toaster {...props} />;
