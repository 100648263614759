import { FC, useEffect, useState } from 'react';

import { LocalizedRoutes } from '@ravago/shared/page-data/models/pages';
import { DataCy, getCountryCode, getLanguageCode, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Language } from '../../../services/contentful/locale.service';

interface Props {
  locale: string;
  languages: Array<Language>;
  localizedRoutes?: LocalizedRoutes;
}

const LanguageSwitch: FC<Props> = ({ languages, localizedRoutes, locale }: Props) => {
  const translate = useTranslations();
  const languageCode = getLanguageCode(locale);
  const countryCode = getCountryCode(locale);

  const [languageOptions, setLanguageOptions] = useState<DropdownOption[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownOption | undefined>(undefined);

  const handleLanguageSelect = (languageOption?: DropdownOption) => {
    if (!languageOption) return;

    setSelectedLanguage(languageOption);

    window.location.replace(localizedRoutes ? `/${languageOption.id}-${countryCode}/${localizedRoutes[languageOption.id]}` : `/${languageOption.id}-${countryCode}`);
  };

  useEffect(() => {
    setLanguageOptions(
      languages
        .map((language) => ({
          id: language.shortName,
          value: language.fullName
        }))
        .sort((a, b) => a.value.localeCompare(b.value))
    );
  }, [languages]);

  useEffect(() => {
    setSelectedLanguage(languageOptions.find((option) => option.id === languageCode));
  }, [languageOptions]);

  return (
    <Box direction="column" gap="sm">
      <Typography variant="body-highlight" color="strong">
        {translate('page-consumer.header.language-selector.header')}
      </Typography>

      <Dropdown id="language-selector" attributes={{ ...DataCy('language-switch-dropdown') }} emptyOption={false} label={translate('page-consumer.header.language-selector.label')} value={selectedLanguage} options={languageOptions} onChange={handleLanguageSelect} />
    </Box>
  );
};

export default LanguageSwitch;
