'use client';

import { FC, useEffect, useState } from 'react';

import { ContentfulMarketSegment, DynamicPageContentType } from '@ravago/shared/page-data/models/contentful';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { groupBy } from '@ravago/utils';

import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';

interface Props {
   segments: Array<ContentfulMarketSegment>;
   locale: string;
   getDynamicPageUrls: (locale: string, pageType: DynamicPageContentType[]) => Promise<{ [key in DynamicPageContentType]?: string }>;
}

interface IndustrySegments {
   industry: {
      name: string;
      slug: string;
      seoMetadata?: {
         noFollow?: boolean;
      };
   };
   segments: ContentfulMarketSegment[];
}

export const IndustriesSegment: FC<Props> = ({ segments, locale, getDynamicPageUrls }) => {
   const [industrySegments, setIndustrySegments] = useState<IndustrySegments[]>([]);
   const [baseRoute, setBaseRoute] = useState<string>('');

   useEffect(() => {
      const industryGroups = groupBy(segments, (s) => s.industry?.name);
      setIndustrySegments(
         Object.keys(industryGroups).map((key) => ({
            industry: industryGroups[key][0].industry!,
            segments: industryGroups[key]
         }))
      );
      const fetchData = async () => {
         const dynamicPageurls = await getDynamicPageUrls(locale, ['industry']);
         setBaseRoute(dynamicPageurls.industry || '');
      };
      fetchData();
   }, [getDynamicPageUrls, locale, segments]);

   return (
      <Box direction="column" gap="xs">
         {industrySegments.map((marketSegment) => (
            <Box direction="column" gap="3xs" key={marketSegment.industry.slug}>
               <DynamicPageLink
                  id={`dynamic-link__industries-segments__${marketSegment.industry.slug}`}
                  slug={marketSegment.industry.slug}
                  baseRoute={{ url: baseRoute, noFollow: marketSegment.industry?.seoMetadata?.noFollow }}
                  locale={locale}
               >
                  <Typography variant="body" component="span" color="primary">
                     {marketSegment?.industry?.name}
                  </Typography>
               </DynamicPageLink>
               <Typography component="p" variant="body">
                  {marketSegment.segments.map((p) => p.name).join(' • ')}
               </Typography>
            </Box>
         ))}
      </Box>
   );
};
