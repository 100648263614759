import { FC, useMemo } from 'react';

import { FlyoutProps, FlyoutSteps, RfqStep } from '@ravago/shared/page-data/models/features';
import { useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { PhoneDetails } from '@ravago/shared/radiance/components/input-field/components/PhoneInputField/PhoneInputField.types';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { RequestForQuotationState } from 'libs/shared/page-elements-radiance/src/lib/reducers/request-for-quotation-reducer';

import RfqAnonymousLoginIllustration from '../../../../../../../public/illustrations/rfq-anonymous-login-illustration';
import { checkIfUserExists } from '../../../../../../actions';
import RfqImage from '../fields/rfq-image';

interface VerifyEmailProps extends FlyoutProps {
  requestForQuotationState: RequestForQuotationState;
  email: string;
  selectedPackagingGroup?: DropdownOption;
  quantities: number[];
  onSetUserSteps: (steps: RfqStep[], currentStep: RfqStep) => void;
  onContactDetailsChange: (field: string, value: string | PhoneDetails | DropdownOption | boolean) => void;
}

const emailRegex = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,4}$/;

const RfqFlyoutStepVerifyEmail: FC<VerifyEmailProps> = ({ onSetUserSteps, phone, onContactDetailsChange, onPreviousStep, email, requestForQuotationState, selectedPackagingGroup, quantities }) => {
  const translate = useTranslations();

  const hasEmailError = useMemo(() => {
    if (!email) return false;
    return !emailRegex.test(email);
  }, [email]);

  const updateUrlToNavigateBackAfterLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);

    if (requestForQuotationState.color?.colorName) {
      searchParams.set('color', requestForQuotationState.color?.colorName);
    }
    if (requestForQuotationState.color?.producerColorCode) {
      searchParams.set('colorCode', requestForQuotationState.color?.producerColorCode);
    }
    if (requestForQuotationState.color?.desiredColor) {
      searchParams.set('desiredColor', requestForQuotationState.color.desiredColor);
    }
    if (selectedPackagingGroup?.value) {
      searchParams.set('packaging', selectedPackagingGroup.value);
    }
    if (quantities.length > 0) {
      searchParams.set('quantities', quantities.join('-'));
    }
    if (searchParams.has('color') && searchParams.has('packaging') && searchParams.has('quantities')) {
      searchParams.set('flyout', 'open');
    }

    const path = window.location.href.split('?')[0];
    const newURL = `${path}?${searchParams.toString()}`;
    window.history.pushState(null, '', newURL);
  };

  const handleVerifyEmailClick = async () => {
    if (!hasEmailError) {
      const userExists = await checkIfUserExists(email);
      if (userExists) {
        updateUrlToNavigateBackAfterLogin();
        const recognizedAnonymousUserSteps: FlyoutSteps = [RfqStep.QUOTATION_DETAILS, RfqStep.VERIFY_EMAIL, RfqStep.RECOGNIZED_EMAIL];
        onSetUserSteps(recognizedAnonymousUserSteps, RfqStep.RECOGNIZED_EMAIL);
      } else {
        const anonymousUserSteps: FlyoutSteps = [RfqStep.QUOTATION_DETAILS, RfqStep.VERIFY_EMAIL, RfqStep.CONTACT_DETAILS, RfqStep.DELIVERY_AREA, RfqStep.SUMMARY, RfqStep.FINAL_MESSAGE];
        onSetUserSteps(anonymousUserSteps, RfqStep.CONTACT_DETAILS);
      }
    }
  };

  const handleEmailChange = (value: string) => {
    onContactDetailsChange('email', value);
  };

  return (
    <Box direction="column">
      <RfqImage>
        <RfqAnonymousLoginIllustration />
      </RfqImage>
      <Typography component="span" variant="body" color="strong">
        {translate('page-consumer.request-for-quotation.verify-email-description')}
      </Typography>
      <InputField type="email" label={translate('page-consumer.request-for-quotation.contact-info.email-address')} id="rfq-verify-email-address-input" maxLength={50} value={email} onChange={handleEmailChange} />
      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" items="center" width="100%">
                <Button variant="secondary" onClick={onPreviousStep} leadingIcon={!phone ? 'long-arrow-left' : ''} fullWidth>
                  {translate('page-consumer.request-for-quotation.back')}
                </Button>
                <Button variant="primary" disabled={!email || hasEmailError} onClick={handleVerifyEmailClick} trailingIcon={!phone ? 'long-arrow-right' : ''} fullWidth id="rfq-verify-email-btn">
                  {translate('page-consumer.request-for-quotation.continue')}
                </Button>
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default RfqFlyoutStepVerifyEmail;
