import { FC } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { SystemPageLink, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';

interface Props {
  locale: string;
  shipmentIds: number[];
  baseRoute: string;
}
const ShipmentsInfo: FC<Props> = ({ locale, shipmentIds, baseRoute }) => {
  const translate = useTranslations();

  return (
    <Table rowDividers={false} border rounded stroked="horizontal">
      {shipmentIds.map((shipmentId) => (
        <TableRow key={shipmentId}>
          <TableCell>
            {translate('page-consumer.order-line-flyout.shipment')} {shipmentId}
          </TableCell>
          <TableCell>
            <SystemPageLink baseRoute={baseRoute} locale={locale} type={SystemPageType.SHIPMENT} slug={shipmentId.toString()}>
              <Box justify="end">
                <IconButton icon="arrow-right" />
              </Box>
            </SystemPageLink>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default ShipmentsInfo;
