import { FC } from 'react';

import classNames from 'classnames';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   badge?: string;
   disabled?: boolean;
   icon: string;
   label?: string;
   subText?: string;
   variant?: 'default' | 'primary';
   onClick?: () => void;
}

export const FabAction: FC<Props> = ({ icon, label, subText, disabled = false, variant = 'default', onClick, badge }) => {
   /* istanbul ignore next */
   const badgeVariant = () => {
      switch (variant) {
         case 'default':
            return 'primary';
         case 'primary':
            return 'secondary';
         /* istanbul ignore next */
         default:
            return 'primary';
      }
   };

   return (
      <button data-testid={`fab-action${variant !== 'default' ? `--${variant}` : ''}${disabled ? '--disabled' : ''}`} type="button" onClick={onClick} disabled={disabled} className="gap-component-input-fab-action-container-spacing-outer-gap group flex appearance-none items-center justify-end">
         {(label || subText) && (
            <Box direction="column" gap="none" items="end">
               {label && (
                  <div data-testid="fab-action--label" className="contents">
                     <Typography align="right" variant="body-highlight" color={disabled ? 'weak' : 'strong'}>
                        {label}
                     </Typography>
                  </div>
               )}
               {subText && (
                  <div data-testid="fab-action--subtext" className="contents">
                     <Typography align="right" variant="small" color={disabled ? 'weak' : 'subtle'}>
                        {subText}
                     </Typography>
                  </div>
               )}
            </Box>
         )}
         <div
            className={classNames(
               `flex relative transition-all items-center justify-center min-h-component-input-fab-action-container-sizing-min-size min-w-component-input-fab-action-container-sizing-min-size p-component-input-fab-action-container-spacing-padding rounded-component-input-fab-action-container-border-radius shadow-component-input-fab-action-container-elevation
              after:absolute after:inset-0 after:transition-all after:rounded-component-input-fab-action-container-border-radius`,
               variant === 'default' &&
                  'bg-component-input-fab-action-container-color-background-default text-component-input-fab-action-icon-color-fill-default-enabled group-disabled:text-component-input-fab-action-icon-color-fill-default-disabled group-disabled:bg-component-input-fab-action-state-layer-color-background-on-default-disabled',
               variant === 'default' &&
                  !disabled &&
                  'group-hover:after:bg-component-input-fab-action-state-layer-color-background-on-default-hover group-has-[:focus-visible]:after:bg-component-input-fab-action-state-layer-color-background-on-default-focused group-active:after:bg-component-input-fab-action-state-layer-color-background-on-default-pressed',
               variant === 'primary' &&
                  'bg-component-input-fab-action-container-color-background-primary text-component-input-fab-action-icon-color-fill-primary-enabled group-disabled:text-component-input-fab-action-icon-color-fill-primary-disabled group-disabled:bg-component-input-fab-action-state-layer-color-background-on-primary-disabled',
               variant === 'primary' &&
                  !disabled &&
                  'group-hover:after:bg-component-input-fab-action-state-layer-color-background-on-primary-hover group-has-[:focus-visible]:after:bg-component-input-fab-action-state-layer-color-background-on-primary-focused group-active:after:bg-component-input-fab-action-state-layer-color-background-on-primary-pressed'
            )}
         >
            <Icon icon={icon} color="inherit" />

            {badge && !disabled && (
               <div data-testid="fab-action--badge" className="absolute right-[calc(var(--component-input-fab-action-badge-container-spacing-padding-right)/2)] top-0 translate-x-1/2">
                  <Badge variant={badgeVariant()}>{badge}</Badge>
               </div>
            )}
         </div>
      </button>
   );
};
