import { useMemo } from 'react';

import { DisplayShoppingCartProblem } from '@ravago/shared/page-data/models/features';
import { useShoppingCartContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from 'libs/shared/page-elements-radiance/src/lib/providers/translation-client-context-provider';

const AddToShoppingCartValidation = () => {
  const translate = useTranslations();
  const { state: shoppingCartState, resetCartValidation } = useShoppingCartContext();

  const transformedErrors: DisplayShoppingCartProblem[] = useMemo(() => shoppingCartState.validationErrors.map((error) => ({ ...error, title: `${error.code}-title`, action: `${error.code}-action` })), [shoppingCartState.validationErrors]);

  return (
    <Box direction="column" gap="lg">
      <Box gap="md">
        <Icon icon="exclamation-triangle" />
        <Typography variant="heading-4">{translate(transformedErrors.length > 1 ? 'shopping-cart.validation.error-title' : transformedErrors[0].title)}</Typography>
      </Box>
      <Box direction="column">
        {transformedErrors.map((error) => (
          <Box key={error.code} direction="column" gap="md">
            <Typography>{translate(error.code, { productName: shoppingCartState.productName })}</Typography>
            <Typography>{translate('shopping-cart.validation.modal-add')}</Typography>
            <Typography>{translate(error.action)}</Typography>
          </Box>
        ))}
      </Box>
      <Button onClick={resetCartValidation}>{translate('shopping-cart.validation.modal-close')}</Button>
    </Box>
  );
};

export default AddToShoppingCartValidation;
