'use client';

import { FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { BottomContainerContext } from '../context/bottom-container-context';
import { initialState, reducer } from '../reducers/bottom-container-reducer';

export const BottomContainerProvider: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState);

   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

   return <BottomContainerContext.Provider value={contextValue}>{children}</BottomContainerContext.Provider>;
};
