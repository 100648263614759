import { FC } from 'react';

import { OrderDocumentType, OrderLineBOL, OrderLineDeliveryNote } from '@ravago/shared/page-data/models/elements';
import { DateUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Accordion } from '@ravago/shared/radiance/components/accordion/components/Accordion/Accordion';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  deliveryNotes: OrderLineDeliveryNote[];
  billsOfLading: OrderLineBOL[];
  isUSBusinessContext?: boolean;
  onDownload: (document: OrderLineDeliveryNote, type: OrderDocumentType) => void;
  onRequestDeliveryNote: (deliveryNote: OrderLineDeliveryNote) => void;
  onRequestBol: (bol: OrderLineBOL) => void;
}

const DeliveryNoteDocuments: FC<Props> = ({ deliveryNotes, billsOfLading, onDownload, onRequestDeliveryNote, onRequestBol, isUSBusinessContext = false }: Props) => {
  const translate = useTranslations();

  return (
    <Box direction="column" gap="xs">
      <Accordion
        open
        header={
          <Typography variant="body-highlight" component="span" color="strong">
            {isUSBusinessContext ? translate('page-consumer.order-line-flyout.bills-of-lading') : translate('page-consumer.order-line-flyout.delivery-notes')}
          </Typography>
        }
        body={
          deliveryNotes.length > 0 || billsOfLading.length > 0 ? (
            <>
              {deliveryNotes.map((deliveryNote, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} gap="none">
                  <Box direction="column" gap="sm" width="100%">
                    <Box gap="xl">
                      <Box direction="column" gap="none">
                        <Typography variant="body" component="span" color="subtle">
                          {translate('page-consumer.order-line-flyout.transport-order-number')}
                        </Typography>
                        <Typography variant="body" component="span">
                          {deliveryNote.transportOrderNr}
                        </Typography>
                      </Box>
                      <Box direction="column" gap="none">
                        <Typography variant="body" component="span" color="subtle">
                          {translate('page-consumer.order-line-flyout.document-reference')}
                        </Typography>
                        <Typography variant="body" component="span">
                          {deliveryNote.deliveryNoteNr}
                        </Typography>
                      </Box>
                    </Box>
                    <Box justify="between">
                      <Box direction="column" gap="none">
                        <Typography variant="body" component="span" color="subtle">
                          {translate('page-consumer.order-line-flyout.document-date')}
                        </Typography>
                        <Typography variant="body" component="span">
                          {deliveryNote.documentDate ? DateUtils.localizeDate(deliveryNote.documentDate) : '-'}
                        </Typography>
                      </Box>
                      <Box>
                        {deliveryNote.id ? (
                          <IconButton icon="download" onClick={() => onDownload(deliveryNote, 'Delivery Note')} />
                        ) : (
                          <Button variant="tertiary" onClick={() => onRequestDeliveryNote(deliveryNote)}>
                            {translate('page-consumer.order-line-flyout.request')}
                          </Button>
                        )}
                      </Box>
                    </Box>
                    {index < deliveryNotes.length - 1 && <Divider />}
                  </Box>
                </Box>
              ))}
              {billsOfLading.map((billOfLading, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} gap="none">
                  <Box direction="column" gap="sm" width="100%">
                    <Box gap="xl" justify="between">
                      <Box direction="column" gap="none">
                        <Typography variant="body" component="span" color="subtle">
                          {translate('page-consumer.order-line-flyout.transport-order-number')}
                        </Typography>
                        <Typography variant="body" component="span">
                          {billOfLading.transportOrderNr}
                        </Typography>
                      </Box>
                      <Box justify="end">
                        <Button variant="tertiary" onClick={() => onRequestBol(billOfLading)}>
                          {translate('page-consumer.order-line-flyout.request')}
                        </Button>
                      </Box>
                    </Box>
                    {index < billsOfLading.length - 1 && <Divider />}
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body" component="span">
              {translate('page-consumer.order-line-flyout.no-documents-available')}
            </Typography>
          )
        }
      />
    </Box>
  );
};

export default DeliveryNoteDocuments;
