import { FC, useMemo } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  timeframe?: DropdownOption;
  onSetTimeframe?: (timeframe: DropdownOption | undefined) => void;
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
}

const RfqTimeframeOptions: FC<Props> = ({ timeframe, onSetTimeframe, translate }: Props) => {
  const timeframeOptions = useMemo(
    () => [
      {
        id: 'ASAP',
        value: translate('page-consumer.request-for-quotation.timeframe.asap')
      },
      {
        id: 'WITHIN_10_DAYS',
        value: translate('page-consumer.request-for-quotation.timeframe.within-10-days')
      },
      {
        id: 'MORE_THAN_10_DAYS',
        value: translate('page-consumer.request-for-quotation.timeframe.more-10-days')
      },
      {
        id: 'NOT_LOOKING_TO_PURCHASE',
        value: translate('page-consumer.request-for-quotation.timeframe.not-looking')
      }
    ],
    [translate]
  );

  const handleTimeframeChange = (value: DropdownOption | undefined) => {
    onSetTimeframe?.(value);
  };

  return (
    <Box gap="md" direction="column">
      <Box direction="row" gap="2xs" items="center">
        <Typography component="span" variant="body-highlight">
          {translate('page-consumer.request-for-quotation.delivery-timeframe')}
        </Typography>
        <Tooltip align="start" pointers text={translate('page-consumer.request-for-quotation.tooltip.delivery-timeframe')}>
          <Box>
            <Icon icon="info-circle" />
          </Box>
        </Tooltip>
      </Box>
      <Dropdown required onChange={handleTimeframeChange} id="request-for-quotation-delivery-timeframe" value={timeframe} label={translate('page-consumer.request-for-quotation.select-delivery-timeframe')} emptyOption={false} options={timeframeOptions} />
    </Box>
  );
};

export default RfqTimeframeOptions;
