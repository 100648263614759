'use client';

import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   cols?: number;
   disabled?: boolean;
   error?: boolean;
   errorMessage?: string;
   id: string;
   label?: string;
   maxLength?: number;
   onChange?: (value: string) => void;
   readOnly?: boolean;
   required?: boolean;
   rows?: number;
   supportText?: string;
   value?: string;
   width?: string | number;
}

export const InputArea: FC<Props> = ({ id, cols = 20, disabled = false, error = false, errorMessage, rows = 3, supportText, label, maxLength = 255, readOnly = false, required = false, value = '', width, onChange }) => {
   const [inputValue, setInputValue] = useState(value);
   const [focused, setFocused] = useState(false);

   const inputRef = useRef<HTMLTextAreaElement>(null);

   /* istanbul ignore next */
   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(event.target.value);
      onChange?.(event.target.value);
   };

   useEffect(() => setInputValue(value), [value]);

   return (
      <div data-testid={`input-area${error ? '--error' : ''}`} style={{ width }} className="gap-component-input-input-field-container-spacing-outer-gap flex w-full flex-col">
         <div className={`group relative cursor-text ${disabled ? 'pointer-events-none' : ''}`}>
            {label && (
               <label
                  data-testid="input-area-label"
                  htmlFor={id}
                  className={`absolute left-0 top-0 flex items-center transition-all cursor-pointer ml-component-input-input-field-container-spacing-padding-x bg-component-input-input-field-container-color-background px-component-input-input-field-label-spacing-padding-x max-w-[calc(var(--component-input-input-field-container-sizing-max-width)-2*var(--component-input-input-field-container-spacing-padding-left))]
                           ${!error && !disabled ? 'text-component-input-input-field-label-color-text-neutral-enabled-as-label' : ''}
                           ${!error && !disabled && focused ? /* istanbul ignore next */ 'text-component-input-input-field-label-color-text-neutral-focused' : ''}
                           ${disabled ? 'text-component-input-input-field-label-color-text-neutral-disabled' : ''}
                           ${error && !disabled ? 'text-component-input-input-field-label-color-text-error-enabled group-hover:text-component-input-input-field-label-color-text-error-hover' : ''}
                           ${focused ? /* istanbul ignore next */ 'h-[var(--component-input-input-field-container-border-width-focused)]' : /* istanbul ignore next */ 'h-[var(--component-input-input-field-container-border-width-enabled)]'}
                      `.replace(/\s+/g, ' ')}
               >
                  <Typography variant="caption" color="inherit" maxLines="1">
                     {label}
                     {required && '*'}
                  </Typography>
               </label>
            )}

            <div
               data-testid="input-area-container"
               onClick={/* istanbul ignore next */ () => inputRef.current?.focus()}
               role="presentation"
               className={`min-h-component-input-input-field-container-sizing-min-height px-component-input-input-field-container-spacing-padding-x py-component-input-input-field-container-spacing-padding-y gap-component-input-input-field-container-spacing-gap bg-component-input-input-field-container-color-background rounded-component-input-input-field-container-border-radius border-component-input-input-field-container-border-width-enabled focus-within:!border-component-input-input-field-container-border-width-focused box-border flex items-start justify-between transition-all
                           ${
                              error && !disabled
                                 ? '!border-component-input-input-field-container-color-border-error-enabled hover:!border-component-input-input-field-container-color-border-error-hover focus-within:!border-component-input-input-field-container-color-border-error-enabled'
                                 : '!border-component-input-input-field-container-color-border-neutral-enabled hover:!border-component-input-input-field-container-color-border-neutral-hover focus-within:!border-component-input-input-field-container-color-border-neutral-focused'
                           }
                  `.replace(/\s+/g, ' ')}
            >
               <div className="flex h-full flex-1 items-center">
                  <textarea
                     data-testid="input-area-input"
                     className="text-component-input-input-field-input-color-text-on-surface-enabled disabled:text-component-input-input-field-input-color-text-on-surface-disabled typography-component-typography-body-regular-md min-h-component-input-text-area-container-sizing-min-height w-full bg-transparent outline-none"
                     disabled={disabled}
                     id={id}
                     maxLength={maxLength}
                     name={id}
                     onBlur={/* istanbul ignore next */ () => setFocused(false)}
                     onChange={handleChange}
                     onFocus={/* istanbul ignore next */ () => setFocused(true)}
                     readOnly={readOnly}
                     ref={inputRef}
                     required={required}
                     value={inputValue}
                     rows={rows}
                     cols={cols}
                  />
               </div>
            </div>
         </div>
         {supportText && (
            <div
               data-testid="input-area-support-text"
               className={`pl-component-input-input-field-supporting-text-spacing-padding-left gap-component-input-input-field-supporting-text-spacing-gap flex items-center

                        ${!disabled ? 'text-component-input-input-field-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-input-field-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
         {error && errorMessage && (
            <div data-testid="input-area-error-message" className="pl-component-input-input-field-supporting-text-spacing-padding-left gap-component-input-input-field-supporting-text-spacing-gap text-component-input-input-field-supporting-text-color-text-error flex items-center">
               <Icon icon="exclamation-circle" size="caption" />
               <Typography variant="caption" color="inherit">
                  {errorMessage}
               </Typography>
            </div>
         )}
      </div>
   );
};
