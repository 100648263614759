import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { PhoneDetails } from '@ravago/shared/radiance/components/input-field/components/PhoneInputField/PhoneInputField.types';

import { Customer } from './customer.model';

export type RequestType = 'QUOTATION';
export type PreferredDeliveryTimeframe = 'ASAP' | 'WITHIN_10_DAYS' | 'MORE_THAN_10_DAYS' | 'NOT_LOOKING_TO_PURCHASE';

export interface QuotationLines {
  productId: number;
  quantity: {
    amount: number;
    unitOfMeasure: string;
  };
  preferredPackagingGroupId: number;
  otherPackagingInfo: string;
  colorId: number;
  desiredColor?: string;
  producerColorCode?: string;
}

export interface VerifiedRequestForQuotation {
  type: RequestType;
  preferredDeliveryTimeframe: PreferredDeliveryTimeframe;
  additionalInfo: string;
  deliveryAddressId: number;
  quotationRequestLines: QuotationLines[];
}

export interface GuestRequestForQuotation {
  preferredDeliveryTimeframe: PreferredDeliveryTimeframe;
  additionalInfo: string;
  deliveryAddress: RfqDeliveryAddress;
  contactInfo: RfqContactInfo;
  quotationRequestLines: QuotationLines[];
}

export interface RfqDeliveryAddress {
  country: string;
  city: string;
  postalCode: string;
}

export interface RfqContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  phoneNumber: string;
  company: RfqCompanyInfo;
}

export interface RfqCompanyInfo {
  name: string;
  countryIsoCode: string;
}

export interface FlyoutProps {
  title?: string;
  phone: boolean;
  handleCloseFlyout: () => void;
  currentStep: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export interface QuantityProps {
  addQuantity: (quantity?: string) => void;
  removeQuantity: (index: number) => void;
  quantities: number[];
  newQuantity: string;
  handleNewQuantityChange: (value: string) => void;
}

interface DropdownOption {
  id: string;
  value: string;
  supportingText?: string;
}

export interface PackagingProps {
  packagingOptions: DropdownOption[];
  selectedPackagingGroup?: DropdownOption;
  otherPackagingDescription: string;
  handleOtherPackagingDescriptionChange: (value: string) => void;
  handlePackagingChange: (value?: DropdownOption) => void;
}

export interface ErrorProps {
  hasError: boolean;
  errorText: string;
}

export interface CustomerProps {
  customer?: Customer;
  customerDeliveryAddress?: Address;
  handleAddressChange?: (address: Address) => void;
  isDeliveryAddressExpanded?: boolean;
}

export interface ContactDetails {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  country: DropdownOption;
  phone: PhoneDetails;
  termsAndConditions: boolean;
}

export interface DeliveryArea {
  country: DropdownOption;
  city: string;
  postalCode: string;
}

export interface AdditionalInfoProps {
  additionalInfo: string;
  handleAdditionalInfoChange: (value: string) => void;
}

export type FlyoutSteps = Array<RfqStep>;

export enum RfqStep {
  QUOTATION_DETAILS = 'quotation_details',
  VERIFY_EMAIL = 'verify_email',
  RECOGNIZED_EMAIL = 'recognized_email',
  CONTACT_DETAILS = 'contact_details',
  ADDRESS_DETAILS = 'address_details',
  DELIVERY_AREA = 'delivery_area',
  SUMMARY = 'summary',
  FINAL_MESSAGE = 'final_message'
}
