'use client';

import { FC, ReactElement } from 'react';

import { useBottomContainerContext } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { NotificationProvider } from '@ravago/shared/radiance/providers/notifications/NotificationProvider';

interface Props {
  widget: ReactElement;
}

const BottomContainer: FC<Props> = ({ widget }) => {
  const { state, dispatch } = useBottomContainerContext();
  const bottomSheet = state.bottomSheet;

  const handleClose = () => {
    dispatch?.({ type: 'close_bottom_sheet' });
  };

  return (
    <>
      <NotificationProvider
        containerStyle={{
          zIndex: 0,
          bottom: bottomSheet ? 180 : 80
        }}
      />

      <div style={{ position: 'fixed', bottom: bottomSheet ? 92 : 0, left: 0, right: 0 }}>{widget}</div>

      {bottomSheet && (
        <BottomSheet viewport container={false} closeOnOutsideClick={bottomSheet.options?.closeOnOutsideClick} expanded={bottomSheet.expanded} header={bottomSheet.header} onClose={handleClose}>
          {bottomSheet.children}
        </BottomSheet>
      )}
    </>
  );
};

export default BottomContainer;
