'use client';

import { useEffect, useMemo, useState } from 'react';

import { ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { useDialogContext, useDocumentShare, useProductCompare, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { FabAction } from '@ravago/shared/radiance/components/fab/components/FabAction/FabAction';
import { FabMenu } from '@ravago/shared/radiance/components/fab/components/FabMenu/FabMenu';
import { useAuthentication } from '@ravago/shared/authentication';

import { getDynamicPageUrls, getProductByEntryId } from 'apps/next-page-consumer/app/actions';
import { addDays } from 'date-fns';
import { createPortal } from 'react-dom';

import useFeaturePermissions from '../../../hooks/use-feature-permissions';

import ProductCompareDialog from './product-compare-dialog';

interface Props {
  feedbackUrl: string | undefined;
  locale: string;
}

const Widget = ({ feedbackUrl, locale }: Props) => {
  const translate = useTranslations();
  const { featurePermissions } = useFeaturePermissions();
  const { dispatch } = useDialogContext();

  const { user, mail } = useAuthentication();

  const { shareDocumentList, setDownloadList } = useDocumentShare();
  const { productsToCompareList, removeAllProductsFromCompareList } = useProductCompare();

  const showFeedback = feedbackUrl && featurePermissions.hasTeamdeskFeedbackFeaturePermission;
  const showSharePage = featurePermissions.hasShareFeaturePermission;
  const showProductCompare = featurePermissions.hasProductCompareFeaturePermission;

  const [teamdeskFeedbackUrl, setTeamdeskFeedbackUrl] = useState(feedbackUrl);
  const [showProductCompareDialog, setShowProductCompareDialog] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [widgetLabel, setWidgetLabel] = useState(translate('page-consumer.seller-widget.tools'));
  const [widgetBadge, setWidgetBadge] = useState('');
  const [widgetIcon, setWidgetIcon] = useState('tools');
  const [compareProducts, setCompareProducts] = useState<ContentfulProduct[]>([]);

  const totalComparisonProducts = productsToCompareList.length;

  const getLocalStorageWarningRemover = () => {
    const previousTimeStampString = localStorage.getItem('hideInteralDocumentWarning');
    const currentTimeStamp = new Date();
    if (!previousTimeStampString) return false;
    const maxTimeStamp = addDays(new Date(previousTimeStampString), 15); // this number indicates 15 days, after that the warning will be shown again
    return currentTimeStamp < maxTimeStamp;
  };

  const compareSubText = useMemo(() => {
    if (totalComparisonProducts === 5) {
      return translate('page-consumer.product-compare.maximum-number-of-products-selected');
    }
    if (totalComparisonProducts > 0) {
      return `${totalComparisonProducts}/5 ${translate('page-consumer.seller-widget.selected')}`;
    }
    return translate('page-consumer.seller-widget.no-products-selected');
  }, [totalComparisonProducts, translate]);

  const shareSubText = useMemo(() => {
    if (shareDocumentList.documents.some((doc) => doc.isInternal)) {
      return translate('page-consumer.seller-widget.share-internal-subtext');
    }
    return undefined;
  }, [shareDocumentList.documents, translate]);

  const shareBadge = useMemo(() => shareDocumentList.documents.filter((doc) => !doc.isInternal).length.toString(), [shareDocumentList.documents]);

  useEffect(() => {
    if (feedbackUrl && mail) {
      setTeamdeskFeedbackUrl(feedbackUrl + mail);
    }
  }, [feedbackUrl, mail]);

  useEffect(() => {
    if (totalComparisonProducts > 0 && showProductCompare) {
      setWidgetBadge(`${totalComparisonProducts}/5`);
      setWidgetLabel(translate('page-consumer.seller-widget.start-comparison'));
      setWidgetIcon('balance-scale');
      return;
    }
    if (shareDocumentList.documents.length > 0 && showSharePage) {
      setWidgetBadge(shareBadge);
      setWidgetLabel(translate('page-consumer.seller-widget.share-docs'));
      setWidgetIcon('share-alt');
      return;
    }
    setWidgetBadge('');
    setWidgetLabel(translate('page-consumer.seller-widget.tools'));
    setWidgetIcon('tools');
  }, [totalComparisonProducts, translate]);

  useEffect(() => {
    if (shareDocumentList.documents.length > 0 && showSharePage) {
      setWidgetBadge(shareBadge);
      setWidgetLabel(translate('page-consumer.seller-widget.share-docs'));
      setWidgetIcon('share-alt');
      return;
    }
    if (totalComparisonProducts > 0 && showProductCompare) {
      setWidgetBadge(`${totalComparisonProducts}/5`);
      setWidgetLabel(translate('page-consumer.seller-widget.start-comparison'));
      setWidgetIcon('balance-scale');
      return;
    }
    setWidgetBadge('');
    setWidgetLabel(translate('page-consumer.seller-widget.tools'));
    setWidgetIcon('tools');
  }, [shareDocumentList, translate]);

  useEffect(() => {
    const fetchProducts = async () => {
      const productPromises = productsToCompareList.map((productId) => getProductByEntryId(locale, productId));
      const result = (await Promise.all(productPromises)) as ContentfulProduct[];
      setCompareProducts(result);
    };

    fetchProducts();
  }, [locale, productsToCompareList]);

  const onFeedbackClick = () => {
    setMenuOpen(false);
    if (teamdeskFeedbackUrl) window.open(teamdeskFeedbackUrl, '_blank');
  };

  const onProductCompareClick = () => {
    setMenuOpen(false);
    setShowProductCompareDialog(true);
  };

  const onSharePageClick = () => {
    setMenuOpen(false);
    if (dispatch) {
      dispatch({ type: 'open_dialog', value: { dialogType: 'share-page', title: 'Share Page' } });
    }
  };

  const onShareDocumentsClick = () => {
    setMenuOpen(false);
    if (dispatch) {
      dispatch({ type: 'open_dialog', value: { dialogType: 'share-documents' } });
    }
  };

  const onDownloadAllClick = () => {
    setMenuOpen(false);
    if (dispatch) {
      if (shareDocumentList.documents.some((doc) => doc.isInternal) && !getLocalStorageWarningRemover())
        dispatch({ type: 'open_dialog', value: { dialogType: 'internal-document-warning', totalAmountOfDocuments: shareDocumentList.documents.length, amountOfInternalDocuments: shareDocumentList.documents.filter((doc) => doc.isInternal).length } });
      else {
        setDownloadList(shareDocumentList.documents);
      }
    }
  };

  const onWidgetClick = () => {
    if (shareDocumentList.documents.length > 0 && widgetIcon === 'share-alt') onShareDocumentsClick();
    else if (totalComparisonProducts > 0 && widgetIcon === 'balance-scale') setShowProductCompareDialog(true);
    else setMenuOpen(true);
  };

  return (
    (showFeedback || showProductCompare || showSharePage) && (
      <>
        <FabMenu open={menuOpen} onClick={onWidgetClick} onToggle={setMenuOpen} label={widgetLabel} leadingIcon={widgetIcon} badge={widgetBadge} closeOnOutsideClick container={false}>
          {showFeedback && <FabAction label={translate('page-consumer.seller-widget.feedback')} icon="comment-alt" onClick={onFeedbackClick} />}
          {showSharePage && <FabAction label={translate('page-consumer.seller-widget.share-page-text')} icon="share-alt" onClick={onSharePageClick} />}
          {showProductCompare && (
            <FabAction
              label={totalComparisonProducts > 0 ? translate('page-consumer.seller-widget.start-comparison') : translate('page-consumer.seller-widget.product-comparison')}
              badge={totalComparisonProducts > 0 ? `${totalComparisonProducts}` : ''}
              icon="balance-scale"
              subText={compareSubText}
              disabled={totalComparisonProducts === 0}
              onClick={onProductCompareClick}
            />
          )}
          {showSharePage && shareDocumentList.documents.length > 0 && <FabAction variant="primary" badge={shareDocumentList.documents.length.toString()} label={translate('page-consumer.seller-widget.download-docs')} icon="download" onClick={onDownloadAllClick} />}
          {showSharePage && shareDocumentList.documents.length > 0 && <FabAction subText={shareSubText} variant="primary" badge={shareBadge} label={translate('page-consumer.seller-widget.share-docs')} icon="share-alt" onClick={onShareDocumentsClick} />}
        </FabMenu>

        {showProductCompareDialog &&
          createPortal(
            <ProductCompareDialog
              open={showProductCompareDialog}
              totalProducts={totalComparisonProducts}
              products={compareProducts}
              locale={locale}
              user={user}
              onDialogClose={() => setShowProductCompareDialog(false)}
              onRemoveSelection={removeAllProductsFromCompareList}
              getDynamicPageUrls={getDynamicPageUrls}
            />,
            document.body
          )}
      </>
    )
  );
};

export default Widget;
