import { getLanguageCode, useTranslations, useShoppingCartContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Link } from '@ravago/shared/radiance/components/link/components/Link/Link';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  locale: string;
}

const AddToShoppingCartSuccess = ({ locale }: Props) => {
  const { resetCartValidation } = useShoppingCartContext();

  const translate = useTranslations();
  const language = getLanguageCode(locale);

  return (
    <Box direction="column" gap="lg">
      <Typography variant="heading-4">{translate('shopping-cart.product-added')}</Typography>
      <Typography>{translate('shopping-cart.product-added-info')}</Typography>
      <Box direction="column" gap="xs">
        <Link fullWidth href={`/my/${language}/shopping-cart`}>
          <Button fullWidth leadingIcon="shopping-cart">
            {translate('shopping-cart.product-added-button-open')}
          </Button>
        </Link>
        <Button variant="secondary" leadingIcon="grip-horizontal" onClick={resetCartValidation}>
          {translate('shopping-cart.product-added-button-continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddToShoppingCartSuccess;
