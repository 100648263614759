import { useEffect, useRef, useState } from 'react';

export const useExpandOnScroll = (expandTimeoutDuration: number) => {
   const [isExpanded, setIsExpanded] = useState(false);
   const expandTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
   const collapseTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

   useEffect(() => {
      const handleScroll = () => {
         const { scrollY } = window;

         if (expandTimeoutRef.current) {
            clearTimeout(expandTimeoutRef.current);
            expandTimeoutRef.current = null;
         }

         if (collapseTimeoutRef.current) {
            clearTimeout(collapseTimeoutRef.current);
            collapseTimeoutRef.current = null;
         }

         if (scrollY === 0) {
            expandTimeoutRef.current = setTimeout(() => {
               setIsExpanded(true);
            }, expandTimeoutDuration);
         } else {
            collapseTimeoutRef.current = setTimeout(() => {
               setIsExpanded(false);
            }, expandTimeoutDuration);
         }
      };

      setIsExpanded(window.scrollY === 0);
      window.addEventListener('scroll', handleScroll);

      return () => {
         if (expandTimeoutRef.current) {
            clearTimeout(expandTimeoutRef.current);
         }
         if (collapseTimeoutRef.current) {
            clearTimeout(collapseTimeoutRef.current);
         }
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   return isExpanded;
};
