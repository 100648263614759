'use client';

import { FC, ReactNode, useEffect, useRef } from 'react';

import { Box } from '../../../box/components/Box/Box';
import { Expand } from '../../../expand/components/Expand/Expand';
import { Toolbar } from '../../../toolbar/components/Toolbar/Toolbar';

interface CollapseProps {
   body: ReactNode;
   fullWidth?: boolean;
}

interface Props {
   sticky?: boolean;
   expanded?: 'left' | 'right' | 'none';
   closeOnOutsideClick?: boolean;
   leftCollapse?: CollapseProps;
   rightCollapse?: CollapseProps;
   toolbars?: ReactNode;
   overflow?: 'auto' | 'visible';
   container?: boolean;
   onClose?: () => void;
}

export const Header: FC<Props> = ({ sticky = false, expanded = 'none', closeOnOutsideClick = true, leftCollapse, rightCollapse, toolbars, overflow = 'auto', container = false, onClose }) => {
   const toolbarRef = useRef<HTMLDivElement>(null);
   const leftCollapseRef = useRef<HTMLDivElement>(null);
   const rightCollapseRef = useRef<HTMLDivElement>(null);

   /* istanbul ignore next */
   const handleOutsideClick = (event: MouseEvent) => {
      if (!closeOnOutsideClick || expanded === 'none') return;

      if (toolbarRef.current && toolbarRef.current.contains(event.target as Node)) return;
      if (leftCollapseRef.current && leftCollapseRef.current.contains(event.target as Node)) return;
      if (rightCollapseRef.current && rightCollapseRef.current.contains(event.target as Node)) return;

      setTimeout(() => onClose?.(), 300);
   };

   useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => document.removeEventListener('click', handleOutsideClick);
   }, [expanded, closeOnOutsideClick]);

   return (
      <header data-testid={`header${sticky ? '--sticky' : ''}${expanded !== 'none' ? `--expanded-${expanded}` : ''}`} className={`z-[80] flex h-fit w-full flex-col ${sticky ? 'sticky inset-x-0 top-0' : 'relative'}`}>
         <div ref={toolbarRef} className="z-[60] flex flex-col">
            {toolbars}
         </div>

         {leftCollapse?.body && (
            <div data-testid={`header-left-${container ? 'contained' : 'full'}`} className={`${container ? 'container relative' : 'w-full'}`}>
               <div data-testid={`header-left-collapse${leftCollapse.fullWidth ? '--full-width' : ''}`} className="absolute inset-x-0 top-full transition-all">
                  <Expand expanded={expanded === 'left'}>
                     <Box spacing={{ x: 'lg', bottom: 'sm' }} overflowY="auto" width="100%" justify="start">
                        <div ref={leftCollapseRef} className={leftCollapse.fullWidth ? 'w-full' : ''}>
                           <Toolbar variant="dropdown" showGutter={false} left={leftCollapse.body} fullWidth={leftCollapse.fullWidth} />
                        </div>
                     </Box>
                  </Expand>
               </div>
            </div>
         )}
         {rightCollapse?.body && (
            <div data-testid={`header-right-${container ? 'contained' : 'full'}`} className={`${container ? 'container relative' : 'w-full'}`}>
               <div data-testid={`header-right-collapse${rightCollapse.fullWidth ? '--full-width' : ''}${overflow === 'visible' ? '--overflow-visible' : ''}`} className="absolute inset-x-0 top-full transition-all">
                  <Expand expanded={expanded === 'right'} overflow={overflow === 'visible' ? 'visible' : 'hidden'}>
                     <Box spacing={{ x: 'lg', bottom: 'sm' }} overflowY={overflow} width="100%" justify="end">
                        <div ref={rightCollapseRef} className={rightCollapse.fullWidth ? 'w-full' : ''}>
                           <Toolbar variant="dropdown" showGutter={false} right={rightCollapse.body} fullWidth={rightCollapse.fullWidth} overflow={overflow === 'visible' ? 'visible' : 'auto'} />
                        </div>
                     </Box>
                  </Expand>
               </div>
            </div>
         )}
      </header>
   );
};
