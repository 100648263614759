'use client';

import { FC, useState } from 'react';

import { useConfigContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Dialog } from '@ravago/shared/radiance/components/dialog/components/Dialog/Dialog';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useAuthentication } from '@ravago/shared/authentication';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { TechnicalSupport } from '../../../models/contentful/tech-knowledge.model';
import TechnicalSupportService from '../../../services/features/technical-support.service';

interface Props {
  open: boolean;
  data: Array<TechnicalSupport>;
  onDialogClose: () => void;
}

const TechKnowledgeBaseDialog: FC<Props> = ({ open, onDialogClose, data }) => {
  const translate = useTranslations();
  const { technicalSupport } = useConfigContext();
  const { accessToken } = useAuthentication();

  const [error, setError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

  const handleClose = () => {
    setError(false);
    setRedirectUrl(undefined);
    onDialogClose();
  };

  const getTechnicalSupportUrl = async (projectNumber: string) => {
    const url = await TechnicalSupportService.getTechnicalSupportUrl(technicalSupport.baseUrl, accessToken!, projectNumber);

    if (!url) {
      setError(true);
      return;
    }

    setRedirectUrl(url);
  };

  const onProceed = () => {
    if (!redirectUrl) return;
    window.open(redirectUrl, '_blank', 'noreferrer');
    setRedirectUrl(undefined);
    onDialogClose();
  };

  const onCancel = () => {
    setRedirectUrl(undefined);
  };

  return (
    <Dialog open={open} onDialogClose={handleClose} closeOnOutsideClick={false}>
      {error && (
        <Box direction="column" items="center">
          <svg>
            <use href="/illustrations/unreachable-link.svg#symbol" />
          </svg>
          <Typography variant="body" component="p" align="center">
            {translate('page-consumer.technical-support.error.information')}
          </Typography>
          <Button variant={'primary'} onClick={handleClose}>
            {translate('page-consumer.technical-support.error.close')}
          </Button>
        </Box>
      )}
      {data && !error && !redirectUrl && (
        <Box direction="column" gap="lg" items="center">
          <Typography variant="heading-4" component="h4" color="primary">
            {translate('page-consumer.technical-support.content.title')}
          </Typography>
          <Box direction="column" items="center">
            {data.map((item) => (
              <Box key={item.sys.id} direction="column" items="center" gap="xs">
                <button type="button" onClick={() => getTechnicalSupportUrl(item.projectNumber)}>
                  <Paper width="204px">
                    <Box direction="column" gap="2xs" items="center">
                      <Image src={item.picture.url} alt={item.picture.title ?? ''} title={item.picture.title ?? ''} />
                      <Typography align="center" variant="body-highlight" component="p" color="primary">
                        {item.title}
                      </Typography>
                    </Box>
                  </Paper>
                </button>
                {documentToReactComponents(item.description.json)}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {redirectUrl && (
        <Box direction="column" items="center">
          <Icon icon="info-circle" size="body" />
          <Typography align="center" variant="body" component="p">
            {translate('page-consumer.technical-support.message.information')}
          </Typography>
          <Box direction="column" gap="3xs">
            <Button variant="primary" onClick={onProceed}>
              {translate('page-consumer.technical-support.message.proceed')}
            </Button>
            <Button variant="tertiary" onClick={onCancel}>
              {translate('page-consumer.technical-support.message.cancel')}
            </Button>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default TechKnowledgeBaseDialog;
