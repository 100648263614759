import { FC } from 'react';

import { NavigationItem } from '@ravago/shared/page-data/models/config';
import { LoginButton, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Link } from '@ravago/shared/radiance/components/link/components/Link/Link';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { usePathname } from 'next/navigation';

import NavigationService from '../../../../services/navigation/navigation.service';

interface Props {
  locale: string;
  registrationLandingPage?: NavigationItem;
}

const LoginMenu: FC<Props> = ({ locale, registrationLandingPage }) => {
  const translate = useTranslations();

  const { phone } = useBreakpoint();

  const pathname = usePathname();

  const rlpHref = registrationLandingPage?.page ? `/${locale}/${registrationLandingPage.page.route ?? ''}` : registrationLandingPage?.externalUrl ?? '';

  return (
    <Box gap="sm" spacing={{ x: 'lg' }} direction="column" items="center" width={!phone ? '408px' : '100%'} height="100%">
      <LoginButton buttonLabel={translate('page-consumer.header.login')} language={NavigationService.getLanguage(locale)} variant="primary" fullwidth />
      {registrationLandingPage && (
        <>
          <Typography variant="small">{translate('page-consumer.header.or')}</Typography>
          <Link href={rlpHref === pathname ? undefined : rlpHref}>{registrationLandingPage.label}</Link>
        </>
      )}
    </Box>
  );
};

export default LoginMenu;
