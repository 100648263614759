'use client';

import { FC, useCallback, useEffect, useState } from 'react';

import { Customer, CustomerListItem } from '@ravago/shared/page-data/models/features';
import { DataCy, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Alert } from '@ravago/shared/radiance/components/alert/components/Alert/Alert';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Dialog } from '@ravago/shared/radiance/components/dialog/components/Dialog/Dialog';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { Radio } from '@ravago/shared/radiance/components/radio/components/Radio/Radio';
import { RadioGroup } from '@ravago/shared/radiance/components/radio/components/RadioGroup/RadioGroup';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import { useAuthentication, UserGroups } from '@ravago/shared/authentication';

import debounce from 'lodash/debounce';

interface Props {
  open: boolean;
  getCustomer: (accessToken: string) => Promise<Customer | undefined>;
  getCustomers: (accessToken: string, searchTerm?: string, perPage?: string) => Promise<Array<CustomerListItem>>;
  setCustomer: (accessToken: string, id: number) => Promise<boolean>;
  enableCustomer?: (accessToken: string) => Promise<boolean>;
  onDialogClose?: () => void;
}

const labels = {
  customerSwitchTitle: 'page-consumer.header.customer-switch-dialog.header-switch',
  customerEnableTitle: 'page-consumer.header.customer-switch-dialog.header-enable',
  cancelAction: 'page-consumer.header.customer-switch-dialog.cancel',
  switchAction: 'page-consumer.header.customer-switch-dialog.switch-customer',
  switchingAction: 'page-consumer.header.customer-switch-dialog.switching-customer',
  enableAction: 'page-consumer.header.customer-switch-dialog.enable-customer',
  enablingAction: 'page-consumer.header.customer-switch-dialog.enabling-customer',
  onSwitchErrorTitle: 'page-consumer.header.customer-switch-dialog.on-switch-error-title',
  onSwitchErrorText: 'page-consumer.header.customer-switch-dialog.on-switch-error-text',
  onEnableSuccessTitle: 'page-consumer.header.customer-switch-dialog.on-enable-success-title',
  onEnableSuccessText: 'page-consumer.header.customer-switch-dialog.on-enable-success-text',
  onEnableErrorTitle: 'page-consumer.header.customer-switch-dialog.on-enable-error-title',
  onEnableErrorText: 'page-consumer.header.customer-switch-dialog.on-enable-error-text'
};

const CustomerSwitchDialog: FC<Props> = ({ open, getCustomer, getCustomers, setCustomer, enableCustomer, onDialogClose }) => {
  const translate = useTranslations();
  const [currentCustomer, setCurrentCustomer] = useState<CustomerListItem | undefined>(undefined);
  const [selectedCustomer, setSelectedCustomer] = useState<number>(-1);
  const [customers, setCustomers] = useState<Array<CustomerListItem>>([]);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const [isEnabling, setIsEnabling] = useState<boolean>(false);
  const [customerDisabled, setCustomerDisabled] = useState<boolean>(false);
  const [hasSuccess, setHasSuccess] = useState<{ title: string; text: string } | null>(null);
  const [hasError, setHasError] = useState<{ title: string; text: string } | null>(null);

  const { user, accessToken, refreshToken } = useAuthentication();

  const { phone } = useBreakpoint();

  const canSwitchCustomer = user && (user.groups.includes(UserGroups.ravagoSalesRepresentative) || user.groups.includes(UserGroups.ravagoGlobalGroupAdmin));
  const hasCustomer = user && user.groups.includes(UserGroups.customerPortal);

  const handleSearch = (value?: string) => setSearchTerm(value);

  const handleSetCustomer = (id: number) => {
    setHasError(null);
    setIsSwitching(true);

    setCustomer(accessToken || '', id).then((success) => {
      if (success) {
        refreshToken()
          .then(() => {
            setIsSwitching(false);
            window.location.reload();
          })
          .catch(() => {
            setIsSwitching(false);
            setHasError({ title: translate(labels.onSwitchErrorTitle), text: translate(labels.onSwitchErrorText) });
          });

        return;
      }

      setIsSwitching(false);
      setHasError({ title: translate(labels.onSwitchErrorTitle), text: translate(labels.onSwitchErrorText) });
    });
  };

  const handleEnableCustomer = () => {
    setHasError(null);
    setHasSuccess(null);
    setIsEnabling(true);

    enableCustomer?.(accessToken || '').then((success) => {
      setIsEnabling(false);

      if (success) {
        setHasSuccess({ title: translate(labels.onEnableSuccessTitle), text: translate(labels.onEnableSuccessText) });

        /**
         * Enabling a customer triggers an automated request.
         * This should take no longer than a few seconds.
         * I tested it with a 2 second timeout and that was fine. 4 seconds should be more than enough to be safe.
         */
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        setHasError({ title: translate(labels.onEnableErrorTitle), text: translate(labels.onEnableErrorText) });
      }
    });
  };

  const onSearch = useCallback(debounce(handleSearch, 500), []);

  const handleDialogClose = () => {
    onDialogClose?.();
    setSelectedCustomer(-1);
  };

  useEffect(() => {
    if (!currentCustomer && accessToken)
      getCustomer(accessToken).then((res) => {
        setCurrentCustomer({ id: -1, name: res?.name || '' });
        setCustomerDisabled(!res?.customerPortalEnabled);
      });
  }, [accessToken, getCustomer, currentCustomer]);

  useEffect(() => {
    if (!accessToken) return;

    setIsFetching(true);

    if (searchTerm && searchTerm.length > 1) {
      getCustomers(accessToken, searchTerm).then((res) => {
        setIsFetching(false);
        setCustomers(res);
      });
    } else {
      getCustomers(accessToken, '', '4').then((res) => {
        setIsFetching(false);
        setCustomers(res);
      });
    }
  }, [accessToken, getCustomers, searchTerm]);

  if (!hasCustomer) return null;

  return (
    <Dialog
      open={open}
      disableBackdrop={customerDisabled}
      title={!customerDisabled ? translate(labels.customerSwitchTitle) : translate(labels.customerEnableTitle)}
      closable={!customerDisabled}
      actions={
        <>
          {!customerDisabled && (
            <Button variant="secondary" attributes={{ ...DataCy('customer-switch-dialog-button-close') }} onClick={handleDialogClose}>
              {translate(labels.cancelAction)}
            </Button>
          )}
          <Button
            attributes={{ ...DataCy('customer-switch-dialog-button-switch') }}
            disabled={(!customerDisabled && selectedCustomer === currentCustomer?.id) || isSwitching || isEnabling}
            onClick={() => {
              if ((selectedCustomer && !customerDisabled) || selectedCustomer !== currentCustomer?.id) handleSetCustomer(selectedCustomer);
              if (customerDisabled && selectedCustomer === currentCustomer?.id) handleEnableCustomer();
            }}
          >
            <Box gap="sm">
              {(isSwitching || isEnabling) && <Spinner size="small" />}

              {customerDisabled && selectedCustomer === currentCustomer?.id ? (
                <>
                  {!isEnabling && translate(labels.enableAction)}
                  {isEnabling && translate(labels.enablingAction)}
                </>
              ) : null}

              {(customerDisabled && selectedCustomer !== currentCustomer?.id) || !customerDisabled ? (
                <>
                  {!isSwitching && translate(labels.switchAction)}
                  {isSwitching && translate(labels.switchingAction)}
                </>
              ) : null}
            </Box>
          </Button>
        </>
      }
      onDialogClose={handleDialogClose}
      closeOnOutsideClick={false}
    >
      <Box direction="column" width={phone ? '100%' : '560px'} margin={{ top: 'xs' }}>
        {canSwitchCustomer ? (
          <>
            <InputField id="search-customers" label={translate('page-consumer.header.customer-switch-dialog.search')} trailingIcon="search" value={searchTerm} onChange={onSearch} />

            {hasError && (
              <Alert headline={hasError.title} variant="error">
                {hasError.text}
              </Alert>
            )}

            {hasSuccess && (
              <Alert headline={hasSuccess.title} variant="success">
                {hasSuccess.text}
              </Alert>
            )}

            {customerDisabled && (
              <Alert headline={translate('page-consumer.header.customer-switch-dialog.on-disabled-title')} variant="warning">
                {translate('page-consumer.header.customer-switch-dialog.on-disabled-text', { customer: currentCustomer?.name })}
              </Alert>
            )}

            {isFetching && (
              <Box height="220px" items="center" justify="center">
                <Spinner />
              </Box>
            )}

            {!isFetching && (
              <Box maxHeight="440px" overflowY="auto">
                <RadioGroup value={currentCustomer?.id} onChange={setSelectedCustomer}>
                  {currentCustomer && (
                    <Radio attributes={{ ...DataCy('customer-switch-dialog-option', currentCustomer.name) }} name="test" disabled key={currentCustomer.id} id={currentCustomer.id.toString()} value={currentCustomer.id}>
                      {currentCustomer.name}
                    </Radio>
                  )}

                  {customers.map((customer) => (
                    <Radio attributes={{ ...DataCy('customer-switch-dialog-option', customer.name) }} name="test" key={customer.id} id={customer.id.toString()} value={customer.id}>
                      {customer.name}
                    </Radio>
                  ))}
                </RadioGroup>
              </Box>
            )}
          </>
        ) : (
          <Alert headline={translate('page-consumer.header.customer-switch-dialog.on-not-allowed-title')} variant="warning">
            {translate('page-consumer.header.customer-switch-dialog.on-not-allowed-text')}
          </Alert>
        )}
      </Box>
    </Dialog>
  );
};

export default CustomerSwitchDialog;
