'use client';

import { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { IconButton } from '../../../button/components/IconButton/IconButton';
import { Container } from '../../../container/components/Container/Container';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props extends PropsWithChildren {
   variant?: 'neutral' | 'success' | 'warning' | 'error' | 'info';
   closable?: boolean;
   onClose?: () => void;
}

const backgroundColors = {
   neutral: 'bg-component-feedback-ribbon-container-color-background-neutral',
   success: 'bg-component-feedback-ribbon-container-color-background-success',
   warning: 'bg-component-feedback-ribbon-container-color-background-warning',
   error: 'bg-component-feedback-ribbon-container-color-background-danger',
   info: 'bg-component-feedback-ribbon-container-color-background-info'
};

const colors = {
   neutral: 'text-component-feedback-ribbon-content-color-fill-on-neutral',
   success: 'text-component-feedback-ribbon-content-color-fill-on-success',
   warning: 'text-component-feedback-ribbon-content-color-fill-on-warning',
   error: 'text-component-feedback-ribbon-content-color-fill-on-danger',
   info: 'text-component-feedback-ribbon-content-color-fill-on-info'
};

const iconColors = {
   neutral: 'text-component-feedback-ribbon-icon-end-color-fill-on-neutral',
   success: 'text-component-feedback-ribbon-icon-end-color-fill-on-success',
   warning: 'text-component-feedback-ribbon-icon-end-color-fill-on-warning',
   error: 'text-component-feedback-ribbon-icon-end-color-fill-on-danger',
   info: 'text-component-feedback-ribbon-icon-end-color-fill-on-info'
};

const alertIcon = {
   neutral: 'exclamation-circle',
   success: 'check-circle',
   warning: 'exclamation-triangle',
   error: 'exclamation-circle',
   info: 'info-circle'
};

export const Ribbon: FC<Props> = ({ variant = 'neutral', closable, onClose, children }) => (
   <div className={classNames('w-full flex items-center min-h-component-feedback-ribbon-container-sizing-min-height py-component-feedback-ribbon-container-spacing-padding-y', backgroundColors[variant], colors[variant])}>
      <Container>
         <div className="flex items-center justify-between">
            <div className={classNames('flex items-center gap-component-feedback-ribbon-container-spacing-gap')}>
               <div className={classNames(iconColors[variant])}>
                  <Icon icon={alertIcon[variant]} size="heading-3" />
               </div>
               <Typography component="p" color="inherit">
                  {children}
               </Typography>
            </div>
            {closable && (
               <div className={classNames(iconColors[variant])}>
                  <IconButton icon="times" variant="inherit" onClick={() => onClose?.()} />
               </div>
            )}
         </div>
      </Container>
   </div>
);
