'use client';

import { FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { CallOffAgreementContext } from '../context/call-off-agreement-context';
import { CallOffAgreementReducer, initialState } from '../reducers/call-off-agreement-reducer';

export const CallOffAgreementProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(CallOffAgreementReducer, initialState);

   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

   return <CallOffAgreementContext.Provider value={contextValue}>{children}</CallOffAgreementContext.Provider>;
};
