import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  quantities: number[];
  uom: string;
  packaging?: string;
  otherPackagingDescription?: string;
}
const RfqProductDetails: FC<Props> = ({ translate, quantities, uom, packaging, otherPackagingDescription }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" tableLayout="fixed" hover={false}>
      <TableBody>
        {quantities.map((quantity, index) => (
          <TableRow key={quantity + uom}>
            <TableCell>
              <Typography component="b" variant="body-highlight" color="primary">
                {`${translate('page-consumer.request-for-quotation.quantity')} ${index + 1}`}
              </Typography>
            </TableCell>
            <TableCell>{`${quantity} ${uom}`}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.packaging')}
            </Typography>
          </TableCell>
          <TableCell>{packaging}</TableCell>
        </TableRow>
        {otherPackagingDescription && (
          <TableRow>
            <TableCell>
              <Typography component="b" variant="body-highlight" color="primary">
                {translate('page-consumer.request-for-quotation.packaging-description')}
              </Typography>
            </TableCell>
            <TableCell>{otherPackagingDescription}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Box>
);

export default RfqProductDetails;
