import { FC } from 'react';

import { DeliveryArea } from '@ravago/shared/page-data/models/features';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  isVerified?: boolean;
  address?: string;
  deliveryArea: DeliveryArea;
  timeframe?: string;
}
const RfqDeliveryInfo: FC<Props> = ({ translate, isVerified, address = '-', timeframe, deliveryArea }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" tableLayout="fixed" hover={false}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.delivery-address')}
            </Typography>
          </TableCell>
          <TableCell>
            {isVerified ? (
              <div dangerouslySetInnerHTML={{ __html: address }} />
            ) : (
              <>
                <Typography variant="body">
                  {deliveryArea.postalCode} {deliveryArea.city}
                </Typography>
                <Typography variant="body" component="p">
                  {deliveryArea.country.value}
                </Typography>
              </>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.delivery-timeframe')}
            </Typography>
          </TableCell>
          <TableCell>{timeframe ?? ''}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default RfqDeliveryInfo;
