'use client';

import { FC, useContext, useMemo } from 'react';

import { BusinessContext, NavigationGroup } from '@ravago/shared/page-data/models/config';
import { AssetUtils, BottomContainerContext, ContentfulDocument, getCountryCode, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Container } from '@ravago/shared/radiance/components/container/components/Container/Container';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';

import { OleInfo } from '../../../models/contentful/ole-info.model';

interface Props {
  businessContext: BusinessContext;
  locale: string;
  acceptWebp?: boolean;
  oles?: OleInfo[];
  language: string;
}

const Footer: FC<Props> = ({ businessContext, language, acceptWebp, locale, oles }: Props) => {
  const translate = useTranslations();

  const currentOle = useMemo(() => oles?.find((ole) => ole.country.shortName.toUpperCase() === getCountryCode(locale)), [oles, locale]);

  const { state } = useContext(BottomContainerContext);

  const handleOpenOsanoFlyout = () => {
    if ((window as any)?.Osano) {
      (window as any).Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    } else {
      notification(translate('page-consumer.technical-support.error.information'), { duration: 3000 });
    }
  };

  const group = (navGroup?: NavigationGroup, osanaCookiePreference?: boolean) => (
    <GridCol spanDesktop={2} spanTablet={3} spanPhone={4}>
      {navGroup && (
        <Box direction="column" gap="sm">
          <Typography variant="body-highlight" color="primary">
            {navGroup.label}
          </Typography>
          <Box direction="column" gap="sm">
            {navGroup.itemsCollection.items
              .filter((item) => item.externalUrl || item.page?.route)
              .map((navItem) => (
                <Link underline="none" href={navItem.page ? `/${locale}/${navItem.page.route ?? ''}` : navItem.externalUrl} target={navItem.openInNewTab ? '_blank' : undefined} key={navItem.label}>
                  <Typography variant="body">{navItem.label}</Typography>
                </Link>
              ))}
            {osanaCookiePreference && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link underline="none" onClick={handleOpenOsanoFlyout}>
                <Typography variant="body">{translate('page-consumer.osana-cookie-preference')}</Typography>
              </Link>
            )}
          </Box>
        </Box>
      )}
    </GridCol>
  );

  return (
    <Container>
      <Box direction="column" gap="xl" spacing={{ top: 'xl', bottom: 'xl' }} margin={{ bottom: state.bottomSheet ? '3xl' : 'xl' }}>
        <Grid>
          <GridCol spanDesktop={5} spanTablet={12} spanPhone={4}>
            <Link underline="none" href={`/${language}`}>
              <Image objectFit="contain" src={AssetUtils.optimizeImageIfRequired(businessContext.logo.url, acceptWebp)} alt={businessContext.name} title={businessContext.name} />
            </Link>
          </GridCol>
          {group(businessContext.footerCollection.items[0], false)}
          {group(businessContext.footerCollection.items[1], true)}
          <GridCol spanDesktop={3} spanTablet={6} spanPhone={4}>
            <Box direction="column" gap="sm">
              <Typography variant="body-highlight" color="primary">
                {currentOle?.title ?? translate('page-consumer.footer.contact')}
              </Typography>
              {(currentOle?.phone || businessContext.phoneNr) && (
                <Box gap="2xs" items="baseline">
                  <Typography color="primary">
                    <Icon icon="phone" size="body-highlight" />
                  </Typography>
                  <Link underline="none" href={`tel:${currentOle?.phone ?? businessContext.phoneNr}`}>
                    <Typography>{currentOle?.phone ?? businessContext.phoneNr}</Typography>
                  </Link>
                </Box>
              )}
              {(!!currentOle?.address || businessContext.address) && (
                <Box gap="2xs" items="baseline">
                  <Typography color="primary">
                    <Icon icon="map-marker-alt" size="body-highlight" />
                  </Typography>
                  <Typography>
                    <Box direction="column" gap="none">
                      {currentOle?.address && <ContentfulDocument document={currentOle?.address.json} color="primary" />}
                    </Box>
                    {businessContext.address && <div dangerouslySetInnerHTML={{ __html: businessContext.address?.replace(/\n/g, '<br>') as string }} />}
                  </Typography>
                </Box>
              )}
              {(currentOle?.email || businessContext.emailAddress) && (
                <Box gap="2xs" items="baseline">
                  <Typography color="primary">
                    <Icon icon="envelope" size="body-highlight" />
                  </Typography>
                  <Link underline="none" href={`mailto:${currentOle?.email ?? businessContext.emailAddress}`}>
                    <Typography>{currentOle?.email ?? businessContext.emailAddress}</Typography>
                  </Link>
                </Box>
              )}
              {!currentOle && businessContext.linkedInUrl && (
                <Box gap="2xs" items="baseline">
                  <Typography color="primary">
                    <Icon icon="linkedin" type="brand" size="body-highlight" />
                  </Typography>
                  <Link underline="none" href={businessContext.linkedInUrl} target="_blank">
                    <Typography>{translate('page-consumer.footer.follow-us-on-linkedin')}</Typography>
                  </Link>
                </Box>
              )}
            </Box>
          </GridCol>
        </Grid>
        <Divider />
        <Box gap="lg" items="center">
          <AspectRatio aspectRatio="4:3">
            <Image src="/images/footer-logo.png" alt="Ravago" title="Ravago" height="100%" width="100%" objectFit="contain" />
          </AspectRatio>
          <Box gap="xs" items="baseline">
            <Icon size="caption" color="subtle" icon="copyright" />
            <Typography variant="caption" color="subtle">
              {translate('page-consumer.footer.legal', { businessContext: businessContext.name })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Footer;
