'use client';

import { useEffect, useState } from 'react';

import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { useConfigContext } from '@ravago/shared/page-elements-radiance-consumer';
import { useAuthentication, UserGroups } from '@ravago/shared/authentication';

import { getPrivateToggles, getPublicToggles } from '../../actions';
import { Features, FeaturesPermissions } from '../models/config/feature.model';

const useFeaturePermissions = () => {
  const config = useConfigContext();
  const { accessToken, user } = useAuthentication();

  const [privateFeatures, setPrivateFeatures] = useState<FeatureToggle[]>([]);
  const [publicFeatures, setPublicFeatures] = useState<FeatureToggle[]>([]);
  const [features, setFeatures] = useState<Features>({});
  const [featurePermissions, setFeaturePermissions] = useState<FeaturesPermissions>({
    hasTeamdeskFeedbackFeaturePermission: false,
    hasProductCompareFeaturePermission: false,
    hasShareFeaturePermission: false
  });

  useEffect(() => {
    if (!config.isPublic) {
      getPublicToggles().then((data) => {
        setPublicFeatures(data);
      });
    }
  }, [config.isPublic]);

  useEffect(() => {
    if (!config.isPublic && accessToken) {
      getPrivateToggles(accessToken).then((data) => {
        setPrivateFeatures(data);
      });
    }
  }, [accessToken, config.isPublic]);

  useEffect(() => {
    if (config.isPublic) return;

    const publicFeaturesList = publicFeatures.reduce((acc, feature) => {
      acc[feature.name] = feature.allowed;
      return acc;
    }, {} as Features);

    if (!accessToken) {
      // anonymous
      setFeaturePermissions({
        hasTeamdeskFeedbackFeaturePermission: false,
        hasProductCompareFeaturePermission: publicFeaturesList['public-widget-product-compare'],
        hasShareFeaturePermission: publicFeaturesList['public-widget-share-page']
      });
      return;
    }

    const privateFeaturesList = privateFeatures.reduce((acc, feature) => {
      acc[feature.name] = feature.allowed;
      return acc;
    }, {} as Features);

    const allFeatures = { ...privateFeaturesList, ...publicFeaturesList };

    setFeatures(allFeatures);

    if (user?.groups.includes(UserGroups.ravagoSalesRepresentative)) {
      // seller
      setFeaturePermissions({
        hasTeamdeskFeedbackFeaturePermission: allFeatures['teamdesk-feedback'],
        hasProductCompareFeaturePermission: allFeatures['product-compare'],
        hasShareFeaturePermission: allFeatures['share-page']
      });
    } else if (user?.groups.includes(UserGroups.digitalPlatformGuest) || user?.groups.includes(UserGroups.digitalPlatformPendingVerification) || user?.groups.includes(UserGroups.customerPortal)) {
      // customer, peding or guest
      setFeaturePermissions({
        hasTeamdeskFeedbackFeaturePermission: false,
        hasProductCompareFeaturePermission: allFeatures['customer-widget-product-compare'],
        hasShareFeaturePermission: allFeatures['customer-widget-share-page']
      });
    }
  }, [user, accessToken, config, publicFeatures, privateFeatures]);

  return { featurePermissions, features };
};

export default useFeaturePermissions;
