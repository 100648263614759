import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  country?: string;
}
const CallOffContactInfo: FC<Props> = ({ translate, firstName, lastName, email, companyName, country }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" tableLayout="fixed" hover={false} rowDividers={false}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.contact-person')}
            </Typography>
          </TableCell>
          <TableCell>
            {firstName} {lastName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.email')}
            </Typography>
          </TableCell>
          <TableCell wordBreak="all">{email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.company')}
            </Typography>
          </TableCell>
          <TableCell>{companyName || '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.country')}
            </Typography>
          </TableCell>
          <TableCell>{country || '-'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default CallOffContactInfo;
