import { FC } from 'react';

import { Quantity } from '@ravago/shared/page-data/models/elements';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  deliveryDate: string;
  quantity: Quantity;
  deliveryAddress: Address;
  additionalInfo: string;
}

const CallOffDeliveryInfo: FC<Props> = ({ translate, deliveryDate, quantity, deliveryAddress, additionalInfo }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" tableLayout="fixed" hover={false} rowDividers={false}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.requested-delivery-date')}
            </Typography>
          </TableCell>
          <TableCell>{deliveryDate}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.quantity')}
            </Typography>
          </TableCell>
          <TableCell>
            {quantity.amount} {quantity.unitOfMeasure}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.delivery-address')}
            </Typography>
          </TableCell>
          <TableCell>
            <div dangerouslySetInnerHTML={{ __html: deliveryAddress.htmlFormat }} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.additional-information')}
            </Typography>
          </TableCell>
          <TableCell wordBreak="all">{additionalInfo}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default CallOffDeliveryInfo;
