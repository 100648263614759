'use client';

import { FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { AsYouType, isPossibleNumber } from 'libphonenumber-js';
import { firstBy } from 'thenby';

import { Icon } from '../../../icon/components/Icon/Icon';
import { List } from '../../../list/components/List/List';
import { ListItem } from '../../../list/components/ListItem/ListItem';
import { Menu } from '../../../menu/components/Menu/Menu';
import { Typography } from '../../../typography/components/Typography/Typography';

import { Countries, CountryCode, CountryInfo, PhoneDetails, PhoneInputErrorMessages } from './PhoneInputField.types';

interface Props {
   id: string;
   autocomplete?: 'on' | 'off';
   clearable?: boolean;
   countryFilter?: Array<CountryCode>;
   disabled?: boolean;
   filterType?: 'include' | 'exclude';
   error?: boolean;
   errorMessage?: string;
   supportText?: string;
   label?: string;
   readOnly?: boolean;
   required?: boolean;
   trailingIcon?: string;
   value?: PhoneDetails;
   width?: string | number;
   onClear?: () => void;
   onChange?: (value: PhoneDetails) => void;
   onEnter?: (value: PhoneDetails) => void;
   onError?: (errorMessage: PhoneInputErrorMessages) => void;
}

export const PhoneInputField: FC<Props> = ({
   id,
   autocomplete = 'on',
   clearable = false,
   countryFilter = [],
   disabled = false,
   filterType = 'include',
   error = false,
   errorMessage,
   supportText,
   label,
   readOnly = false,
   required = false,
   trailingIcon,
   value = {
      phone: '',
      countryInfo: Countries.US
   },
   width,
   onClear,
   onChange,
   onEnter,
   onError
}) => {
   const [focused, setFocused] = useState(false);
   const [countryMenuOpen, setCountryMenuOpen] = useState(false);

   /* istanbul ignore next */
   const countries = useMemo(() => {
      if (countryFilter.length === 0) return Object.values(Countries).sort(firstBy('prefix'));

      return Object.values(Countries)
         .filter((c) => (filterType === 'include' ? countryFilter.includes(c.iso2) : !countryFilter.includes(c.iso2)))
         .sort(firstBy('prefix'));
   }, [countryFilter, filterType]);

   const inputRef = useRef<HTMLInputElement>(null);

   const handleClear = () => {
      onClear?.();
   };

   /* istanbul ignore next */
   const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
         onEnter?.({
            countryInfo: value.countryInfo,
            phone: value.phone
         });
      }
   };

   /* istanbul ignore next */
   const handleError = (phoneInput: PhoneDetails) => {
      const phoneRegex = /[!@#$%^&*()_+={}[\]:;<>,.?~\\'"|A-Za-z/]/g;
      const hasInvalidCharacter = phoneRegex.test(phoneInput.phone);

      if (phoneInput.phone) {
         if (hasInvalidCharacter) {
            onError?.(PhoneInputErrorMessages.INVALID_PHONE_NUMBER_CHAR);
         } else if (phoneInput.phone.length < 9) {
            onError?.(PhoneInputErrorMessages.INVALID_PHONE_NUMBER_LENGTH);
         } else {
            onError?.(PhoneInputErrorMessages.VALID_PHONE_NUMBER);
         }
      }
   };

   /* istanbul ignore next */
   const handleChangeValue = (newValue: string) => {
      setCountryMenuOpen(false);

      const newInputValue = {
         countryInfo: value.countryInfo,
         phone: newValue
      };

      const formatter = new AsYouType();
      formatter.input(newValue);

      const foundCountry = countries.find((c) => c.prefix === formatter.getCallingCode());

      if (foundCountry) {
         newInputValue.countryInfo = foundCountry;
         newInputValue.phone = newValue.replace(`+${foundCountry.prefix}`, '');
      }

      if (isPossibleNumber(`+${newInputValue.countryInfo?.prefix}${newInputValue.phone}`)) {
         onError?.(PhoneInputErrorMessages.VALID_PHONE_NUMBER);
      } else {
         handleError(newInputValue);
      }

      onChange?.(newInputValue);

      formatter.reset();
   };

   /* istanbul ignore next */
   const handleChangeCountry = (c: CountryInfo) => {
      setCountryMenuOpen(false);
      onChange?.({
         ...value,
         countryInfo: c
      });
   };

   useEffect(() => {
      handleError(value);
   }, []);

   return (
      <div data-testid={`phone-input-field${error ? '--error' : ''}`} style={{ width }} className="gap-component-input-phone-input-field-container-spacing-outer-gap flex w-full flex-col">
         <div>
            <div className={`group relative cursor-text ${disabled ? 'pointer-events-none' : ''}`}>
               {label && (
                  <label
                     data-testid="phone-input-field-label"
                     htmlFor={id}
                     className={`absolute left-0 top-0 flex items-center transition-all cursor-pointer ml-component-input-phone-input-field-container-spacing-padding-x bg-component-input-phone-input-field-container-color-background px-component-input-phone-input-field-label-spacing-padding-x h-[var(--component-input-phone-input-field-container-border-width-enabled)] max-w-[calc(var(--component-input-phone-input-field-container-sizing-max-width)-2*var(--component-input-phone-input-field-container-spacing-padding-y))]
                           ${!error && !disabled ? 'text-component-input-phone-input-field-label-color-text-neutral-enabled-as-label' : ''}
                           ${!error && !disabled && focused ? 'text-component-input-phone-input-field-label-color-text-neutral-focused' : ''}
                           ${disabled ? 'text-component-input-phone-input-field-label-color-text-neutral-disabled' : ''}
                           ${error && !disabled ? 'text-component-input-phone-input-field-label-color-text-danger-enabled group-hover:text-component-input-phone-input-field-label-color-text-danger-hover' : ''}
                           ${focused ? 'h-[var(--component-input-phone-input-field-container-border-width-focused)]' : ''}
                      `.replace(/\s+/g, ' ')}
                  >
                     <Typography variant="caption" color="inherit" maxLines="1">
                        {label}
                        {required && '*'}
                     </Typography>
                  </label>
               )}

               <div
                  data-testid="phone-input-field-container"
                  onClick={() => inputRef.current?.focus()}
                  role="presentation"
                  className={`min-h-component-input-phone-input-field-container-sizing-min-height px-component-input-phone-input-field-container-spacing-padding-x py-component-input-phone-input-field-container-spacing-padding-y gap-component-input-phone-input-field-container-spacing-gap bg-component-input-phone-input-field-container-color-background rounded-component-input-phone-input-field-container-border-radius border-component-input-phone-input-field-container-border-width-enabled focus-within:!border-component-input-phone-input-field-container-border-width-focused box-border flex items-center justify-between transition-all
                           ${
                              error && !disabled
                                 ? '!border-component-input-phone-input-field-container-color-border-danger-enabled hover:!border-component-input-phone-input-field-container-color-border-danger-hover focus-within:!border-component-input-phone-input-field-container-color-border-danger-enabled'
                                 : '!border-component-input-phone-input-field-container-color-border-neutral-enabled hover:!border-component-input-phone-input-field-container-color-border-neutral-hover focus-within:!border-component-input-phone-input-field-container-color-border-neutral-focused'
                           }
                  `.replace(/\s+/g, ' ')}
               >
                  <Menu
                     content={
                        <List>
                           {countries.map((c) => (
                              <ListItem key={c.iso2} onClick={/* istanbul ignore next */ () => handleChangeCountry(c)}>
                                 <div className="gap-component-input-country-code-selector-container-spacing-gap text-component-input-country-code-selector-icon-color-fill-enabled flex items-center">
                                    <div className="flex size-[20px] items-center justify-center">
                                       <img className="h-full w-[var(--brand-font-size-md)] object-contain" src={c.flag} alt={c.name} title={c.name} />
                                    </div>
                                    <Typography variant="body" color="inherit">
                                       +{c.prefix}
                                    </Typography>
                                 </div>
                              </ListItem>
                           ))}
                        </List>
                     }
                     open={countryMenuOpen}
                     onMenuClose={() => setCountryMenuOpen(false)}
                     role="listbox"
                     toggleWidth="auto"
                     maxHeight
                  >
                     <button
                        type="button"
                        disabled={disabled}
                        onClick={() => setCountryMenuOpen((curr) => !curr)}
                        onBlur={/* istanbul ignore next */ () => setFocused(false)}
                        onFocus={/* istanbul ignore next */ () => setFocused(true)}
                        className="gap-component-input-country-code-selector-container-spacing-gap inline-flex appearance-none items-center"
                     >
                        <div className={`flex size-[20px] items-center justify-center ${disabled ? 'opacity-component-input-country-code-selector-flag-opacity-disabled' : ''}`}>
                           <img className="h-full w-[var(--brand-font-size-md)] object-contain" src={value.countryInfo?.flag} alt={value.countryInfo?.name} title={value.countryInfo?.name} />
                        </div>
                        <div className={disabled ? 'text-component-input-country-code-selector-icon-color-fill-disabled' : 'text-component-input-country-code-selector-icon-color-fill-enabled'}>
                           <Typography variant="body" color="inherit" whiteSpace="nowrap">
                              +{value.countryInfo?.prefix}
                           </Typography>
                        </div>
                        <div className={`transition-all ${disabled ? 'text-component-input-country-code-selector-icon-color-fill-disabled' : 'text-component-input-country-code-selector-icon-color-fill-enabled'} ${countryMenuOpen ? /* istanbul ignore next */ 'rotate-180' : ''}`}>
                           <Icon icon="chevron-down" />
                        </div>
                     </button>
                  </Menu>
                  <div className="flex h-full flex-1 items-center">
                     <input
                        data-testid="phone-input-field-input"
                        autoComplete={autocomplete}
                        className="text-component-input-phone-input-field-input-color-text-on-surface-enabled disabled:text-component-input-phone-input-field-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full bg-transparent outline-none"
                        disabled={disabled}
                        id={id}
                        name={id}
                        onBlur={/* istanbul ignore next */ () => setFocused(false)}
                        onChange={/* istanbul ignore next */ (e) => handleChangeValue(e.target.value)}
                        onFocus={/* istanbul ignore next */ () => setFocused(true)}
                        onKeyUp={handleKeyPress}
                        readOnly={readOnly}
                        ref={inputRef}
                        required={required}
                        type="tel"
                        value={value.phone}
                     />
                  </div>
                  {clearable && value.phone !== '' && (
                     <div
                        data-testid="phone-input-field-clear"
                        onClick={handleClear}
                        role="presentation"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center cursor-pointer ${
                           disabled ? 'text-component-input-search-icon-end-color-text-on-surface-disabled' : 'text-component-input-search-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon="times-circle" />
                     </div>
                  )}
                  {trailingIcon && (
                     <div
                        data-testid="phone-input-field-trailing-icon"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                           disabled ? 'text-component-input-phone-input-field-icon-end-color-text-on-surface-disabled' : 'text-component-input-phone-input-field-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon={trailingIcon} />
                     </div>
                  )}
               </div>
            </div>
         </div>

         {supportText && (
            <div
               data-testid="phone-input-field-support-text"
               className={`pl-component-input-phone-input-field-supporting-text-spacing-padding-left gap-component-input-phone-input-field-supporting-text-spacing-gap flex items-center
                        ${error && !disabled ? 'text-component-input-phone-input-field-supporting-text-color-text-danger' : ''}
                        ${!disabled ? 'text-component-input-phone-input-field-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-phone-input-field-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
         {error && errorMessage && (
            <div
               data-testid="phone-input-field-error-message"
               className="pl-component-input-phone-input-field-supporting-text-spacing-padding-left gap-component-input-phone-input-field-supporting-text-spacing-gap text-component-input-phone-input-field-supporting-text-color-text-danger flex items-center"
            >
               <Icon icon="exclamation-circle" size="caption" />
               <Typography variant="caption" color="inherit">
                  {errorMessage}
               </Typography>
            </div>
         )}
      </div>
   );
};
