import { FC } from 'react';

import { OrderDocumentType, OrderLineCOA } from '@ravago/shared/page-data/models/elements';
import { useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Accordion } from '@ravago/shared/radiance/components/accordion/components/Accordion/Accordion';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  documents: Array<OrderLineCOA>;
  onDownload: (document: OrderLineCOA, type: OrderDocumentType) => void;
  onRequestCoa: (coa: OrderLineCOA) => void;
}

const CoaDocuments: FC<Props> = ({ documents, onDownload, onRequestCoa }: Props) => {
  const translate = useTranslations();

  return (
    <Box direction="column" gap="xs">
      <Accordion
        open
        header={
          <Typography variant="body-highlight" component="span" color="strong">
            {translate('page-consumer.order-line-flyout.certificates-of-analysis')}
          </Typography>
        }
        body={
          documents.length > 0 ? (
            documents?.map((document, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} gap="none">
                <Box direction="column" gap="sm" width="100%">
                  <Box gap="xl">
                    <Box direction="column" gap="none">
                      <Typography variant="body" component="span" color="subtle">
                        {translate('page-consumer.order-line-flyout.transport-order-number')}
                      </Typography>
                      <Typography variant="body" component="span">
                        {document.transportOrderNr}
                      </Typography>
                    </Box>
                    <Box direction="column" gap="none">
                      <Typography variant="body" component="span" color="subtle">
                        {translate('page-consumer.order-line-flyout.producer-lot-number')}
                      </Typography>
                      <Typography variant="body" component="span">
                        {document.producerLotNr}
                      </Typography>
                    </Box>
                  </Box>
                  <Box justify="between">
                    <Box direction="column" gap="none">
                      <Typography variant="body" component="span" color="subtle">
                        {translate('page-consumer.order-line-flyout.quantity')}
                      </Typography>
                      <Typography variant="body" component="span">
                        {document.batch.quantity.amount} {document.batch.quantity.unitOfMeasure}
                      </Typography>
                    </Box>
                    <Box>
                      {document.isAvailable ? (
                        <IconButton icon="download" onClick={() => onDownload(document, 'COA')} />
                      ) : (
                        <Button variant="tertiary" onClick={() => onRequestCoa(document)}>
                          {translate('page-consumer.order-line-flyout.request')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  {index < documents.length - 1 && <Divider />}
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body" component="span">
              {translate('page-consumer.order-line-flyout.no-documents-available')}
            </Typography>
          )
        }
      />
    </Box>
  );
};

export default CoaDocuments;
