import { FC, HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';

import classnames from 'classnames';

import dataAttributes from '../../../../utils/attributes/attributes';
import { Box } from '../../../box/components/Box/Box';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

const iconColors = [
   'bg-component-data-display-facet-background-color-a text-component-data-display-facet-icon-color-a',
   'bg-component-data-display-facet-background-color-b text-component-data-display-facet-icon-color-b',
   'bg-component-data-display-facet-background-color-c text-component-data-display-facet-icon-color-c',
   'bg-component-data-display-facet-background-color-d text-component-data-display-facet-icon-color-d',
   'bg-component-data-display-facet-background-color-e text-component-data-display-facet-icon-color-e'
];

interface Props extends PropsWithChildren {
   icon?: string;
   size?: 'md' | 'lg';
   color?: number;
   trailingIcon?: string;
   href?: string;
   target?: HTMLAttributeAnchorTarget;
   title?: string;
   fullWidth?: boolean;
   attributes?: Record<string, string>;
   onClick?: () => void;
}

export const NavItem: FC<Props> = ({ children, icon, color = 0, trailingIcon, href, target, size = 'md', title, fullWidth = false, attributes = {}, onClick }) => (
   <div
      data-testid={`nav-item--${size}${fullWidth ? '--full-width' : ''}`}
      {...dataAttributes(attributes)}
      className={classnames(
         'p-component-data-display-nav-item-container-spacing-padding !m-0 list-none transition-all',
         size === 'md' && 'rounded-component-data-display-nav-item-container-border-radius-small',
         size === 'lg' && 'rounded-component-data-display-nav-item-container-border-radius-large',
         !icon && 'min-h-component-data-display-nav-item-container-sizing-min-height flex items-center',
         (href || onClick) &&
            'hover:bg-component-data-display-nav-item-state-layer-color-background-default-hover active:bg-component-data-display-nav-item-state-layer-color-background-default-pressed focus-within:bg-component-data-display-nav-item-state-layer-color-background-default-focused cursor-pointer'
      )}
   >
      <a data-testid="nav-item-anchor" href={href} target={target} title={title} onClick={onClick} className={classnames('gap-component-data-display-nav-item-container-spacing-gap flex items-center', fullWidth && 'w-full')}>
         <Box gap="sm" items="center" width={fullWidth ? '100%' : 'auto'}>
            {icon && (
               <div
                  data-testid="nav-item-icon"
                  className={classnames(
                     'flex items-center justify-center',
                     iconColors[color % iconColors.length],
                     size === 'md' && 'rounded-component-data-display-nav-item-facet-border-radius-small min-h-component-data-display-facet-container-sizing-min-size-small min-w-component-data-display-facet-container-sizing-min-size-small',
                     size === 'lg' && 'rounded-component-data-display-nav-item-facet-border-radius-large min-h-component-data-display-facet-container-sizing-min-size-large min-w-component-data-display-facet-container-sizing-min-size-large'
                  )}
               >
                  <Icon icon={icon} color="inherit" size="body" />
               </div>
            )}

            <div className={classnames('text-component-data-display-nav-item-text-color-title', fullWidth && 'grow')}>
               <Typography variant={size === 'md' ? 'body-highlight' : 'heading-4'} color="inherit">
                  <Box width="100%" direction="column" gap="3xs">
                     {children}
                  </Box>
               </Typography>
            </div>

            {trailingIcon && (
               <div data-testid="nav-item-trailing-icon" className="text-component-data-display-search-item-icon-end-color-fill-enabled flex h-full items-center justify-center">
                  <Icon icon={trailingIcon} color="inherit" size="body" />
               </div>
            )}
         </Box>
      </a>
   </div>
);
