import { FC } from 'react';

import { FlyoutProps } from '@ravago/shared/page-data/models/features';
import { getLanguageCode, LoginButton, TrackingUtils, useRequestForQuotationContext, UserUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import RfqEmailRecognizedIllustration from '../../../../../../../public/illustrations/rfq-email-recognized-illustration';
import RfqImage from '../fields/rfq-image';

interface Props extends FlyoutProps {
  locale: string;
}

const RfqFlyoutStepVerifyEmail: FC<Props> = ({ onPreviousStep, phone, locale }) => {
  const translate = useTranslations();
  const language = getLanguageCode(locale);
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleBackButtonClick = () => {
    TrackingUtils.track('rfq_click_back_button_flyout', {
      productName: requestForQuotationState.product?.description,
      productId: requestForQuotationState.product?.id,
      userLoggedIn: JSON.stringify(false),
      userType: UserUtils.getUserType(undefined)
    });
    onPreviousStep();
  };

  const addLoginTracking = () => {
    TrackingUtils.track('rfq_click_login_button_flyout', {
      productName: requestForQuotationState.product?.description,
      productId: requestForQuotationState.product?.id,
      userLoggedIn: JSON.stringify(false),
      userType: UserUtils.getUserType(undefined)
    });
  };

  return (
    <Box direction="column">
      <RfqImage>
        <RfqEmailRecognizedIllustration />
      </RfqImage>
      <Typography component="h4" variant="heading-4" color="primary">
        {translate('page-consumer.request-for-quotation.email-recognized-title')}
      </Typography>
      <Typography component="span" variant="body" color="strong">
        {translate('page-consumer.request-for-quotation.email-recognized-description')}
      </Typography>
      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" items="center" width="100%">
                <Button variant="secondary" onClick={handleBackButtonClick} leadingIcon={!phone ? 'long-arrow-left' : ''} fullWidth>
                  {translate('page-consumer.request-for-quotation.back')}
                </Button>
                <Box width="100%" onClick={addLoginTracking}>
                  <LoginButton trailingIcon="long-arrow-right" fullwidth buttonLabel={translate('page-consumer.header.login')} language={language} variant="primary" />
                </Box>
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default RfqFlyoutStepVerifyEmail;
