import { FC } from 'react';

import { OrderDocumentType, OrderLineInvoice } from '@ravago/shared/page-data/models/elements';
import { DateUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Accordion } from '@ravago/shared/radiance/components/accordion/components/Accordion/Accordion';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  documents: Array<OrderLineInvoice>;
  onDownload: (document: OrderLineInvoice, type: OrderDocumentType) => void;
}

const InvoiceDocuments: FC<Props> = ({ documents, onDownload }: Props) => {
  const translate = useTranslations();

  return (
    <Box direction="column" gap="xs">
      <Accordion
        open
        header={
          <Typography variant="body-highlight" component="span" color="strong">
            {translate('page-consumer.order-line-flyout.copy-of-invoices-and-credit-notes')}
          </Typography>
        }
        body={
          documents.length > 0 ? (
            documents?.map((document, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} direction="column" gap="sm" width="100%">
                <Box gap="xl">
                  <Box direction="column" gap="none" width="50%">
                    <Typography variant="body" component="span" color="strong">
                      {translate('page-consumer.order-line-flyout.invoice-type')}
                    </Typography>
                    <Typography variant="body" component="span">
                      {document.type}
                    </Typography>
                  </Box>
                  <Box direction="column" gap="none" width="50%">
                    <Typography variant="body" component="span" color="subtle">
                      {translate('page-consumer.order-line-flyout.invoice-number')}
                    </Typography>
                    <Typography variant="body" component="span">
                      {document.number}
                    </Typography>
                  </Box>
                </Box>
                <Box justify="between">
                  <Box direction="column" gap="none">
                    <Typography variant="body" component="span" color="subtle">
                      {translate('page-consumer.order-line-flyout.invoice-date')}
                    </Typography>
                    <Typography variant="body" component="span">
                      {DateUtils.localizeDate(document.createdOn) || '-'}
                    </Typography>
                  </Box>
                  <IconButton icon="download" onClick={() => onDownload(document, 'Invoice')} />
                </Box>
                {index < documents.length - 1 && <Divider />}
              </Box>
            ))
          ) : (
            <Typography variant="body" component="span">
              {translate('page-consumer.order-line-flyout.no-documents-available')}
            </Typography>
          )
        }
      />
    </Box>
  );
};

export default InvoiceDocuments;
