export default abstract class CpApiService {
  static async fetch<T>(accessToken: string, baseURL: string, method: 'GET' | 'PUT' | 'POST' = 'GET', body?: any): Promise<T> {
    return fetch(baseURL, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
        'X-ravago-authenticationToken': accessToken
      },
      cache: 'no-cache',
      ...(body && { body })
    }).then(async (response) => {
      try {
        const json = await response.json();

        return json;
      } catch (err) {
        return response;
      }
    });
  }
}
