import { FC, ReactNode } from 'react';

import classnames from 'classnames';

interface Props {
   size?: 'small' | 'medium' | 'large';
   height?: string;
   showGutter?: boolean;
   container?: boolean;
   left?: ReactNode;
   right?: ReactNode;
}

export const NavBar: FC<Props> = ({ height, size = 'medium', showGutter = true, left, right, container = false }) => (
   <div
      data-testid="nav-bar-box"
      className={classnames(
         'border-component-navigation-navbar-toolbar-container-color-border border-b-component-navigation-navbar-toolbar-container-border-width box-border',
         size === 'small' && 'h-component-navigation-navbar-toolbar-container-sizing-min-height-sm',
         size === 'medium' && 'h-component-navigation-navbar-toolbar-container-sizing-min-height-md',
         size === 'large' && 'h-component-navigation-navbar-toolbar-container-sizing-min-height-lg'
      )}
      style={{ height }}
   >
      <div data-testid={`nav-bar${container ? '--container' : ''}`} className="size-full">
         <div
            data-testid={`nav-bar--${size}${showGutter ? '--gutter' : ''}`}
            className={`size-full bg-component-navigation-navbar-container-color-background
                  ${showGutter && !container ? 'px-component-navigation-navbar-toolbar-container-spacing-padding-x-default' : ''}
              `.replace(/\s+/g, ' ')}
         >
            <div className={`flex size-full items-center justify-between ${container ? 'container' : 'w-full'}`}>
               {left && (
                  <div data-testid="nav-bar-left" className={classnames('flex h-full justify-start', { 'flex-1': !right })}>
                     {left}
                  </div>
               )}
               {right && (
                  <div data-testid="nav-bar-right" className={classnames('flex h-full justify-end', { 'flex-1': !left })}>
                     {right}
                  </div>
               )}
            </div>
         </div>
      </div>
   </div>
);
