'use client';

import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Customer } from '@ravago/shared/page-data/models/features';
import { DataCy, getCountryCode, useConfigContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Header } from '@ravago/shared/radiance/components/header/components/Header/Header';
import { NavBar } from '@ravago/shared/radiance/components/navigation/components/NavBar/NavBar';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import { NotificationProvider } from '@ravago/shared/radiance/providers/notifications/NotificationProvider';
import { useAuthentication } from '@ravago/shared/authentication';

import { usePathname, useRouter } from 'next/navigation';

import { enableCustomer, getCustomer, getCustomers, setCustomer } from '../../../../actions';
import { Country, Language } from '../../../services/contentful/locale.service';
import CustomerSwitchDialog from '../customer-switch-dialog/customer-switch-dialog';
import CountryLanguageMenu from '../navbar/components/country-language-menu';

interface Props extends PropsWithChildren {
  locale: string;
  availableLanguages: Array<Language>;
  availableCountries: Array<Country>;
}

const EnableCustomerHandler: FC<Props> = ({ children, locale, availableLanguages, availableCountries }) => {
  const { okta, linkLegacyDigitalPlatform, isPublic } = useConfigContext();

  const { user, accessToken } = useAuthentication();

  const { phone } = useBreakpoint();

  const pathname = usePathname();

  const router = useRouter();

  const [countryLanguageMenuOpen, setCountryLanguageMenuOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState<Customer | undefined>();

  const getFirstSupportedCountry = (countries: (string | null | undefined)[]) => {
    const supportedCountries = availableCountries.map((country) => country.shortName);
    return countries.filter((c) => !!c).find((country) => supportedCountries.includes(country!)) ?? 'GB';
  };

  useEffect(() => {
    const navigateToCorrectCountryLocale = async () => {
      if (!accessToken) return;

      const customer = await getCustomer(accessToken);

      const defaultCustomerCountry = customer?.addresses?.find((address) => address.type === 'Legal Address' && address.isDefault)?.countryIsoCode;
      const countryFromStorage = localStorage.getItem('country-key');
      const currentCountry = getCountryCode(locale);

      const countryToSet = getFirstSupportedCountry([defaultCustomerCountry, countryFromStorage, currentCountry]);
      if (countryToSet !== currentCountry) {
        router.push(pathname.replace(currentCountry, countryToSet));
        localStorage.setItem('country-key', countryToSet);
      }
    };

    if (user) {
      if (sessionStorage.getItem('do-not-switch-back') === 'true') {
        sessionStorage.removeItem('do-not-switch-back');
        return;
      }
      navigateToCorrectCountryLocale();
    }
  }, [availableCountries, user]);

  useEffect(() => {
    if (!currentCustomer && accessToken) {
      getCustomer(accessToken).then((res) => setCurrentCustomer(res));
    }
  }, [accessToken, currentCustomer]);

  if (currentCustomer?.customerPortalEnabled === false) {
    return (
      <>
        <NotificationProvider
          containerStyle={{
            zIndex: 0,
            bottom: 80
          }}
        />
        <Header
          sticky
          expanded={countryLanguageMenuOpen ? 'right' : 'none'}
          toolbars={
            <NavBar
              size="large"
              showGutter={false}
              right={
                (availableCountries.length > 1 || availableLanguages.length > 1 || (!isPublic && linkLegacyDigitalPlatform && okta)) && (
                  <Box items="center" gap="xs" height="100%" spacing={{ right: 'lg' }}>
                    <IconButton attributes={{ ...DataCy('navbar-country-language-trigger') }} icon="globe" onClick={() => setCountryLanguageMenuOpen((current) => !current)} />
                  </Box>
                )
              }
            />
          }
          rightCollapse={{
            body: countryLanguageMenuOpen && <CountryLanguageMenu locale={locale} availableCountries={availableCountries} availableLanguages={availableLanguages} />,
            fullWidth: phone
          }}
        />
        <CustomerSwitchDialog open getCustomer={getCustomer} getCustomers={getCustomers} setCustomer={setCustomer} enableCustomer={enableCustomer} />
      </>
    );
  }
  return children;
};

export default EnableCustomerHandler;
