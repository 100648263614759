'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { useAuthentication, UserGroups } from '@ravago/shared/authentication';
import { BusinessContext, NavigationDropdown } from '@ravago/shared/page-data/models/config';
import { Customer } from '@ravago/shared/page-data/models/features';
import { LocalizedRoutes } from '@ravago/shared/page-data/models/pages';
import { AssetUtils, DataCy, getCountryCode, getLanguageCode, useConfigContext, useShoppingCartContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Flyout } from '@ravago/shared/radiance/components/flyout/components/Flyout/Flyout';
import { Header } from '@ravago/shared/radiance/components/header/components/Header/Header';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { NavBar } from '@ravago/shared/radiance/components/navigation/components/NavBar/NavBar';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

import { getCustomer, getCustomers, getShoppingCart, setCustomer } from '../../../../actions';
import useFeaturePermissions from '../../../hooks/use-feature-permissions';
import { TechnicalSupport } from '../../../models/contentful/tech-knowledge.model';
import { Country, Language } from '../../../services/contentful/locale.service';
import NavigationService from '../../../services/navigation/navigation.service';
import CustomerSwitchDialog from '../customer-switch-dialog/customer-switch-dialog';
import TechKnowledgeBaseDialog from '../tech-knowledge-base-dialog/tech-knowledge-base-dialog';

import AuthenticationButton from './components/authentication-button';
import CountryLanguageMenu from './components/country-language-menu';
import LoginMenu from './components/login-menu';
import MegaMenu from './components/mega-menu';
import Navigation from './components/navigation';
import ProfileMenu from './components/profile-menu';
import ShoppingCartMenu from './components/shopping-cart/shopping-cart-menu';

interface Props {
  businessContext: BusinessContext;
  locale: string;
  availableLanguages: Array<Language>;
  availableCountries: Array<Country>;
  technicalSupport: Array<TechnicalSupport>;
  acceptWebp?: boolean;
  localizedRoutes?: LocalizedRoutes;
  myOrdersUrl?: string;
  myInvoicesUrl?: string;
  myRegistrationNumbersUrl?: string;
  myVatNumbersUrl?: string;
  myAddressesUrl?: string;
}

type AvailableMenus = 'accountMenu' | 'countryLanguageMenu' | 'loginMenu' | 'mobileMenu' | 'mobileAccountMenu' | 'shoppingCartMenu';
type AvailableDialogs = 'techKnowledgeBase' | 'customerSwitch';

const Navbar: FC<Props> = ({ businessContext, locale, availableLanguages, availableCountries, technicalSupport, acceptWebp, localizedRoutes, myOrdersUrl, myInvoicesUrl, myRegistrationNumbersUrl, myAddressesUrl, myVatNumbersUrl }: Props) => {
  const translate = useTranslations();

  const { okta, linkLegacyDigitalPlatform, adminPortalUrl, isPublic } = useConfigContext();

  const { user, loading, accessToken } = useAuthentication();
  const { features } = useFeaturePermissions();

  const { desktop, tabLand, tabPort, phone } = useBreakpoint();

  const path = usePathname();

  const router = useRouter();

  const { state: shoppingCartState, resetCartValidation } = useShoppingCartContext();

  const defaultMenuStates: { [key in AvailableMenus]: boolean } = {
    accountMenu: false,
    countryLanguageMenu: false,
    loginMenu: false,
    mobileMenu: false,
    mobileAccountMenu: false,
    shoppingCartMenu: false
  };

  const [openMenus, setOpenMenus] = useState<{ [key in AvailableMenus]: boolean }>(defaultMenuStates);

  const defaultDialogStates: { [key in AvailableDialogs]: boolean } = {
    techKnowledgeBase: false,
    customerSwitch: false
  };

  const [openDialogs, setOpenDialogs] = useState<{ [key in AvailableDialogs]: boolean }>(defaultDialogStates);

  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [firstLoginChanged, setFirstLoginChanged] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState<Customer | undefined>(undefined);
  const [mobileNavStep, setMobileNavStep] = useState<'nav' | 'countryAndLanguage'>('nav');
  const [shoppingCartBadge, setShoppingCartBadge] = useState<number>(0);

  const [dropdownMenu, setDropdownMenu] = useState<NavigationDropdown>();
  const [expandedState, setExpandedState] = useState<'left' | 'right' | 'none'>('none');

  const isCustomerPortal = user && user.groups.includes(UserGroups.customerPortal);

  const getFirstSupportedCountry = (countries: (string | null | undefined)[]) => {
    const supportedCountries = availableCountries.map((cntry) => cntry.shortName);
    return countries.filter((c) => !!c).find((cntry) => supportedCountries.includes(cntry!)) ?? 'GB';
  };

  const handleHamburgerClick = () => {
    if (!openMenus.mobileMenu) setMobileNavStep('nav');
    setOpenMenus({ ...defaultMenuStates, mobileMenu: !openMenus.mobileMenu });
  };

  const setDropdownMenuFromId = (dropdownId: string) => {
    const dropdown = businessContext.header?.navigationItemsCollection.items.find((item) => 'sys' in item && item.sys.id === dropdownId) as NavigationDropdown;
    setDropdownMenu(dropdown);
  };

  const handleMenuClick = (state: { [key in AvailableMenus]?: boolean }, dropdownId?: string) => {
    setOpenMenus({ ...defaultMenuStates, ...state });

    if (state.shoppingCartMenu) {
      setExpandedState('right');
      return;
    }

    if ((!phone || (phone && !user)) && Object.values(state).some((value) => value)) {
      setExpandedState('right');

      return;
    }

    if (dropdownId) {
      setOpenMenus(defaultMenuStates);

      if (dropdownMenu?.sys.id === dropdownId) {
        setExpandedState('none');

        return;
      }

      setDropdownMenuFromId(dropdownId);
      setExpandedState('left');

      return;
    }

    setExpandedState('none');
  };

  const handleClose = () => {
    if (!localStorage.getItem('new-nav-help-step')) localStorage.setItem('new-nav-help-step', 'INFO');
    if (openMenus.shoppingCartMenu) resetCartValidation();

    setOpenMenus(defaultMenuStates);
    setOpenDialogs(defaultDialogStates);
    setExpandedState('none');
  };

  const handleNavBack = () => {
    if (mobileNavStep === 'nav' && !dropdownMenu) handleClose();
    if (mobileNavStep !== 'nav') setMobileNavStep('nav');

    setDropdownMenu(undefined);
  };

  const countrylabel = useMemo(() => {
    const country = availableCountries.find((c) => c.shortName === getCountryCode(locale));

    return country?.fullName ?? '';
  }, [locale, availableCountries]);

  const languageLabel = useMemo(() => {
    const language = availableLanguages.find((c) => c.shortName === getLanguageCode(locale));

    return language?.fullName ?? '';
  }, [locale, availableLanguages]);

  const colorIndex = businessContext.header?.navigationItemsCollection.items.findIndex((item) => 'sys' in item && item.sys.id === dropdownMenu?.sys?.id) ?? 0;

  const onFirstLoginDismiss = () => {
    localStorage.setItem('new-nav-help-step', 'END');
    setIsFirstLogin(false);
    setFirstLoginChanged(true);
  };

  const navAlignment = useMemo(() => businessContext?.header?.alignment ?? 'Left', [businessContext]);

  useEffect(() => {
    const navigateToCorrectCountryLocale = async () => {
      if (!accessToken) return;

      const customer = await getCustomer(accessToken);

      const defaultCustomerCountry = customer?.addresses?.find((address) => address.type === 'Legal Address' && address.isDefault)?.countryIsoCode;
      const countryFromStorage = localStorage.getItem('country-key');
      const currentCountry = getCountryCode(locale);

      const countryToSet = getFirstSupportedCountry([defaultCustomerCountry, countryFromStorage, currentCountry]);
      if (countryToSet !== currentCountry) {
        router.push(path.replace(currentCountry, countryToSet));
        localStorage.setItem('country-key', countryToSet);
      }
    };

    if (user) {
      if (sessionStorage.getItem('do-not-switch-back') === 'true') {
        sessionStorage.removeItem('do-not-switch-back');
        return;
      }
      navigateToCorrectCountryLocale();
    }
  }, [availableCountries, user]);

  useEffect(() => {
    setOpenMenus(defaultMenuStates);
    setDropdownMenu(undefined);
    setExpandedState('none');
  }, [desktop, tabLand, tabPort, phone]);

  useEffect(() => {
    if (!accessToken || !features['shopping-cart'] || !isCustomerPortal || isPublic) return;

    getShoppingCart(accessToken).then((shoppingCart) => {
      setShoppingCartBadge(shoppingCart.numberOfItems);
    });
  }, [accessToken, features]);

  useEffect(() => {
    document.documentElement.style.setProperty('--sticky-column-offset', user ? '180px' : '136px');

    if (!accessToken) return;

    getCustomer(accessToken).then(setCurrentCustomer);
  }, [user, accessToken]);

  useEffect(() => {
    if (expandedState === 'none' || expandedState === 'right') setTimeout(() => setDropdownMenu(undefined), 300);
  }, [expandedState]);

  useEffect(() => {
    if (user) {
      requestAnimationFrame(() => {
        setOpenMenus({
          ...defaultMenuStates,
          accountMenu: !phone && !localStorage.getItem('new-nav-help-step'),
          mobileAccountMenu: phone && !localStorage.getItem('new-nav-help-step')
        });
        setExpandedState(!phone && !localStorage.getItem('new-nav-help-step') ? 'right' : 'none');
      });
    }
  }, [user, phone, desktop]);

  useEffect(() => {
    if (firstLoginChanged && !phone) {
      setOpenMenus({ ...defaultMenuStates, accountMenu: true });
      setExpandedState('right');
      setFirstLoginChanged(false);
    }
  }, [openMenus]);

  useEffect(() => {
    setIsFirstLogin(!localStorage.getItem('new-nav-help-step') || localStorage.getItem('new-nav-help-step') === 'INFO');
  }, []);

  useEffect(() => {
    if (!accessToken) return;
    if (shoppingCartState.type) {
      handleMenuClick({ shoppingCartMenu: true });
      if (shoppingCartState.type === 'SUCCESS') {
        getShoppingCart(accessToken).then((shoppingCart) => {
          setShoppingCartBadge(shoppingCart.numberOfItems);
        });
      }
    } else {
      handleMenuClick({ shoppingCartMenu: false });
    }
  }, [shoppingCartState]);

  return (
    <>
      <Header
        sticky
        overflow="visible"
        onClose={handleClose}
        expanded={expandedState}
        container={!phone}
        toolbars={
          <NavBar
            size="large"
            showGutter={false}
            container={!phone}
            left={
              <Box height="100%" items="center" width="100%" spacing={{ left: 'lg' }}>
                <Box attributes={{ ...DataCy('navbar-logo-link') }} height="100%" spacing={{ y: '2xs' }} items="center" maxWidth="164px">
                  <Link style={{ height: '100%' }} onClick={handleClose} href={`/${locale}`}>
                    <Image height="100%" objectFit="contain" src={AssetUtils.optimizeImageIfRequired(businessContext.logo.url, acceptWebp)} alt={businessContext.name} title={businessContext.name} />
                  </Link>
                </Box>

                <BreakpointSwitch includeViews={['phone', 'tab-port', 'tab-land']}>
                  <IconButton attributes={{ ...DataCy('navbar-mobile-menu-trigger') }} icon={openMenus.mobileMenu ? 'times' : 'bars'} onClick={handleHamburgerClick} />
                </BreakpointSwitch>

                {(linkLegacyDigitalPlatform || (!linkLegacyDigitalPlatform && navAlignment === 'Left')) && (
                  <BreakpointSwitch includeViews={['desktop']}>
                    <Navigation businessContext={businessContext} locale={locale} onDropdownClick={(id) => handleMenuClick(defaultMenuStates, id)} currentDropdown={dropdownMenu} />
                  </BreakpointSwitch>
                )}
              </Box>
            }
            right={
              <Box items="center" gap="xs" height="100%" spacing={{ right: 'lg' }}>
                {linkLegacyDigitalPlatform && okta && !isPublic && !loading && user && features['shopping-cart'] && isCustomerPortal && (
                  <Box attributes={{ ...DataCy('navbar-shopping-cart-link') }} items="center" gap="xs" height="100%">
                    <Link title={translate('page-consumer.header.shopping-cart')} href={`/my/${NavigationService.getLanguage(locale)}/shopping-cart`}>
                      <IconButton icon="shopping-cart" badge={shoppingCartBadge > 0 ? shoppingCartBadge.toString() : undefined} />
                    </Link>
                  </Box>
                )}

                {(linkLegacyDigitalPlatform || (!linkLegacyDigitalPlatform && navAlignment === 'Left' && (availableCountries.length > 1 || availableLanguages.length > 1))) && (
                  <BreakpointSwitch includeViews={['desktop', 'tab-land']}>
                    <Box items="center" gap="xs" height="100%">
                      <IconButton attributes={{ ...DataCy('navbar-country-language-trigger') }} icon="globe" onClick={() => handleMenuClick({ countryLanguageMenu: !openMenus.countryLanguageMenu })} />
                    </Box>
                  </BreakpointSwitch>
                )}

                {linkLegacyDigitalPlatform && okta && !isPublic && !loading && (
                  <Box items="center" gap="xs" height="100%">
                    <AuthenticationButton
                      isOpen={openMenus.accountMenu || openMenus.loginMenu}
                      currentCustomer={currentCustomer}
                      onClick={() =>
                        handleMenuClick({
                          accountMenu: !phone && user && !openMenus.accountMenu,
                          mobileAccountMenu: phone && user && !openMenus.mobileAccountMenu,
                          loginMenu: !user && !openMenus.loginMenu
                        })
                      }
                    />
                  </Box>
                )}

                {!linkLegacyDigitalPlatform && navAlignment === 'Right' && (
                  <>
                    <BreakpointSwitch includeViews={['desktop']}>
                      <Navigation businessContext={businessContext} locale={locale} currentDropdown={dropdownMenu} onDropdownClick={(id) => handleMenuClick(defaultMenuStates, id)} />
                    </BreakpointSwitch>

                    {(availableCountries.length > 1 || availableLanguages.length > 1) && (
                      <BreakpointSwitch includeViews={['desktop', 'tab-land']}>
                        <Box items="center" gap="xs" height="100%">
                          <IconButton attributes={{ ...DataCy('navbar-country-language-trigger') }} icon="globe" onClick={() => handleMenuClick({ countryLanguageMenu: !openMenus.countryLanguageMenu })} />
                        </Box>
                      </BreakpointSwitch>
                    )}
                  </>
                )}
              </Box>
            }
          />
        }
        leftCollapse={{
          body: <MegaMenu menu={dropdownMenu} onNavigate={handleClose} locale={locale} colorIndex={colorIndex} />,
          fullWidth: true
        }}
        rightCollapse={{
          body: (
            <>
              {openMenus.countryLanguageMenu && <CountryLanguageMenu locale={locale} availableCountries={availableCountries} availableLanguages={availableLanguages} localizedRoutes={localizedRoutes} />}
              {openMenus.loginMenu && <LoginMenu locale={locale} registrationLandingPage={businessContext.registrationLandingPage} />}
              {openMenus.accountMenu && (
                <ProfileMenu
                  locale={locale}
                  features={features}
                  adminPlatformUrl={adminPortalUrl}
                  currentCustomer={currentCustomer}
                  isFirstLogin={isFirstLogin}
                  myOrdersUrl={myOrdersUrl}
                  myInvoicesUrl={myInvoicesUrl}
                  myRegistrationNumbersUrl={myRegistrationNumbersUrl}
                  myVatNumbersUrl={myVatNumbersUrl}
                  myAddressesUrl={myAddressesUrl}
                  onClick={() => handleMenuClick(defaultMenuStates)}
                  onTechKnowledgeBaseClick={() => setOpenDialogs({ ...defaultDialogStates, techKnowledgeBase: true })}
                  onCustomerSwitchClick={() => setOpenDialogs({ ...defaultDialogStates, customerSwitch: true })}
                  onFirstLoginDismiss={onFirstLoginDismiss}
                />
              )}
              {openMenus.shoppingCartMenu && <ShoppingCartMenu locale={locale} token={accessToken} />}
            </>
          ),
          fullWidth: phone
        }}
      />
      <BreakpointSwitch excludeViews={['desktop']}>
        <Flyout open={openMenus.mobileMenu && !desktop} onFlyoutClose={handleClose} position="left" width={phone ? '360px' : '400px'} disableGutters>
          <Box height="100vh" direction="column" gap="md" spacing={{ x: 'md', y: 'md' }}>
            <Box gap="xs" items="center">
              <IconButton attributes={{ ...DataCy('navbar-nav-back-trigger') }} icon="chevron-left" onClick={handleNavBack} />
              {dropdownMenu && (
                <Typography variant="heading-4" color="strong">
                  {dropdownMenu.label}
                </Typography>
              )}
            </Box>
            <Divider />
            {mobileNavStep === 'nav' && (
              <Box width="100%" height="100%" direction="column" gap="sm" justify="between">
                <Navigation businessContext={businessContext} locale={locale} onNavigate={() => handleMenuClick(defaultMenuStates)} onDropdownClick={setDropdownMenuFromId} currentDropdown={dropdownMenu} />
                {(availableCountries.length > 1 || availableLanguages.length > 1) && (
                  <>
                    <Divider />
                    <Box attributes={{ ...DataCy('navbar-mobile-country-language-trigger') }} height="44px" justify="between" items="center" onClick={() => setMobileNavStep('countryAndLanguage')}>
                      <Box gap="sm">
                        <Icon icon="globe" color="strong" />
                        <Typography>
                          {countrylabel} / {languageLabel}
                        </Typography>
                      </Box>
                      <Icon icon="chevron-right" />
                    </Box>
                  </>
                )}
              </Box>
            )}
            {mobileNavStep === 'countryAndLanguage' && <CountryLanguageMenu locale={locale} availableCountries={availableCountries} availableLanguages={availableLanguages} localizedRoutes={localizedRoutes} />}
          </Box>
        </Flyout>
        <Flyout open={openMenus.mobileAccountMenu && phone} onFlyoutClose={handleClose} position="right" disableGutters>
          <Box height="100vh" direction="column" gap="md" spacing={{ x: 'md', y: 'md' }}>
            <Box gap="xs" items="center" justify="between">
              <Typography variant="heading-4" color="strong">
                {translate('page-consumer.header.account.my-account')}
              </Typography>
              <IconButton icon="chevron-right" onClick={handleNavBack} />
            </Box>
            <Divider />
            <ProfileMenu
              locale={locale}
              features={features}
              adminPlatformUrl={adminPortalUrl}
              currentCustomer={currentCustomer}
              isFirstLogin={isFirstLogin}
              myOrdersUrl={myOrdersUrl}
              myInvoicesUrl={myInvoicesUrl}
              myRegistrationNumbersUrl={myRegistrationNumbersUrl}
              myVatNumbersUrl={myVatNumbersUrl}
              myAddressesUrl={myAddressesUrl}
              onClick={() => () => handleMenuClick(defaultMenuStates)}
              onTechKnowledgeBaseClick={() => setOpenDialogs({ ...defaultDialogStates, techKnowledgeBase: true })}
              onCustomerSwitchClick={() => setOpenDialogs({ ...defaultDialogStates, customerSwitch: true })}
              onFirstLoginDismiss={onFirstLoginDismiss}
            />
          </Box>
        </Flyout>
      </BreakpointSwitch>
      <TechKnowledgeBaseDialog open={openDialogs.techKnowledgeBase} data={technicalSupport} onDialogClose={() => setOpenDialogs(defaultDialogStates)} />
      {user && <CustomerSwitchDialog open={openDialogs.customerSwitch} getCustomer={getCustomer} getCustomers={getCustomers} setCustomer={setCustomer} onDialogClose={() => setOpenDialogs(defaultDialogStates)} />}
    </>
  );
};

export default Navbar;
