'use client';

import React, { useEffect, useState } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { BusinessContext } from '@ravago/shared/page-data/models/config';
import { OrderLineDetail, OrderLineDocumentResponse, Status } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';
import { DateUtils, DeliveryInformation, DynamicPageLink, getLanguageCode, useFlyoutContext, useOrderLineContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Avatar } from '@ravago/shared/radiance/components/avatar/components/Avatar/Avatar';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Chip } from '@ravago/shared/radiance/components/chip/components/Chip/Chip';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';
import { Flyout } from '@ravago/shared/radiance/components/flyout/components/Flyout/Flyout';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import { useAuthentication } from '@ravago/shared/authentication';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';

import { getDynamicPageUrls, getOrderLine, getOrderLineDocuments, getProductByEntryId, getSystemPageUrl } from '../../../../actions';

import OrderLineButtons from './components/order-line-buttons';
import OrderLineDetailsTable from './components/order-line-details-table';
import OrderLineDocuments from './components/order-line-documents';
import ShipmentsInfo from './components/shipments-info';

interface Props {
  locale: string;
  businessContext: BusinessContext;
}

const mapToTranslation: { [key in Status]: string } = {
  'Order Received': 'shipment.status.order-received',
  Planned: 'shipment.status.processing',
  Processing: 'shipment.status.processing',
  'Order Confirmed': 'shipment.status.order-confirmed',
  'Transport Arranged': 'shipment.status.transport-arranged',
  'In Transit': 'shipment.status.in-transit',
  Closed: 'shipment.status.closed',
  Invoiced: 'order.status.invoiced',
  Credited: 'order.status.credited',
  'Awaiting Call Off': 'order.status.awaiting-call-off',
  'Call Off Overdue': 'order.status.call-off-overdue'
};

const OrderLineFlyout: React.FC<Props> = ({ locale, businessContext }: Props) => {
  const translate = useTranslations();
  const languageCode = getLanguageCode(locale);

  const { accessToken, user } = useAuthentication();

  const { state, dispatch } = useFlyoutContext();

  const { state: orderLineState, dispatch: orderLineDispatch } = useOrderLineContext();

  const { phone } = useBreakpoint();

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDocuments, setHasDocuments] = useState(false);
  const [orderLine, setOrderLine] = useState<OrderLineDetail>();
  const [orderLineDocuments, setOrderLineDocuments] = useState<OrderLineDocumentResponse>();
  const [productsBaseRoute, setProductsBaseRoute] = useState<DynamicPageUrl>();
  const [orderDetailBaseRoute, setOrderDetailBaseRoute] = useState('');
  const [callOffAgreementBaseRoute, setCallOffAgreementBaseRoute] = useState('');
  const [shipmentDetailBaseRoute, setShipmentDetailBaseRoute] = useState('');

  const checkDocuments = (documents: OrderLineDocumentResponse) => {
    const { invoices, coa, deliveryNotes, billsOfLading } = documents;
    return (invoices && invoices.length > 0) || (coa && coa.length > 0) || (deliveryNotes && deliveryNotes.length > 0) || (billsOfLading && billsOfLading.length > 0);
  };

  const getUrls = async () => {
    const [orderDetailRoute, shipmentDetailRoute, callOffAgreementRoute, dynamicProductPageUrl] = await Promise.all([
      getSystemPageUrl(locale, SystemPageType.ORDER_DETAIL),
      getSystemPageUrl(locale, SystemPageType.SHIPMENT),
      getSystemPageUrl(locale, SystemPageType.CALL_OFF_ORDER_DETAIL),
      getDynamicPageUrls(locale, ['product'])
    ]);
    setOrderDetailBaseRoute(orderDetailRoute);
    setShipmentDetailBaseRoute(shipmentDetailRoute);
    setCallOffAgreementBaseRoute(callOffAgreementRoute);
    setProductsBaseRoute({ url: dynamicProductPageUrl.product });
  };

  useEffect(() => {
    const fetchOrderLineDetails = async () => {
      if (!accessToken || !orderLineState.orderId || !orderLineState.orderNumber) return;
      setIsLoading(true);
      try {
        const orderLineResponse = await getOrderLine(accessToken, orderLineState.orderId, orderLineState.orderNumber);
        if (!orderLineResponse) return;
        const documents = await getOrderLineDocuments(accessToken, orderLineState.orderId.toString(), orderLineState.orderNumber);
        if (orderLineState.contentfulProduct) {
          orderLineResponse.contentfulProduct = orderLineState.contentfulProduct;
        } else if (orderLineResponse.product.pkbId) {
          try {
            orderLineResponse.contentfulProduct = await getProductByEntryId(locale, `product-${orderLineResponse.product.pkbId}`);
          } catch (err) {
            orderLineResponse.contentfulProduct = undefined;
          }
        }
        setOrderLine(orderLineResponse);
        getUrls();
        setOrderLineDocuments(documents);
        setHasDocuments(documents ? checkDocuments(documents) : false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrderLineDetails();
  }, [accessToken, orderLineState]);

  const handleFlyoutClose = () => {
    if (dispatch) {
      dispatch({ type: 'close_flyout' });
    }
    if (orderLineDispatch) {
      orderLineDispatch({ type: 'clear_order', value: {} });
      setOrderLine(undefined);
    }
  };

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <Flyout position={phone ? 'bottom' : 'right'} width="100%" open={state.idOpenDialog === 'order-line'} closeOnOutsideClick onFlyoutClose={handleFlyoutClose}>
      {orderLine && orderLineState.orderId && user ? (
        <Box direction="column" gap="lg" spacing={{ top: 'none', right: 'md', bottom: 'md', left: 'md' }} overflowY="auto" height="100%">
          <Box justify="between" items="center">
            <Typography component="span" variant="body-highlight" color="strong">
              {translate('page-consumer.order-line-flyout.order')} {orderLine.orderNumber}
            </Typography>
            <IconButton icon="times" onClick={handleFlyoutClose} />
          </Box>
          <Divider />
          <Box direction="column">
            <Box justify="between" items="center">
              {orderLine.status && (
                <Badge size="regular" variant="info" weight="bold">
                  {translate(mapToTranslation[orderLine.status])}
                </Badge>
              )}
              {orderLine.deliveryDate && (
                <Chip
                  type={
                    <Typography variant="small" component="span" color="strong">
                      {translate('page-consumer.order-line-flyout.estimated-delivery')}
                    </Typography>
                  }
                  label={
                    <Typography variant="small-highlight" component="span" align="left" color="strong">
                      {DateUtils.transformOrderDeliveryDate(orderLine.deliveryDate)}
                    </Typography>
                  }
                />
              )}
            </Box>
            <Box direction="column" gap="md">
              <Box items="center">
                <Avatar size="large" shape="square" src={orderLine.contentfulProduct?.brand?.producer?.logo?.url} />
                <Box direction="column" gap="none">
                  {orderLine.contentfulProduct ? (
                    <DynamicPageLink id={`dynamic-link__order-line__${orderLine.contentfulProduct.slug}`} locale={locale} baseRoute={productsBaseRoute} slug={orderLine.contentfulProduct.slug}>
                      <Typography component="span" variant="body-highlight" color="primary">
                        {orderLine.product.description}
                      </Typography>
                    </DynamicPageLink>
                  ) : (
                    <Typography component="span" variant="body-highlight" color="primary">
                      {orderLine.product.description}
                    </Typography>
                  )}
                  {orderLine.customerProductReference && (
                    <Typography component="span" variant="body">
                      {orderLine.customerProductReference}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <OrderLineDetailsTable translate={translate} orderLine={orderLine} />
          <OrderLineButtons
            showOrderDetailButton={orderLineState.openedFrom !== 'order_detail'}
            showCallOffAgreementButton={orderLineState.openedFrom !== 'call_off_agreement'}
            language={languageCode}
            locale={locale}
            orderId={orderLineState.orderId.toString()}
            orderDetailBaseRoute={orderDetailBaseRoute}
            callOffOrderDetailBaseRoute={callOffAgreementBaseRoute}
            agreementNumber={orderLine.agreementNumber}
          />
          <Divider />
          <Expand
            expanded={isAccordionOpen}
            header={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link underline="none" onClick={handleAccordionToggle}>
                <Box direction="row" justify="between" items="center">
                  <Box>
                    <Typography component="h4" variant="heading-4" color="strong">
                      {translate('page-consumer.order-line-flyout.shipment-information')}
                    </Typography>
                    {orderLine.shipmentIds && orderLine.shipmentIds.length > 1 && (
                      <Badge size="regular" variant="primary">
                        {orderLine.shipmentIds.length}
                      </Badge>
                    )}
                  </Box>

                  <Icon icon={isAccordionOpen ? 'chevron-up' : 'chevron-down'} />
                </Box>
              </Link>
            }
          >
            <Box direction="column" spacing={{ top: 'md' }} gap="md">
              {orderLine.shipmentIds && orderLine.shipmentIds.length > 0 && orderLineState.openedFrom !== 'shipment_detail' && <ShipmentsInfo shipmentIds={orderLine.shipmentIds} locale={locale} baseRoute={shipmentDetailBaseRoute} />}
              <DeliveryInformation destinationAddress={orderLine.destinationAddress} deliveryTermAddress={orderLine.deliveryTermAddress} deliveryInstructions={orderLine.deliveryInstructions} deliveryTerm={orderLine.deliveryTerm} isInFlyout />
            </Box>
          </Expand>
          <Divider />
          {hasDocuments && orderLineState.orderId && <OrderLineDocuments orderLine={orderLine} documents={orderLineDocuments} businessContext={businessContext} />}
        </Box>
      ) : (
        isLoading && (
          <Box direction="column" items="center">
            <Spinner />
          </Box>
        )
      )}
    </Flyout>
  );
};

export default OrderLineFlyout;
