import { FC, useEffect, useState } from 'react';

import { DataCy, getCountryCode, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useAuthentication } from '@ravago/shared/authentication';

import { usePathname } from 'next/navigation';

import { Country } from '../../../services/contentful/locale.service';

interface Props {
  locale: string;
  countries: Array<Country>;
}

const CountrySwitch: FC<Props> = ({ locale, countries }: Props) => {
  const translate = useTranslations();
  const countryCode = getCountryCode(locale);

  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<DropdownOption | undefined>(undefined);

  const { user } = useAuthentication();
  const pathname = usePathname();

  const handleCountrySelect = (countryOption?: DropdownOption) => {
    if (!countryOption) return;

    setSelectedCountry(countryOption);

    if (user) sessionStorage.setItem('do-not-switch-back', 'true');
    localStorage.setItem('country-key', countryOption.id);

    window.location.replace(pathname.replace(countryCode, countryOption.id));
  };

  useEffect(() => {
    setCountryOptions(
      countries
        .map((country) => ({
          id: country.shortName,
          value: country.fullName,
          icon: country.shortName
        }))
        .sort((a, b) => a.value.localeCompare(b.value))
    );
  }, [countries]);

  useEffect(() => {
    setSelectedCountry(countryOptions.find((option) => option.id === countryCode));
  }, [countryOptions]);

  return (
    <Box direction="column" gap="sm">
      <Typography variant="body-highlight" color="strong">
        {translate('page-consumer.header.country-selector.header')}
      </Typography>
      <Typography variant="body" color="subtle">
        {translate('page-consumer.header.country-selector.description')}
      </Typography>

      <Dropdown
        id="country-selector"
        attributes={{ ...DataCy('country-switch-dropdown') }}
        emptyOption={false}
        label={translate('page-consumer.header.country-selector.label')}
        value={selectedCountry}
        iconType="flag"
        leadingIcon={selectedCountry?.id}
        options={countryOptions}
        onChange={handleCountrySelect}
        disabled={!!user}
      />
    </Box>
  );
};

export default CountrySwitch;
