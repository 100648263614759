import(/* webpackMode: "eager", webpackExports: ["capitalize"] */ "__barrel_optimize__?names=capitalize!=!/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/bottom-container/bottom-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/call-off-agreement-flyout/call-off-agreement-flyout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/enable-customer-handler/enable-customer-handler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/footer/footer.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/main-structure/main-structure.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/navbar/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/order-line-flyout/order-line-flyout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/page-wide-dialog/page-wide-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/request-for-quotation-flyout/request-for-quotation-flyout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/ribbon/ribbon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/widget/share-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/widget/widget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/hooks/gtm-user-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useAuthenticationContext","PageProducerAuthenticationContextProvider","PreviewClientAuthenticationContextProvider"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/authentication/src/lib/contexts/authentication-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useAuthentication"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/authentication/src/lib/hooks/use-authentication.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/authentication/src/lib/providers/authentication-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogFormProvider"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/context/dialog-form-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WidgetProvider"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/context/widget-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BottomContainerProvider"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/bottom-container-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CallOffAgreementProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/call-off-agreement-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/config-context-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/dialog-context-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlyoutProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/flyout-context-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrderLineProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/order-line-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestForQuotationProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/request-for-quotation-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RibbonProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/ribbon-context-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShoppingCartProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/shopping-cart-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationClientContextProvider"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/translation-client-context-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/radiance/src/styles/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/next/dist/client/script.js");
