'use client';

import { FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { RequestForQuotationContext } from '../context/request-for-quotation-context';
import { RequestForQuotationReducer, initialState, RequestForQuotationState } from '../reducers/request-for-quotation-reducer';

const isCallOffValid = (state: RequestForQuotationState) => {
   const selectedCallOffId = state.color.callOffOrderId;
   const selectedCallOff = selectedCallOffId
      ? state.callOffOrderColors?.find((color) => color.recommendedCallOff.id === selectedCallOffId)
      : undefined;

   if (!selectedCallOff) return false;

   return (
      !!state.color.quantity &&
      state.color.quantity.amount > 0 &&
      state.color.quantity.amount <= (selectedCallOff.recommendedCallOff.remainingQuantity.amount ?? 0)
   );
};

export const RequestForQuotationProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(RequestForQuotationReducer, initialState);

   const contextValue = useMemo(
      () => ({ state, dispatch, isRfqValid: !!state.color.colorId, isCallOffValid: isCallOffValid(state) }),
      [state, dispatch]
   );

   return <RequestForQuotationContext.Provider value={contextValue}>{children}</RequestForQuotationContext.Provider>;
};
