import { useMemo } from 'react';

import { ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { AppUtils, DynamicPageLink, IndustriesSegment, useProductCompare, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Avatar } from '@ravago/shared/radiance/components/avatar/components/Avatar/Avatar';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableFooter } from '@ravago/shared/radiance/components/table/components/TableFooter/TableFooter';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { TableSubheader } from '@ravago/shared/radiance/components/table/components/TableSubheader/TableSubheader';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { getDynamicPageUrls } from 'apps/next-page-consumer/app/actions';

interface Props {
  locale: string;
  baseRoute: string;
  productsToCompareList: ContentfulProduct[];
}

const ProductCompareTable = ({ baseRoute, locale, productsToCompareList }: Props) => {
  const translate = useTranslations();
  const { removeProductFromCompareList } = useProductCompare();

  const displayedProducts = useMemo(() => {
    const products = [...productsToCompareList];
    const emptySlotsNeeded = 5 - products.length;

    for (let i = 0; i < emptySlotsNeeded; i += 1) {
      products.push({ id: `empty-${i}` } as ContentfulProduct);
    }
    return products.slice(0, 5);
  }, [productsToCompareList]);

  const specificationTypes = useMemo(() => {
    const types: string[] = [];
    productsToCompareList.forEach((product) => {
      if (product.specifications) {
        product.specifications.forEach((specification) => {
          if (specification.type && !types.includes(specification.type)) {
            types.push(specification.type);
          }
        });
      }
    });
    return types;
  }, [productsToCompareList]);

  return (
    <Table snapToColumn height="100%">
      <TableHead>
        <TableRow>
          {displayedProducts.map((product, index) => (
            <TableHeader width="300px" key={AppUtils.getKey(`actions-${product.id}`, index)}>
              {product.brand && !product.id.includes('empty-') && (
                <Box gap="2xs" direction="column" spacing={{ y: 'md' }} width="100%">
                  <Box justify="between" gap="md" items="center">
                    <Avatar src={product.brand.producer.logo?.url ?? ''} title={product.brand.producer.name} shape="square" />
                    <DynamicPageLink id={`dynamic-link__product-compare-avatar__${product.slug}`} baseRoute={{ url: baseRoute, noFollow: product.seoMetadata?.noFollow }} slug={product.slug} locale={locale}>
                      <Typography whiteSpace="normal" variant="body-highlight" color="primary">
                        {product.name}
                      </Typography>
                    </DynamicPageLink>
                    <IconButton icon="trash" onClick={() => removeProductFromCompareList(product.id)} />
                  </Box>
                  {!product.ulProductId && (
                    <Box content="start" justify="start" items="start">
                      <Typography component="span" variant="small" color="subtle">
                        {translate('page-consumer.product-compare.limited-information')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableSubheader colSpan={5}>{translate('page-consumer.product-compare.table.general-information')}</TableSubheader>
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`brand-${product.id}`}>
              {product.brand && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.brand')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.brand.name}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`producer-${product.id}`}>
              {product.brand && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.producer')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.brand.producer.name}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`polymer-${product.id}`}>
              {product.polymerGroup && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.polymer')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.polymerGroup}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`form-${product.id}`}>
              {product.form && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.form')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.form}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`quality-${product.id}`}>
              {product.brand && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.quality')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.quality.name}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableSubheader colSpan={5}>{translate('page-consumer.product-compare.table.related-properties')}</TableSubheader>
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`technologies-${product.id}`}>
              {product.processes && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.technologies')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.processes.map((p) => p.name).join(' • ')}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`features-${product.id}`}>
              {product.features && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.features')}
                  </Typography>
                  <Typography component="span" variant="body">
                    {product.features.map((f) => f.name).join(' • ')}
                  </Typography>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {displayedProducts.map((product) => (
            <TableCell width="300px" verticalAlign="top" key={`markets-and-segments-${product.id}`}>
              {product.segments && (
                <Box width="100%" direction="column" gap="2xs">
                  <Typography variant="body-highlight" component="span">
                    {translate('page-consumer.product-compare.table.markets-and-segments')}
                  </Typography>
                  <IndustriesSegment segments={product.segments} locale={locale} getDynamicPageUrls={getDynamicPageUrls} />
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableSubheader colSpan={5}>{translate('page-consumer.product-compare.table.specifications')}</TableSubheader>
        </TableRow>
        {specificationTypes.length > 0 ? (
          specificationTypes.map((typee) => (
            <TableRow key={typee}>
              {displayedProducts.map((product) => (
                <TableCell width="300px" verticalAlign="top" key={`specifications-${typee}-${product.id}`}>
                  {product.specifications && (
                    <Box width="100%" direction="column" gap="2xs">
                      <Typography variant="body-highlight" component="span">
                        {typee ?? '-'}
                      </Typography>
                      <Typography variant="body" component="span">
                        {product.specifications.find((spec) => spec.type === typee)?.name ?? '-'}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5}>
              <Typography variant="body">{translate('page-consumer.product-compare.table.products-specifications-empty')}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {displayedProducts.map((product, index) => (
            <TableCell width="300px" key={AppUtils.getKey(`actions-${product.id}`, index)}>
              {product.id && !product.id.includes('empty-') && (
                <Box spacing={{ y: 'sm' }} direction="column">
                  <DynamicPageLink id={`dynamic-link__product-compare-footer__${product.slug}`} baseRoute={{ url: baseRoute, noFollow: product.seoMetadata?.noFollow }} slug={product.slug} locale={locale} underline="none">
                    <Button fullWidth variant="secondary">
                      {translate('page-consumer.product-compare.table.go-to-product')}
                    </Button>
                  </DynamicPageLink>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default ProductCompareTable;
