'use client';

import { FC, useState } from 'react';

import { getLanguageCode, useDialogContext, useDocumentShare, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Checkbox } from '@ravago/shared/radiance/components/checkbox/components/Checkbox/Checkbox';
import { Dialog } from '@ravago/shared/radiance/components/dialog/components/Dialog/Dialog';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import RequestMissingDocumentsForm from './components/request-missing-documents-form';

interface Props {
  locale: string;
}

const PageWideDialog: FC<Props> = ({ locale }) => {
  const translate = useTranslations();
  const languageCode = getLanguageCode(locale);
  const [removeWarningChecked, setRemoveWarningChecked] = useState<boolean>(false);
  const { state, dispatch } = useDialogContext();
  const { shareDocumentList, setDownloadList } = useDocumentShare();

  const handleOnCheckChange = (check: boolean) => {
    setRemoveWarningChecked(check);
  };

  const handleCloseDialog = () => {
    if (dispatch) {
      dispatch({ type: 'close_dialog' });
    }
  };

  const downloadDespiteWarning = () => {
    if (removeWarningChecked) {
      localStorage.setItem('hideInteralDocumentWarning', new Date().toString());
      setRemoveWarningChecked(false);
    }
    if (dispatch) {
      dispatch({ type: 'download_despite_warning' });
    }
  };

  const onDownloadAllClick = () => {
    if (removeWarningChecked) {
      localStorage.setItem('hideInteralDocumentWarning', new Date().toString());
      setRemoveWarningChecked(false);
    }
    if (dispatch) {
      setDownloadList(shareDocumentList.documents);
      dispatch({ type: 'close_dialog' });
    }
  };

  return (
    <>
      <RequestMissingDocumentsForm title={state.title} open={state.idOpenDialog === 'request-documents-form'} />
      <Dialog open={state.idOpenDialog === 'document-is-downloading'} closable={false} closeOnOutsideClick={false} title={translate('page-consumer.related-documents.downloading-document', { documentName: state.title ?? '' })}>
        <Box direction="row" justify="center" height="60px" width="100%">
          <Spinner />
        </Box>
      </Dialog>
      <Dialog open={state.idOpenDialog === 'multiple-documents-are-loading'} closable={false} closeOnOutsideClick={false} title={translate('page-consumer.related-documents.downloading-documents')}>
        <Box direction="row" justify="center" height="60px" width="100%">
          <Spinner />
        </Box>
      </Dialog>
      <Dialog open={state.idOpenDialog === 'internal-document-warning'} closable={false} closeOnOutsideClick={false} title={translate('page-consumer.related-documents.acknowledge')}>
        <Box direction="column" maxWidth="560px">
          <Box direction="column" gap="md">
            {!state.documentId && (
              <Typography variant="body" component="p">
                {translate('page-consumer.related-documents.internal-warning-multiple', { internal: state.internal?.amountOfInternalDocuments?.toString() ?? '', total: state.internal?.totalAmountOfDocuments?.toString() ?? '' })}
              </Typography>
            )}
            {state.documentId && (
              <Typography variant="body" component="p">
                {translate('page-consumer.related-documents.internal-warning')}
              </Typography>
            )}
            <Checkbox id="remove-warning-checkbox" checked={removeWarningChecked} onChange={() => handleOnCheckChange(!removeWarningChecked)}>
              <Typography variant="body" component="p">
                {translate('page-consumer.related-documents.internal-warning-stop')}
              </Typography>
            </Checkbox>
          </Box>
          <Box direction="row" width="100%" justify="end">
            <Button onClick={() => handleCloseDialog()} variant="secondary">
              {translate('page-consumer.related-documents.cancel')}
            </Button>
            <Button onClick={() => (state.documentId ? downloadDespiteWarning() : onDownloadAllClick())}>{translate('page-consumer.related-documents.understand')}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PageWideDialog;
