import { FC } from 'react';

import { CallOffOrderDetail } from '@ravago/shared/page-data/models/elements';
import { OrderPriceInfoRow } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  callOffOrderDetail: CallOffOrderDetail;
  showTotalQuantity?: boolean;
}
const CallOffProductInfo: FC<Props> = ({ translate, callOffOrderDetail, showTotalQuantity = false }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" tableLayout="fixed" hover={false} rowDividers={false}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.your-order-reference')}
            </Typography>
          </TableCell>
          <TableCell>{callOffOrderDetail.customerProductReferences[0] || '-'}</TableCell>
        </TableRow>
        {showTotalQuantity && (
          <TableRow>
            <TableCell>
              <Typography component="b" variant="body-highlight" color="primary">
                {translate('page-consumer.call-off-agreement-flyout.total-quantity')}
              </Typography>
            </TableCell>
            <TableCell>
              {callOffOrderDetail.totalQuantity.amount} {callOffOrderDetail.totalQuantity.unitOfMeasure}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.packaging')}
            </Typography>
          </TableCell>
          <TableCell>{callOffOrderDetail.packagings[0] || '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.colour')}
            </Typography>
          </TableCell>
          <TableCell>{translate(`colors.${callOffOrderDetail.product.color.toLowerCase().replace(' ', '-')}`) + (callOffOrderDetail.product.producerColor ? ` - ${callOffOrderDetail.product.producerColor}` : '')}</TableCell>
        </TableRow>
        <OrderPriceInfoRow unitPrice={callOffOrderDetail.unitPrice} isUnitPriceFixed={callOffOrderDetail.isUnitPriceFixed} />
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.call-off-agreement-flyout.comment')}
            </Typography>
          </TableCell>
          <TableCell>{callOffOrderDetail.orderConfirmationTexts.join(' • ') || '-'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default CallOffProductInfo;
