import { FC } from 'react';

import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Radio } from '@ravago/shared/radiance/components/radio/components/Radio/Radio';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  address: Address;
  selectedAddress: Address | undefined;
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
}

const RfqAddressPicker: FC<Props> = ({ address, selectedAddress, translate }: Props) => (
  <Radio id={`customer-delivery-address-${address.id}`} value={address}>
    {address.isDefault && (
      <Badge variant="info">
        <Typography component="p" variant="small" color="inherit">
          {translate('page-consumer.request-for-quotation.default')}
        </Typography>
      </Badge>
    )}
    {!address.isDefault && address.id === selectedAddress?.id && (
      <Badge variant="success">
        <Typography component="p" variant="small" color="inherit">
          {translate('page-consumer.request-for-quotation.selected')}
        </Typography>
      </Badge>
    )}
    <Typography component="b" variant="body" color="strong">
      {`${address.line1}, ${address.line2}`}
    </Typography>
    <Typography component="p" variant="body" color="strong">
      {`${address.city}, ${address.postCode} - ${address.countryName}`}
    </Typography>
  </Radio>
);

export default RfqAddressPicker;
