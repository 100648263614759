// eslint-disable-next-line no-shadow
export enum CountryCode {
   AF = 'AF',
   AL = 'AL',
   DZ = 'DZ',
   AS = 'AS',
   AD = 'AD',
   AO = 'AO',
   AI = 'AI',
   AQ = 'AQ',
   AG = 'AG',
   AR = 'AR',
   AM = 'AM',
   AW = 'AW',
   AU = 'AU',
   AT = 'AT',
   AZ = 'AZ',
   BS = 'BS',
   BH = 'BH',
   BD = 'BD',
   BB = 'BB',
   BY = 'BY',
   BE = 'BE',
   BZ = 'BZ',
   BJ = 'BJ',
   BM = 'BM',
   BT = 'BT',
   BO = 'BO',
   BA = 'BA',
   BW = 'BW',
   BR = 'BR',
   IO = 'IO',
   BN = 'BN',
   BG = 'BG',
   BF = 'BF',
   BI = 'BI',
   KH = 'KH',
   CM = 'CM',
   CA = 'CA',
   CV = 'CV',
   KY = 'KY',
   CF = 'CF',
   TD = 'TD',
   CL = 'CL',
   CN = 'CN',
   CX = 'CX',
   CC = 'CC',
   CO = 'CO',
   KM = 'KM',
   CG = 'CG',
   CD = 'CD',
   CK = 'CK',
   CR = 'CR',
   CI = 'CI',
   HR = 'HR',
   CU = 'CU',
   CY = 'CY',
   CZ = 'CZ',
   DK = 'DK',
   DJ = 'DJ',
   DM = 'DM',
   DO = 'DO',
   EC = 'EC',
   EG = 'EG',
   SV = 'SV',
   GQ = 'GQ',
   ER = 'ER',
   EE = 'EE',
   ET = 'ET',
   FK = 'FK',
   FO = 'FO',
   FJ = 'FJ',
   FI = 'FI',
   FR = 'FR',
   GF = 'GF',
   PF = 'PF',
   GA = 'GA',
   GM = 'GM',
   GE = 'GE',
   DE = 'DE',
   GH = 'GH',
   GI = 'GI',
   GR = 'GR',
   GL = 'GL',
   GD = 'GD',
   GP = 'GP',
   GU = 'GU',
   GT = 'GT',
   GN = 'GN',
   GW = 'GW',
   GY = 'GY',
   HT = 'HT',
   VA = 'VA',
   HN = 'HN',
   HK = 'HK',
   HU = 'HU',
   IS = 'IS',
   IN = 'IN',
   ID = 'ID',
   IR = 'IR',
   IQ = 'IQ',
   IE = 'IE',
   IL = 'IL',
   IT = 'IT',
   JM = 'JM',
   JP = 'JP',
   JO = 'JO',
   KZ = 'KZ',
   KE = 'KE',
   KI = 'KI',
   KR = 'KR',
   KP = 'KP',
   KW = 'KW',
   KG = 'KG',
   LA = 'LA',
   LV = 'LV',
   LB = 'LB',
   LS = 'LS',
   LR = 'LR',
   LY = 'LY',
   LI = 'LI',
   LT = 'LT',
   LU = 'LU',
   MO = 'MO',
   MK = 'MK',
   MG = 'MG',
   MW = 'MW',
   MY = 'MY',
   MV = 'MV',
   ML = 'ML',
   MT = 'MT',
   MH = 'MH',
   MQ = 'MQ',
   MR = 'MR',
   MU = 'MU',
   YT = 'YT',
   MX = 'MX',
   FM = 'FM',
   MD = 'MD',
   MC = 'MC',
   MN = 'MN',
   ME = 'ME',
   MS = 'MS',
   MA = 'MA',
   MZ = 'MZ',
   MM = 'MM',
   NA = 'NA',
   NR = 'NR',
   NP = 'NP',
   NL = 'NL',
   NC = 'NC',
   NZ = 'NZ',
   NI = 'NI',
   NE = 'NE',
   NG = 'NG',
   NU = 'NU',
   NF = 'NF',
   MP = 'MP',
   NO = 'NO',
   OM = 'OM',
   PK = 'PK',
   PW = 'PW',
   PS = 'PS',
   PA = 'PA',
   PG = 'PG',
   PY = 'PY',
   PE = 'PE',
   PH = 'PH',
   PL = 'PL',
   PT = 'PT',
   PR = 'PR',
   QA = 'QA',
   RE = 'RE',
   RO = 'RO',
   RU = 'RU',
   RW = 'RW',
   SH = 'SH',
   KN = 'KN',
   LC = 'LC',
   PM = 'PM',
   VC = 'VC',
   WS = 'WS',
   SM = 'SM',
   ST = 'ST',
   SA = 'SA',
   SN = 'SN',
   RS = 'RS',
   SC = 'SC',
   SL = 'SL',
   SG = 'SG',
   SK = 'SK',
   SI = 'SI',
   SB = 'SB',
   SO = 'SO',
   ZA = 'ZA',
   GS = 'GS',
   ES = 'ES',
   LK = 'LK',
   SD = 'SD',
   SR = 'SR',
   SJ = 'SJ',
   SZ = 'SZ',
   SE = 'SE',
   CH = 'CH',
   SY = 'SY',
   TW = 'TW',
   TJ = 'TJ',
   TZ = 'TZ',
   TH = 'TH',
   TL = 'TL',
   TG = 'TG',
   TK = 'TK',
   TO = 'TO',
   TT = 'TT',
   TN = 'TN',
   TR = 'TR',
   TM = 'TM',
   TC = 'TC',
   TV = 'TV',
   UG = 'UG',
   UA = 'UA',
   AE = 'AE',
   GB = 'GB',
   US = 'US',
   UY = 'UY',
   UZ = 'UZ',
   VU = 'VU',
   VE = 'VE',
   VN = 'VN',
   VG = 'VG',
   VI = 'VI',
   WF = 'WF',
   EH = 'EH',
   YE = 'YE',
   ZM = 'ZM',
   ZW = 'ZW'
}

// eslint-disable-next-line no-shadow
export enum PhoneInputErrorMessages {
   INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
   INVALID_PHONE_NUMBER_CHAR = 'INVALID_PHONE_NUMBER_CHAR',
   INVALID_PHONE_NUMBER_LENGTH = 'INVALID_PHONE_NUMBER_LENGTH',
   VALID_PHONE_NUMBER = 'VALID_PHONE_NUMBER'
}

export type CountryInfo = {
   name: string;
   iso2: CountryCode;
   prefix: string;
   flag: string;
};

export interface PhoneDetails {
   countryInfo?: CountryInfo;
   phone: string;
}

export type CountryCodeInfo = {
   [key in CountryCode]: CountryInfo;
};

const assetBasePath = 'https://halo.ravago.com/assets/country-flags';

export const Countries: CountryCodeInfo = {
   [CountryCode.AF]: {
      name: 'Afghanistan',
      iso2: CountryCode.AF,
      prefix: '93',
      flag: `${assetBasePath}/${CountryCode.AF.toLowerCase()}.svg`
   },
   [CountryCode.AL]: {
      name: 'Albania',
      iso2: CountryCode.AL,
      prefix: '355',
      flag: `${assetBasePath}/${CountryCode.AL.toLowerCase()}.svg`
   },
   [CountryCode.DZ]: {
      name: 'Algeria',
      iso2: CountryCode.DZ,
      prefix: '213',
      flag: `${assetBasePath}/${CountryCode.DZ.toLowerCase()}.svg`
   },
   [CountryCode.AS]: {
      name: 'American Samoa',
      iso2: CountryCode.AS,
      prefix: '1-684',
      flag: `${assetBasePath}/${CountryCode.AS.toLowerCase()}.svg`
   },
   [CountryCode.AD]: {
      name: 'Andorra',
      iso2: CountryCode.AD,
      prefix: '376',
      flag: `${assetBasePath}/${CountryCode.AD.toLowerCase()}.svg`
   },
   [CountryCode.AO]: {
      name: 'Angola',
      iso2: CountryCode.AO,
      prefix: '244',
      flag: `${assetBasePath}/${CountryCode.AO.toLowerCase()}.svg`
   },
   [CountryCode.AI]: {
      name: 'Anguilla',
      iso2: CountryCode.AI,
      prefix: '1-264',
      flag: `${assetBasePath}/${CountryCode.AI.toLowerCase()}.svg`
   },
   [CountryCode.AQ]: {
      name: 'Antarctica',
      iso2: CountryCode.AQ,
      prefix: '672',
      flag: `${assetBasePath}/${CountryCode.AQ.toLowerCase()}.svg`
   },
   [CountryCode.AG]: {
      name: 'Antigua and Barbuda',
      iso2: CountryCode.AG,
      prefix: '1-268',
      flag: `${assetBasePath}/${CountryCode.AG.toLowerCase()}.svg`
   },
   [CountryCode.AR]: {
      name: 'Argentina',
      iso2: CountryCode.AR,
      prefix: '54',
      flag: `${assetBasePath}/${CountryCode.AR.toLowerCase()}.svg`
   },
   [CountryCode.AM]: {
      name: 'Armenia',
      iso2: CountryCode.AM,
      prefix: '374',
      flag: `${assetBasePath}/${CountryCode.AM.toLowerCase()}.svg`
   },
   [CountryCode.AW]: {
      name: 'Aruba',
      iso2: CountryCode.AW,
      prefix: '297',
      flag: `${assetBasePath}/${CountryCode.AW.toLowerCase()}.svg`
   },
   [CountryCode.AU]: {
      name: 'Australia',
      iso2: CountryCode.AU,
      prefix: '61',
      flag: `${assetBasePath}/${CountryCode.AU.toLowerCase()}.svg`
   },
   [CountryCode.AT]: {
      name: 'Austria',
      iso2: CountryCode.AT,
      prefix: '43',
      flag: `${assetBasePath}/${CountryCode.AT.toLowerCase()}.svg`
   },
   [CountryCode.AZ]: {
      name: 'Azerbaijan',
      iso2: CountryCode.AZ,
      prefix: '994',
      flag: `${assetBasePath}/${CountryCode.AZ.toLowerCase()}.svg`
   },
   [CountryCode.BS]: {
      name: 'Bahamas',
      iso2: CountryCode.BS,
      prefix: '1-242',
      flag: `${assetBasePath}/${CountryCode.BS.toLowerCase()}.svg`
   },
   [CountryCode.BH]: {
      name: 'Bahrain',
      iso2: CountryCode.BH,
      prefix: '973',
      flag: `${assetBasePath}/${CountryCode.BH.toLowerCase()}.svg`
   },
   [CountryCode.BD]: {
      name: 'Bangladesh',
      iso2: CountryCode.BD,
      prefix: '880',
      flag: `${assetBasePath}/${CountryCode.BD.toLowerCase()}.svg`
   },
   [CountryCode.BB]: {
      name: 'Barbados',
      iso2: CountryCode.BB,
      prefix: '1-246',
      flag: `${assetBasePath}/${CountryCode.BB.toLowerCase()}.svg`
   },
   [CountryCode.BY]: {
      name: 'Belarus',
      iso2: CountryCode.BY,
      prefix: '375',
      flag: `${assetBasePath}/${CountryCode.BY.toLowerCase()}.svg`
   },
   [CountryCode.BE]: {
      name: 'Belgium',
      iso2: CountryCode.BE,
      prefix: '32',
      flag: `${assetBasePath}/${CountryCode.BE.toLowerCase()}.svg`
   },
   [CountryCode.BZ]: {
      name: 'Belize',
      iso2: CountryCode.BZ,
      prefix: '501',
      flag: `${assetBasePath}/${CountryCode.BZ.toLowerCase()}.svg`
   },
   [CountryCode.BJ]: {
      name: 'Benin',
      iso2: CountryCode.BJ,
      prefix: '229',
      flag: `${assetBasePath}/${CountryCode.BJ.toLowerCase()}.svg`
   },
   [CountryCode.BM]: {
      name: 'Bermuda',
      iso2: CountryCode.BM,
      prefix: '1-441',
      flag: `${assetBasePath}/${CountryCode.BM.toLowerCase()}.svg`
   },
   [CountryCode.BT]: {
      name: 'Bhutan',
      iso2: CountryCode.BT,
      prefix: '975',
      flag: `${assetBasePath}/${CountryCode.BT.toLowerCase()}.svg`
   },
   [CountryCode.BO]: {
      name: 'Bolivia',
      iso2: CountryCode.BO,
      prefix: '591',
      flag: `${assetBasePath}/${CountryCode.BO.toLowerCase()}.svg`
   },
   [CountryCode.BA]: {
      name: 'Bosnia and Herzegovina',
      iso2: CountryCode.BA,
      prefix: '387',
      flag: `${assetBasePath}/${CountryCode.BA.toLowerCase()}.svg`
   },
   [CountryCode.BW]: {
      name: 'Botswana',
      iso2: CountryCode.BW,
      prefix: '267',
      flag: `${assetBasePath}/${CountryCode.BW.toLowerCase()}.svg`
   },
   [CountryCode.BR]: {
      name: 'Brazil',
      iso2: CountryCode.BR,
      prefix: '55',
      flag: `${assetBasePath}/${CountryCode.BR.toLowerCase()}.svg`
   },
   [CountryCode.IO]: {
      name: 'British Indian Ocean Territory',
      iso2: CountryCode.IO,
      prefix: '246',
      flag: `${assetBasePath}/${CountryCode.IO.toLowerCase()}.svg`
   },
   [CountryCode.BN]: {
      name: 'Brunei',
      iso2: CountryCode.BN,
      prefix: '673',
      flag: `${assetBasePath}/${CountryCode.BN.toLowerCase()}.svg`
   },
   [CountryCode.BG]: {
      name: 'Bulgaria',
      iso2: CountryCode.BG,
      prefix: '359',
      flag: `${assetBasePath}/${CountryCode.BG.toLowerCase()}.svg`
   },
   [CountryCode.BF]: {
      name: 'Burkina Faso',
      iso2: CountryCode.BF,
      prefix: '226',
      flag: `${assetBasePath}/${CountryCode.BF.toLowerCase()}.svg`
   },
   [CountryCode.BI]: {
      name: 'Burundi',
      iso2: CountryCode.BI,
      prefix: '257',
      flag: `${assetBasePath}/${CountryCode.BI.toLowerCase()}.svg`
   },
   [CountryCode.KH]: {
      name: 'Cambodia',
      iso2: CountryCode.KH,
      prefix: '855',
      flag: `${assetBasePath}/${CountryCode.KH.toLowerCase()}.svg`
   },
   [CountryCode.CM]: {
      name: 'Cameroon',
      iso2: CountryCode.CM,
      prefix: '237',
      flag: `${assetBasePath}/${CountryCode.CM.toLowerCase()}.svg`
   },
   [CountryCode.CA]: {
      name: 'Canada',
      iso2: CountryCode.CA,
      prefix: '1',
      flag: `${assetBasePath}/${CountryCode.CA.toLowerCase()}.svg`
   },
   [CountryCode.CV]: {
      name: 'Cape Verde',
      iso2: CountryCode.CV,
      prefix: '238',
      flag: `${assetBasePath}/${CountryCode.CV.toLowerCase()}.svg`
   },
   [CountryCode.KY]: {
      name: 'Cayman Islands',
      iso2: CountryCode.KY,
      prefix: '1-345',
      flag: `${assetBasePath}/${CountryCode.KY.toLowerCase()}.svg`
   },
   [CountryCode.CF]: {
      name: 'Central African Republic',
      iso2: CountryCode.CF,
      prefix: '236',
      flag: `${assetBasePath}/${CountryCode.CF.toLowerCase()}.svg`
   },
   [CountryCode.TD]: {
      name: 'Chad',
      iso2: CountryCode.TD,
      prefix: '235',
      flag: `${assetBasePath}/${CountryCode.TD.toLowerCase()}.svg`
   },
   [CountryCode.CL]: {
      name: 'Chile',
      iso2: CountryCode.CL,
      prefix: '56',
      flag: `${assetBasePath}/${CountryCode.CL.toLowerCase()}.svg`
   },
   [CountryCode.CN]: {
      name: 'China',
      iso2: CountryCode.CN,
      prefix: '86',
      flag: `${assetBasePath}/${CountryCode.CN.toLowerCase()}.svg`
   },
   [CountryCode.CX]: {
      name: 'Christmas Island',
      iso2: CountryCode.CX,
      prefix: '61',
      flag: `${assetBasePath}/${CountryCode.CX.toLowerCase()}.svg`
   },
   [CountryCode.CC]: {
      name: 'Cocos [Keeling] Islands',
      iso2: CountryCode.CC,
      prefix: '61',
      flag: `${assetBasePath}/${CountryCode.CC.toLowerCase()}.svg`
   },
   [CountryCode.CO]: {
      name: 'Colombia',
      iso2: CountryCode.CO,
      prefix: '57',
      flag: `${assetBasePath}/${CountryCode.CO.toLowerCase()}.svg`
   },
   [CountryCode.KM]: {
      name: 'Comoros',
      iso2: CountryCode.KM,
      prefix: '269',
      flag: `${assetBasePath}/${CountryCode.KM.toLowerCase()}.svg`
   },
   [CountryCode.CG]: {
      name: 'Congo - Brazzaville',
      iso2: CountryCode.CG,
      prefix: '242',
      flag: `${assetBasePath}/${CountryCode.CG.toLowerCase()}.svg`
   },
   [CountryCode.CD]: {
      name: 'Congo - Kinshasa',
      iso2: CountryCode.CD,
      prefix: '243',
      flag: `${assetBasePath}/${CountryCode.CD.toLowerCase()}.svg`
   },
   [CountryCode.CK]: {
      name: 'Cook Islands',
      iso2: CountryCode.CK,
      prefix: '682',
      flag: `${assetBasePath}/${CountryCode.CK.toLowerCase()}.svg`
   },
   [CountryCode.CR]: {
      name: 'Costa Rica',
      iso2: CountryCode.CR,
      prefix: '506',
      flag: `${assetBasePath}/${CountryCode.CR.toLowerCase()}.svg`
   },
   [CountryCode.CI]: {
      name: 'Côte d’Ivoire',
      iso2: CountryCode.CI,
      prefix: '225',
      flag: `${assetBasePath}/${CountryCode.CI.toLowerCase()}.svg`
   },
   [CountryCode.HR]: {
      name: 'Croatia',
      iso2: CountryCode.HR,
      prefix: '385',
      flag: `${assetBasePath}/${CountryCode.HR.toLowerCase()}.svg`
   },
   [CountryCode.CU]: {
      name: 'Cuba',
      iso2: CountryCode.CU,
      prefix: '53',
      flag: `${assetBasePath}/${CountryCode.CU.toLowerCase()}.svg`
   },
   [CountryCode.CY]: {
      name: 'Cyprus',
      iso2: CountryCode.CY,
      prefix: '357',
      flag: `${assetBasePath}/${CountryCode.CY.toLowerCase()}.svg`
   },
   [CountryCode.CZ]: {
      name: 'Czech Republic',
      iso2: CountryCode.CZ,
      prefix: '420',
      flag: `${assetBasePath}/${CountryCode.CZ.toLowerCase()}.svg`
   },
   [CountryCode.DK]: {
      name: 'Denmark',
      iso2: CountryCode.DK,
      prefix: '45',
      flag: `${assetBasePath}/${CountryCode.DK.toLowerCase()}.svg`
   },
   [CountryCode.DJ]: {
      name: 'Djibouti',
      iso2: CountryCode.DJ,
      prefix: '253',
      flag: `${assetBasePath}/${CountryCode.DJ.toLowerCase()}.svg`
   },
   [CountryCode.DM]: {
      name: 'Dominica',
      iso2: CountryCode.DM,
      prefix: '1-767',
      flag: `${assetBasePath}/${CountryCode.DM.toLowerCase()}.svg`
   },
   [CountryCode.DO]: {
      name: 'Dominican Republic',
      iso2: CountryCode.DO,
      prefix: '1-809, 8',
      flag: `${assetBasePath}/${CountryCode.DO.toLowerCase()}.svg`
   },
   [CountryCode.EC]: {
      name: 'Ecuador',
      iso2: CountryCode.EC,
      prefix: '593',
      flag: `${assetBasePath}/${CountryCode.EC.toLowerCase()}.svg`
   },
   [CountryCode.EG]: {
      name: 'Egypt',
      iso2: CountryCode.EG,
      prefix: '20',
      flag: `${assetBasePath}/${CountryCode.EG.toLowerCase()}.svg`
   },
   [CountryCode.SV]: {
      name: 'El Salvador',
      iso2: CountryCode.SV,
      prefix: '503',
      flag: `${assetBasePath}/${CountryCode.SV.toLowerCase()}.svg`
   },
   [CountryCode.GQ]: {
      name: 'Equatorial Guinea',
      iso2: CountryCode.GQ,
      prefix: '240',
      flag: `${assetBasePath}/${CountryCode.GQ.toLowerCase()}.svg`
   },
   [CountryCode.ER]: {
      name: 'Eritrea',
      iso2: CountryCode.ER,
      prefix: '291',
      flag: `${assetBasePath}/${CountryCode.ER.toLowerCase()}.svg`
   },
   [CountryCode.EE]: {
      name: 'Estonia',
      iso2: CountryCode.EE,
      prefix: '372',
      flag: `${assetBasePath}/${CountryCode.EE.toLowerCase()}.svg`
   },
   [CountryCode.ET]: {
      name: 'Ethiopia',
      iso2: CountryCode.ET,
      prefix: '251',
      flag: `${assetBasePath}/${CountryCode.ET.toLowerCase()}.svg`
   },
   [CountryCode.FK]: {
      name: 'Falkland Islands',
      iso2: CountryCode.FK,
      prefix: '500',
      flag: `${assetBasePath}/${CountryCode.FK.toLowerCase()}.svg`
   },
   [CountryCode.FO]: {
      name: 'Faroe Islands',
      iso2: CountryCode.FO,
      prefix: '298',
      flag: `${assetBasePath}/${CountryCode.FO.toLowerCase()}.svg`
   },
   [CountryCode.FJ]: {
      name: 'Fiji',
      iso2: CountryCode.FJ,
      prefix: '679',
      flag: `${assetBasePath}/${CountryCode.FJ.toLowerCase()}.svg`
   },
   [CountryCode.FI]: {
      name: 'Finland',
      iso2: CountryCode.FI,
      prefix: '358',
      flag: `${assetBasePath}/${CountryCode.FI.toLowerCase()}.svg`
   },
   [CountryCode.FR]: {
      name: 'France',
      iso2: CountryCode.FR,
      prefix: '33',
      flag: `${assetBasePath}/${CountryCode.FR.toLowerCase()}.svg`
   },
   [CountryCode.GF]: {
      name: 'French Guiana',
      iso2: CountryCode.GF,
      prefix: '594',
      flag: `${assetBasePath}/${CountryCode.GF.toLowerCase()}.svg`
   },
   [CountryCode.PF]: {
      name: 'French Polynesia',
      iso2: CountryCode.PF,
      prefix: '689',
      flag: `${assetBasePath}/${CountryCode.PF.toLowerCase()}.svg`
   },
   [CountryCode.GA]: {
      name: 'Gabon',
      iso2: CountryCode.GA,
      prefix: '241',
      flag: `${assetBasePath}/${CountryCode.GA.toLowerCase()}.svg`
   },
   [CountryCode.GM]: {
      name: 'Gambia',
      iso2: CountryCode.GM,
      prefix: '220',
      flag: `${assetBasePath}/${CountryCode.GM.toLowerCase()}.svg`
   },
   [CountryCode.GE]: {
      name: 'Georgia',
      iso2: CountryCode.GE,
      prefix: '995',
      flag: `${assetBasePath}/${CountryCode.GE.toLowerCase()}.svg`
   },
   [CountryCode.DE]: {
      name: 'Germany',
      iso2: CountryCode.DE,
      prefix: '49',
      flag: `${assetBasePath}/${CountryCode.DE.toLowerCase()}.svg`
   },
   [CountryCode.GH]: {
      name: 'Ghana',
      iso2: CountryCode.GH,
      prefix: '233',
      flag: `${assetBasePath}/${CountryCode.GH.toLowerCase()}.svg`
   },
   [CountryCode.GI]: {
      name: 'Gibraltar',
      iso2: CountryCode.GI,
      prefix: '350',
      flag: `${assetBasePath}/${CountryCode.GI.toLowerCase()}.svg`
   },
   [CountryCode.GR]: {
      name: 'Greece',
      iso2: CountryCode.GR,
      prefix: '30',
      flag: `${assetBasePath}/${CountryCode.GR.toLowerCase()}.svg`
   },
   [CountryCode.GL]: {
      name: 'Greenland',
      iso2: CountryCode.GL,
      prefix: '299',
      flag: `${assetBasePath}/${CountryCode.GL.toLowerCase()}.svg`
   },
   [CountryCode.GD]: {
      name: 'Grenada',
      iso2: CountryCode.GD,
      prefix: '1-473',
      flag: `${assetBasePath}/${CountryCode.GD.toLowerCase()}.svg`
   },
   [CountryCode.GP]: {
      name: 'Guadeloupe',
      iso2: CountryCode.GP,
      prefix: '590',
      flag: `${assetBasePath}/${CountryCode.GP.toLowerCase()}.svg`
   },
   [CountryCode.GU]: {
      name: 'Guam',
      iso2: CountryCode.GU,
      prefix: '1-671',
      flag: `${assetBasePath}/${CountryCode.GU.toLowerCase()}.svg`
   },
   [CountryCode.GT]: {
      name: 'Guatemala',
      iso2: CountryCode.GT,
      prefix: '502',
      flag: `${assetBasePath}/${CountryCode.GT.toLowerCase()}.svg`
   },
   [CountryCode.GN]: {
      name: 'Guinea',
      iso2: CountryCode.GN,
      prefix: '224',
      flag: `${assetBasePath}/${CountryCode.GN.toLowerCase()}.svg`
   },
   [CountryCode.GW]: {
      name: 'Guinea-Bissau',
      iso2: CountryCode.GW,
      prefix: '245',
      flag: `${assetBasePath}/${CountryCode.GW.toLowerCase()}.svg`
   },
   [CountryCode.GY]: {
      name: 'Guyana',
      iso2: CountryCode.GY,
      prefix: '592',
      flag: `${assetBasePath}/${CountryCode.GY.toLowerCase()}.svg`
   },
   [CountryCode.HT]: {
      name: 'Haiti',
      iso2: CountryCode.HT,
      prefix: '509',
      flag: `${assetBasePath}/${CountryCode.HT.toLowerCase()}.svg`
   },
   [CountryCode.VA]: {
      name: 'Vatican City',
      iso2: CountryCode.VA,
      prefix: '39',
      flag: `${assetBasePath}/${CountryCode.VA.toLowerCase()}.svg`
   },
   [CountryCode.HN]: {
      name: 'Honduras',
      iso2: CountryCode.HN,
      prefix: '504',
      flag: `${assetBasePath}/${CountryCode.HN.toLowerCase()}.svg`
   },
   [CountryCode.HK]: {
      name: 'Hong Kong SAR China',
      iso2: CountryCode.HK,
      prefix: '852',
      flag: `${assetBasePath}/${CountryCode.HK.toLowerCase()}.svg`
   },
   [CountryCode.HU]: {
      name: 'Hungary',
      iso2: CountryCode.HU,
      prefix: '36',
      flag: `${assetBasePath}/${CountryCode.HU.toLowerCase()}.svg`
   },
   [CountryCode.IS]: {
      name: 'Iceland',
      iso2: CountryCode.IS,
      prefix: '354',
      flag: `${assetBasePath}/${CountryCode.IS.toLowerCase()}.svg`
   },
   [CountryCode.IN]: {
      name: 'India',
      iso2: CountryCode.IN,
      prefix: '91',
      flag: `${assetBasePath}/${CountryCode.IN.toLowerCase()}.svg`
   },
   [CountryCode.ID]: {
      name: 'Indonesia',
      iso2: CountryCode.ID,
      prefix: '62',
      flag: `${assetBasePath}/${CountryCode.ID.toLowerCase()}.svg`
   },
   [CountryCode.IR]: {
      name: 'Iran',
      iso2: CountryCode.IR,
      prefix: '98',
      flag: `${assetBasePath}/${CountryCode.IR.toLowerCase()}.svg`
   },
   [CountryCode.IQ]: {
      name: 'Iraq',
      iso2: CountryCode.IQ,
      prefix: '964',
      flag: `${assetBasePath}/${CountryCode.IQ.toLowerCase()}.svg`
   },
   [CountryCode.IE]: {
      name: 'Ireland',
      iso2: CountryCode.IE,
      prefix: '353',
      flag: `${assetBasePath}/${CountryCode.IE.toLowerCase()}.svg`
   },
   [CountryCode.IL]: {
      name: 'Israel',
      iso2: CountryCode.IL,
      prefix: '972',
      flag: `${assetBasePath}/${CountryCode.IL.toLowerCase()}.svg`
   },
   [CountryCode.IT]: {
      name: 'Italy',
      iso2: CountryCode.IT,
      prefix: '39',
      flag: `${assetBasePath}/${CountryCode.IT.toLowerCase()}.svg`
   },
   [CountryCode.JM]: {
      name: 'Jamaica',
      iso2: CountryCode.JM,
      prefix: '1-876',
      flag: `${assetBasePath}/${CountryCode.JM.toLowerCase()}.svg`
   },
   [CountryCode.JP]: {
      name: 'Japan',
      iso2: CountryCode.JP,
      prefix: '81',
      flag: `${assetBasePath}/${CountryCode.JP.toLowerCase()}.svg`
   },
   [CountryCode.JO]: {
      name: 'Jordan',
      iso2: CountryCode.JO,
      prefix: '962',
      flag: `${assetBasePath}/${CountryCode.JO.toLowerCase()}.svg`
   },
   [CountryCode.KZ]: {
      name: 'Kazakhstan',
      iso2: CountryCode.KZ,
      prefix: '7',
      flag: `${assetBasePath}/${CountryCode.KZ.toLowerCase()}.svg`
   },
   [CountryCode.KE]: {
      name: 'Kenya',
      iso2: CountryCode.KE,
      prefix: '254',
      flag: `${assetBasePath}/${CountryCode.KE.toLowerCase()}.svg`
   },
   [CountryCode.KI]: {
      name: 'Kiribati',
      iso2: CountryCode.KI,
      prefix: '686',
      flag: `${assetBasePath}/${CountryCode.KI.toLowerCase()}.svg`
   },
   [CountryCode.KR]: {
      name: 'South Korea',
      iso2: CountryCode.KR,
      prefix: '82',
      flag: `${assetBasePath}/${CountryCode.KR.toLowerCase()}.svg`
   },
   [CountryCode.KP]: {
      name: 'North Korea',
      iso2: CountryCode.KP,
      prefix: '850',
      flag: `${assetBasePath}/${CountryCode.KP.toLowerCase()}.svg`
   },
   [CountryCode.KW]: {
      name: 'Kuwait',
      iso2: CountryCode.KW,
      prefix: '965',
      flag: `${assetBasePath}/${CountryCode.KW.toLowerCase()}.svg`
   },
   [CountryCode.KG]: {
      name: 'Kyrgyzstan',
      iso2: CountryCode.KG,
      prefix: '996',
      flag: `${assetBasePath}/${CountryCode.KG.toLowerCase()}.svg`
   },
   [CountryCode.LA]: {
      name: 'Laos',
      iso2: CountryCode.LA,
      prefix: '856',
      flag: `${assetBasePath}/${CountryCode.LA.toLowerCase()}.svg`
   },
   [CountryCode.LV]: {
      name: 'Latvia',
      iso2: CountryCode.LV,
      prefix: '371',
      flag: `${assetBasePath}/${CountryCode.LV.toLowerCase()}.svg`
   },
   [CountryCode.LB]: {
      name: 'Lebanon',
      iso2: CountryCode.LB,
      prefix: '961',
      flag: `${assetBasePath}/${CountryCode.LB.toLowerCase()}.svg`
   },
   [CountryCode.LS]: {
      name: 'Lesotho',
      iso2: CountryCode.LS,
      prefix: '266',
      flag: `${assetBasePath}/${CountryCode.LS.toLowerCase()}.svg`
   },
   [CountryCode.LR]: {
      name: 'Liberia',
      iso2: CountryCode.LR,
      prefix: '231',
      flag: `${assetBasePath}/${CountryCode.LR.toLowerCase()}.svg`
   },
   [CountryCode.LY]: {
      name: 'Libya',
      iso2: CountryCode.LY,
      prefix: '218',
      flag: `${assetBasePath}/${CountryCode.LY.toLowerCase()}.svg`
   },
   [CountryCode.LI]: {
      name: 'Liechtenstein',
      iso2: CountryCode.LI,
      prefix: '423',
      flag: `${assetBasePath}/${CountryCode.LI.toLowerCase()}.svg`
   },
   [CountryCode.LT]: {
      name: 'Lithuania',
      iso2: CountryCode.LT,
      prefix: '370',
      flag: `${assetBasePath}/${CountryCode.LT.toLowerCase()}.svg`
   },
   [CountryCode.LU]: {
      name: 'Luxembourg',
      iso2: CountryCode.LU,
      prefix: '352',
      flag: `${assetBasePath}/${CountryCode.LU.toLowerCase()}.svg`
   },
   [CountryCode.MO]: {
      name: 'Macau SAR China',
      iso2: CountryCode.MO,
      prefix: '853',
      flag: `${assetBasePath}/${CountryCode.MO.toLowerCase()}.svg`
   },
   [CountryCode.MK]: {
      name: 'Macedonia',
      iso2: CountryCode.MK,
      prefix: '389',
      flag: `${assetBasePath}/${CountryCode.MK.toLowerCase()}.svg`
   },
   [CountryCode.MG]: {
      name: 'Madagascar',
      iso2: CountryCode.MG,
      prefix: '261',
      flag: `${assetBasePath}/${CountryCode.MG.toLowerCase()}.svg`
   },
   [CountryCode.MW]: {
      name: 'Malawi',
      iso2: CountryCode.MW,
      prefix: '265',
      flag: `${assetBasePath}/${CountryCode.MW.toLowerCase()}.svg`
   },
   [CountryCode.MY]: {
      name: 'Malaysia',
      iso2: CountryCode.MY,
      prefix: '60',
      flag: `${assetBasePath}/${CountryCode.MY.toLowerCase()}.svg`
   },
   [CountryCode.MV]: {
      name: 'Maldives',
      iso2: CountryCode.MV,
      prefix: '960',
      flag: `${assetBasePath}/${CountryCode.MV.toLowerCase()}.svg`
   },
   [CountryCode.ML]: {
      name: 'Mali',
      iso2: CountryCode.ML,
      prefix: '223',
      flag: `${assetBasePath}/${CountryCode.ML.toLowerCase()}.svg`
   },
   [CountryCode.MT]: {
      name: 'Malta',
      iso2: CountryCode.MT,
      prefix: '356',
      flag: `${assetBasePath}/${CountryCode.MT.toLowerCase()}.svg`
   },
   [CountryCode.MH]: {
      name: 'Marshall Islands',
      iso2: CountryCode.MH,
      prefix: '692',
      flag: `${assetBasePath}/${CountryCode.MH.toLowerCase()}.svg`
   },
   [CountryCode.MQ]: {
      name: 'Martinique',
      iso2: CountryCode.MQ,
      prefix: '596',
      flag: `${assetBasePath}/${CountryCode.MQ.toLowerCase()}.svg`
   },
   [CountryCode.MR]: {
      name: 'Mauritania',
      iso2: CountryCode.MR,
      prefix: '222',
      flag: `${assetBasePath}/${CountryCode.MR.toLowerCase()}.svg`
   },
   [CountryCode.MU]: {
      name: 'Mauritius',
      iso2: CountryCode.MU,
      prefix: '230',
      flag: `${assetBasePath}/${CountryCode.MU.toLowerCase()}.svg`
   },
   [CountryCode.YT]: {
      name: 'Mayotte',
      iso2: CountryCode.YT,
      prefix: '262',
      flag: `${assetBasePath}/${CountryCode.YT.toLowerCase()}.svg`
   },
   [CountryCode.MX]: {
      name: 'Mexico',
      iso2: CountryCode.MX,
      prefix: '52',
      flag: `${assetBasePath}/${CountryCode.MX.toLowerCase()}.svg`
   },
   [CountryCode.FM]: {
      name: 'Micronesia',
      iso2: CountryCode.FM,
      prefix: '691',
      flag: `${assetBasePath}/${CountryCode.FM.toLowerCase()}.svg`
   },
   [CountryCode.MD]: {
      name: 'Moldova',
      iso2: CountryCode.MD,
      prefix: '373',
      flag: `${assetBasePath}/${CountryCode.MD.toLowerCase()}.svg`
   },
   [CountryCode.MC]: {
      name: 'Monaco',
      iso2: CountryCode.MC,
      prefix: '377',
      flag: `${assetBasePath}/${CountryCode.MC.toLowerCase()}.svg`
   },
   [CountryCode.MN]: {
      name: 'Mongolia',
      iso2: CountryCode.MN,
      prefix: '976',
      flag: `${assetBasePath}/${CountryCode.MN.toLowerCase()}.svg`
   },
   [CountryCode.ME]: {
      name: 'Montenegro',
      iso2: CountryCode.ME,
      prefix: '382',
      flag: `${assetBasePath}/${CountryCode.ME.toLowerCase()}.svg`
   },
   [CountryCode.MS]: {
      name: 'Montserrat',
      iso2: CountryCode.MS,
      prefix: '1-664',
      flag: `${assetBasePath}/${CountryCode.MS.toLowerCase()}.svg`
   },
   [CountryCode.MA]: {
      name: 'Morocco',
      iso2: CountryCode.MA,
      prefix: '212',
      flag: `${assetBasePath}/${CountryCode.MA.toLowerCase()}.svg`
   },
   [CountryCode.MZ]: {
      name: 'Mozambique',
      iso2: CountryCode.MZ,
      prefix: '258',
      flag: `${assetBasePath}/${CountryCode.MZ.toLowerCase()}.svg`
   },
   [CountryCode.MM]: {
      name: 'Myanmar [Burma]',
      iso2: CountryCode.MM,
      prefix: '95',
      flag: `${assetBasePath}/${CountryCode.MM.toLowerCase()}.svg`
   },
   [CountryCode.NA]: {
      name: 'Namibia',
      iso2: CountryCode.NA,
      prefix: '264',
      flag: `${assetBasePath}/${CountryCode.NA.toLowerCase()}.svg`
   },
   [CountryCode.NR]: {
      name: 'Nauru',
      iso2: CountryCode.NR,
      prefix: '674',
      flag: `${assetBasePath}/${CountryCode.NR.toLowerCase()}.svg`
   },
   [CountryCode.NP]: {
      name: 'Nepal',
      iso2: CountryCode.NP,
      prefix: '977',
      flag: `${assetBasePath}/${CountryCode.NP.toLowerCase()}.svg`
   },
   [CountryCode.NL]: {
      name: 'Netherlands',
      iso2: CountryCode.NL,
      prefix: '31',
      flag: `${assetBasePath}/${CountryCode.NL.toLowerCase()}.svg`
   },
   [CountryCode.NC]: {
      name: 'New Caledonia',
      iso2: CountryCode.NC,
      prefix: '687',
      flag: `${assetBasePath}/${CountryCode.NC.toLowerCase()}.svg`
   },
   [CountryCode.NZ]: {
      name: 'New Zealand',
      iso2: CountryCode.NZ,
      prefix: '64',
      flag: `${assetBasePath}/${CountryCode.NZ.toLowerCase()}.svg`
   },
   [CountryCode.NI]: {
      name: 'Nicaragua',
      iso2: CountryCode.NI,
      prefix: '505',
      flag: `${assetBasePath}/${CountryCode.NI.toLowerCase()}.svg`
   },
   [CountryCode.NE]: {
      name: 'Niger',
      iso2: CountryCode.NE,
      prefix: '227',
      flag: `${assetBasePath}/${CountryCode.NE.toLowerCase()}.svg`
   },
   [CountryCode.NG]: {
      name: 'Nigeria',
      iso2: CountryCode.NG,
      prefix: '234',
      flag: `${assetBasePath}/${CountryCode.NG.toLowerCase()}.svg`
   },
   [CountryCode.NU]: {
      name: 'Niue',
      iso2: CountryCode.NU,
      prefix: '683',
      flag: `${assetBasePath}/${CountryCode.NU.toLowerCase()}.svg`
   },
   [CountryCode.NF]: {
      name: 'Norfolk Island',
      iso2: CountryCode.NF,
      prefix: '672',
      flag: `${assetBasePath}/${CountryCode.NF.toLowerCase()}.svg`
   },
   [CountryCode.MP]: {
      name: 'Northern Mariana Islands',
      iso2: CountryCode.MP,
      prefix: '1-670',
      flag: `${assetBasePath}/${CountryCode.MP.toLowerCase()}.svg`
   },
   [CountryCode.NO]: {
      name: 'Norway',
      iso2: CountryCode.NO,
      prefix: '47',
      flag: `${assetBasePath}/${CountryCode.NO.toLowerCase()}.svg`
   },
   [CountryCode.OM]: {
      name: 'Oman',
      iso2: CountryCode.OM,
      prefix: '968',
      flag: `${assetBasePath}/${CountryCode.OM.toLowerCase()}.svg`
   },
   [CountryCode.PK]: {
      name: 'Pakistan',
      iso2: CountryCode.PK,
      prefix: '92',
      flag: `${assetBasePath}/${CountryCode.PK.toLowerCase()}.svg`
   },
   [CountryCode.PW]: {
      name: 'Palau',
      iso2: CountryCode.PW,
      prefix: '680',
      flag: `${assetBasePath}/${CountryCode.PW.toLowerCase()}.svg`
   },
   [CountryCode.PS]: {
      name: 'Palestinian Territories',
      iso2: CountryCode.PS,
      prefix: '970',
      flag: `${assetBasePath}/${CountryCode.PS.toLowerCase()}.svg`
   },
   [CountryCode.PA]: {
      name: 'Panama',
      iso2: CountryCode.PA,
      prefix: '507',
      flag: `${assetBasePath}/${CountryCode.PA.toLowerCase()}.svg`
   },
   [CountryCode.PG]: {
      name: 'Papua New Guinea',
      iso2: CountryCode.PG,
      prefix: '675',
      flag: `${assetBasePath}/${CountryCode.PG.toLowerCase()}.svg`
   },
   [CountryCode.PY]: {
      name: 'Paraguay',
      iso2: CountryCode.PY,
      prefix: '595',
      flag: `${assetBasePath}/${CountryCode.PY.toLowerCase()}.svg`
   },
   [CountryCode.PE]: {
      name: 'Peru',
      iso2: CountryCode.PE,
      prefix: '51',
      flag: `${assetBasePath}/${CountryCode.PE.toLowerCase()}.svg`
   },
   [CountryCode.PH]: {
      name: 'Philippines',
      iso2: CountryCode.PH,
      prefix: '63',
      flag: `${assetBasePath}/${CountryCode.PH.toLowerCase()}.svg`
   },
   [CountryCode.PL]: {
      name: 'Poland',
      iso2: CountryCode.PL,
      prefix: '48',
      flag: `${assetBasePath}/${CountryCode.PL.toLowerCase()}.svg`
   },
   [CountryCode.PT]: {
      name: 'Portugal',
      iso2: CountryCode.PT,
      prefix: '351',
      flag: `${assetBasePath}/${CountryCode.PT.toLowerCase()}.svg`
   },
   [CountryCode.PR]: {
      name: 'Puerto Rico',
      iso2: CountryCode.PR,
      prefix: '1-939',
      flag: `${assetBasePath}/${CountryCode.PR.toLowerCase()}.svg`
   },
   [CountryCode.QA]: {
      name: 'Qatar',
      iso2: CountryCode.QA,
      prefix: '974',
      flag: `${assetBasePath}/${CountryCode.QA.toLowerCase()}.svg`
   },
   [CountryCode.RE]: {
      name: 'Réunion',
      iso2: CountryCode.RE,
      prefix: '262',
      flag: `${assetBasePath}/${CountryCode.RE.toLowerCase()}.svg`
   },
   [CountryCode.RO]: {
      name: 'Romania',
      iso2: CountryCode.RO,
      prefix: '40',
      flag: `${assetBasePath}/${CountryCode.RO.toLowerCase()}.svg`
   },
   [CountryCode.RU]: {
      name: 'Russia',
      iso2: CountryCode.RU,
      prefix: '7',
      flag: `${assetBasePath}/${CountryCode.RU.toLowerCase()}.svg`
   },
   [CountryCode.RW]: {
      name: 'Rwanda',
      iso2: CountryCode.RW,
      prefix: '250',
      flag: `${assetBasePath}/${CountryCode.RW.toLowerCase()}.svg`
   },
   [CountryCode.SH]: {
      name: 'Saint Helena',
      iso2: CountryCode.SH,
      prefix: '290',
      flag: `${assetBasePath}/${CountryCode.SH.toLowerCase()}.svg`
   },
   [CountryCode.KN]: {
      name: 'Saint Kitts and Nevis',
      iso2: CountryCode.KN,
      prefix: '1-869',
      flag: `${assetBasePath}/${CountryCode.KN.toLowerCase()}.svg`
   },
   [CountryCode.LC]: {
      name: 'Saint Lucia',
      iso2: CountryCode.LC,
      prefix: '1-758',
      flag: `${assetBasePath}/${CountryCode.LC.toLowerCase()}.svg`
   },
   [CountryCode.PM]: {
      name: 'Saint Pierre and Miquelon',
      iso2: CountryCode.PM,
      prefix: '508',
      flag: `${assetBasePath}/${CountryCode.PM.toLowerCase()}.svg`
   },
   [CountryCode.VC]: {
      name: 'Saint Vincent and the Grenadines',
      iso2: CountryCode.VC,
      prefix: '1-784',
      flag: `${assetBasePath}/${CountryCode.VC.toLowerCase()}.svg`
   },
   [CountryCode.WS]: {
      name: 'Samoa',
      iso2: CountryCode.WS,
      prefix: '685',
      flag: `${assetBasePath}/${CountryCode.WS.toLowerCase()}.svg`
   },
   [CountryCode.SM]: {
      name: 'San Marino',
      iso2: CountryCode.SM,
      prefix: '378',
      flag: `${assetBasePath}/${CountryCode.SM.toLowerCase()}.svg`
   },
   [CountryCode.ST]: {
      name: 'São Tomé and Príncipe',
      iso2: CountryCode.ST,
      prefix: '239',
      flag: `${assetBasePath}/${CountryCode.ST.toLowerCase()}.svg`
   },
   [CountryCode.SA]: {
      name: 'Saudi Arabia',
      iso2: CountryCode.SA,
      prefix: '966',
      flag: `${assetBasePath}/${CountryCode.SA.toLowerCase()}.svg`
   },
   [CountryCode.SN]: {
      name: 'Senegal',
      iso2: CountryCode.SN,
      prefix: '221',
      flag: `${assetBasePath}/${CountryCode.SN.toLowerCase()}.svg`
   },
   [CountryCode.RS]: {
      name: 'Serbia',
      iso2: CountryCode.RS,
      prefix: '381',
      flag: `${assetBasePath}/${CountryCode.RS.toLowerCase()}.svg`
   },
   [CountryCode.SC]: {
      name: 'Seychelles',
      iso2: CountryCode.SC,
      prefix: '248',
      flag: `${assetBasePath}/${CountryCode.SC.toLowerCase()}.svg`
   },
   [CountryCode.SL]: {
      name: 'Sierra Leone',
      iso2: CountryCode.SL,
      prefix: '232',
      flag: `${assetBasePath}/${CountryCode.SL.toLowerCase()}.svg`
   },
   [CountryCode.SG]: {
      name: 'Singapore',
      iso2: CountryCode.SG,
      prefix: '65',
      flag: `${assetBasePath}/${CountryCode.SG.toLowerCase()}.svg`
   },
   [CountryCode.SK]: {
      name: 'Slovakia',
      iso2: CountryCode.SK,
      prefix: '421',
      flag: `${assetBasePath}/${CountryCode.SK.toLowerCase()}.svg`
   },
   [CountryCode.SI]: {
      name: 'Slovenia',
      iso2: CountryCode.SI,
      prefix: '386',
      flag: `${assetBasePath}/${CountryCode.SI.toLowerCase()}.svg`
   },
   [CountryCode.SB]: {
      name: 'Solomon Islands',
      iso2: CountryCode.SB,
      prefix: '677',
      flag: `${assetBasePath}/${CountryCode.SB.toLowerCase()}.svg`
   },
   [CountryCode.SO]: {
      name: 'Somalia',
      iso2: CountryCode.SO,
      prefix: '252',
      flag: `${assetBasePath}/${CountryCode.SO.toLowerCase()}.svg`
   },
   [CountryCode.ZA]: {
      name: 'South Africa',
      iso2: CountryCode.ZA,
      prefix: '27',
      flag: `${assetBasePath}/${CountryCode.ZA.toLowerCase()}.svg`
   },
   [CountryCode.GS]: {
      name: 'South Georgia and the South Sandwich Islands',
      iso2: CountryCode.GS,
      prefix: '500',
      flag: `${assetBasePath}/${CountryCode.GS.toLowerCase()}.svg`
   },
   [CountryCode.ES]: {
      name: 'Spain',
      iso2: CountryCode.ES,
      prefix: '34',
      flag: `${assetBasePath}/${CountryCode.ES.toLowerCase()}.svg`
   },
   [CountryCode.LK]: {
      name: 'Sri Lanka',
      iso2: CountryCode.LK,
      prefix: '94',
      flag: `${assetBasePath}/${CountryCode.LK.toLowerCase()}.svg`
   },
   [CountryCode.SD]: {
      name: 'Sudan',
      iso2: CountryCode.SD,
      prefix: '249',
      flag: `${assetBasePath}/${CountryCode.SD.toLowerCase()}.svg`
   },
   [CountryCode.SR]: {
      name: 'Suriname',
      iso2: CountryCode.SR,
      prefix: '597',
      flag: `${assetBasePath}/${CountryCode.SR.toLowerCase()}.svg`
   },
   [CountryCode.SJ]: {
      name: 'Svalbard and Jan Mayen',
      iso2: CountryCode.SJ,
      prefix: '47',
      flag: `${assetBasePath}/${CountryCode.SJ.toLowerCase()}.svg`
   },
   [CountryCode.SZ]: {
      name: 'Swaziland',
      iso2: CountryCode.SZ,
      prefix: '268',
      flag: `${assetBasePath}/${CountryCode.SZ.toLowerCase()}.svg`
   },
   [CountryCode.SE]: {
      name: 'Sweden',
      iso2: CountryCode.SE,
      prefix: '46',
      flag: `${assetBasePath}/${CountryCode.SE.toLowerCase()}.svg`
   },
   [CountryCode.CH]: {
      name: 'Switzerland',
      iso2: CountryCode.CH,
      prefix: '41',
      flag: `${assetBasePath}/${CountryCode.CH.toLowerCase()}.svg`
   },
   [CountryCode.SY]: {
      name: 'Syria',
      iso2: CountryCode.SY,
      prefix: '963',
      flag: `${assetBasePath}/${CountryCode.SY.toLowerCase()}.svg`
   },
   [CountryCode.TW]: {
      name: 'Taiwan',
      iso2: CountryCode.TW,
      prefix: '886',
      flag: `${assetBasePath}/${CountryCode.TW.toLowerCase()}.svg`
   },
   [CountryCode.TJ]: {
      name: 'Tajikistan',
      iso2: CountryCode.TJ,
      prefix: '992',
      flag: `${assetBasePath}/${CountryCode.TJ.toLowerCase()}.svg`
   },
   [CountryCode.TZ]: {
      name: 'Tanzania',
      iso2: CountryCode.TZ,
      prefix: '255',
      flag: `${assetBasePath}/${CountryCode.TZ.toLowerCase()}.svg`
   },
   [CountryCode.TH]: {
      name: 'Thailand',
      iso2: CountryCode.TH,
      prefix: '66',
      flag: `${assetBasePath}/${CountryCode.TH.toLowerCase()}.svg`
   },
   [CountryCode.TL]: {
      name: 'Timor-Leste',
      iso2: CountryCode.TL,
      prefix: '670',
      flag: `${assetBasePath}/${CountryCode.TL.toLowerCase()}.svg`
   },
   [CountryCode.TG]: {
      name: 'Togo',
      iso2: CountryCode.TG,
      prefix: '228',
      flag: `${assetBasePath}/${CountryCode.TG.toLowerCase()}.svg`
   },
   [CountryCode.TK]: {
      name: 'Tokelau',
      iso2: CountryCode.TK,
      prefix: '690',
      flag: `${assetBasePath}/${CountryCode.TK.toLowerCase()}.svg`
   },
   [CountryCode.TO]: {
      name: 'Tonga',
      iso2: CountryCode.TO,
      prefix: '676',
      flag: `${assetBasePath}/${CountryCode.TO.toLowerCase()}.svg`
   },
   [CountryCode.TT]: {
      name: 'Trinidad and Tobago',
      iso2: CountryCode.TT,
      prefix: '1-868',
      flag: `${assetBasePath}/${CountryCode.TT.toLowerCase()}.svg`
   },
   [CountryCode.TN]: {
      name: 'Tunisia',
      iso2: CountryCode.TN,
      prefix: '216',
      flag: `${assetBasePath}/${CountryCode.TN.toLowerCase()}.svg`
   },
   [CountryCode.TR]: {
      name: 'Turkey',
      iso2: CountryCode.TR,
      prefix: '90',
      flag: `${assetBasePath}/${CountryCode.TR.toLowerCase()}.svg`
   },
   [CountryCode.TM]: {
      name: 'Turkmenistan',
      iso2: CountryCode.TM,
      prefix: '993',
      flag: `${assetBasePath}/${CountryCode.TM.toLowerCase()}.svg`
   },
   [CountryCode.TC]: {
      name: 'Turks and Caicos Islands',
      iso2: CountryCode.TC,
      prefix: '1-649',
      flag: `${assetBasePath}/${CountryCode.TC.toLowerCase()}.svg`
   },
   [CountryCode.TV]: {
      name: 'Tuvalu',
      iso2: CountryCode.TV,
      prefix: '688',
      flag: `${assetBasePath}/${CountryCode.TV.toLowerCase()}.svg`
   },
   [CountryCode.UG]: {
      name: 'Uganda',
      iso2: CountryCode.UG,
      prefix: '256',
      flag: `${assetBasePath}/${CountryCode.UG.toLowerCase()}.svg`
   },
   [CountryCode.UA]: {
      name: 'Ukraine',
      iso2: CountryCode.UA,
      prefix: '380',
      flag: `${assetBasePath}/${CountryCode.UA.toLowerCase()}.svg`
   },
   [CountryCode.AE]: {
      name: 'United Arab Emirates',
      iso2: CountryCode.AE,
      prefix: '971',
      flag: `${assetBasePath}/${CountryCode.AE.toLowerCase()}.svg`
   },
   [CountryCode.GB]: {
      name: 'United Kingdom',
      iso2: CountryCode.GB,
      prefix: '44',
      flag: `${assetBasePath}/${CountryCode.GB.toLowerCase()}.svg`
   },
   [CountryCode.US]: {
      name: 'United States',
      iso2: CountryCode.US,
      prefix: '1',
      flag: `${assetBasePath}/${CountryCode.US.toLowerCase()}.svg`
   },
   [CountryCode.UY]: {
      name: 'Uruguay',
      iso2: CountryCode.UY,
      prefix: '598',
      flag: `${assetBasePath}/${CountryCode.UY.toLowerCase()}.svg`
   },
   [CountryCode.UZ]: {
      name: 'Uzbekistan',
      iso2: CountryCode.UZ,
      prefix: '998',
      flag: `${assetBasePath}/${CountryCode.UZ.toLowerCase()}.svg`
   },
   [CountryCode.VU]: {
      name: 'Vanuatu',
      iso2: CountryCode.VU,
      prefix: '678',
      flag: `${assetBasePath}/${CountryCode.VU.toLowerCase()}.svg`
   },
   [CountryCode.VE]: {
      name: 'Venezuela',
      iso2: CountryCode.VE,
      prefix: '58',
      flag: `${assetBasePath}/${CountryCode.VE.toLowerCase()}.svg`
   },
   [CountryCode.VN]: {
      name: 'Vietnam',
      iso2: CountryCode.VN,
      prefix: '84',
      flag: `${assetBasePath}/${CountryCode.VN.toLowerCase()}.svg`
   },
   [CountryCode.VG]: {
      name: 'British Virgin Islands',
      iso2: CountryCode.VG,
      prefix: '1-284',
      flag: `${assetBasePath}/${CountryCode.VG.toLowerCase()}.svg`
   },
   [CountryCode.VI]: {
      name: 'U.S. Virgin Islands',
      iso2: CountryCode.VI,
      prefix: '1-340',
      flag: `${assetBasePath}/${CountryCode.VI.toLowerCase()}.svg`
   },
   [CountryCode.WF]: {
      name: 'Wallis and Futuna',
      iso2: CountryCode.WF,
      prefix: '681',
      flag: `${assetBasePath}/${CountryCode.WF.toLowerCase()}.svg`
   },
   [CountryCode.EH]: {
      name: 'Western Sahara',
      iso2: CountryCode.EH,
      prefix: '212',
      flag: `${assetBasePath}/${CountryCode.EH.toLowerCase()}.svg`
   },
   [CountryCode.YE]: {
      name: 'Yemen',
      iso2: CountryCode.YE,
      prefix: '967',
      flag: `${assetBasePath}/${CountryCode.YE.toLowerCase()}.svg`
   },
   [CountryCode.ZM]: {
      name: 'Zambia',
      iso2: CountryCode.ZM,
      prefix: '260',
      flag: `${assetBasePath}/${CountryCode.ZM.toLowerCase()}.svg`
   },
   [CountryCode.ZW]: {
      name: 'Zimbabwe',
      iso2: CountryCode.ZW,
      prefix: '263',
      flag: `${assetBasePath}/${CountryCode.ZW.toLowerCase()}.svg`
   }
};
