import { FC, useState } from 'react';

import { User } from '@ravago/shared/authentication';
import { ContactDetails, FlyoutProps } from '@ravago/shared/page-data/models/features';
import { getLanguageCode, TrackingUtils, useConfigContext, useRequestForQuotationContext, UserUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Checkbox } from '@ravago/shared/radiance/components/checkbox/components/Checkbox/Checkbox';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { PhoneInputField } from '@ravago/shared/radiance/components/input-field/components/PhoneInputField/PhoneInputField';
import { PhoneDetails, PhoneInputErrorMessages } from '@ravago/shared/radiance/components/input-field/components/PhoneInputField/PhoneInputField.types';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';

interface ContactDetailsProps extends FlyoutProps {
  locale: string;
  contactDetails: ContactDetails;
  onContactDetailsChange: (field: string, value: string | PhoneDetails | DropdownOption | boolean) => void;
  countries: DropdownOption[];
  user?: User;
}

const RfqFlyoutStepContactDetails: FC<ContactDetailsProps> = ({ locale, phone, onNextStep, onPreviousStep, onContactDetailsChange, contactDetails, countries, user }) => {
  const languageCode = getLanguageCode(locale);
  const translate = useTranslations();
  const { hostName } = useConfigContext();
  const [helperText, setHelperText] = useState<string>('');
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleContactDetailsChange = (field: string, value: string | PhoneDetails | DropdownOption | boolean | undefined) => {
    if (value !== undefined) {
      onContactDetailsChange(field, value);
    }
  };

  const handlePhoneChange = (value: PhoneDetails) => {
    handleContactDetailsChange('phone', value);
  };

  const handlePhoneInputError = (errorMessage: PhoneInputErrorMessages) => {
    switch (errorMessage) {
      case 'INVALID_PHONE_NUMBER':
        setHelperText('request-for-quotation.invalid-phone-number');
        break;
      case 'INVALID_PHONE_NUMBER_LENGTH':
        setHelperText('request-for-quotation.invalid-phone-number-length');
        break;
      case 'INVALID_PHONE_NUMBER_CHAR':
        setHelperText('request-for-quotation.invalid-phone-number-characters');
        break;
      default:
        setHelperText('');
        break;
    }
  };

  const handleNextStep = () => {
    TrackingUtils.track('rfq_click_delivery_flyout', {
      productName: requestForQuotationState.product?.description,
      productId: requestForQuotationState.product?.id,
      userLoggedIn: JSON.stringify(!!user),
      userType: UserUtils.getUserType(user)
    });
    onNextStep();
  };

  return (
    <>
      <Box direction="column">
        <Box direction="column" justify="center" items="center">
          <Image width="180px" src="/images/rfq-contact-details.png" alt={translate('page-consumer.request-for-quotation.contact-details')} title={translate('page-consumer.request-for-quotation.contact-details')} objectFit="contain" />
        </Box>
        <Typography component="span" variant="body" color="strong">
          {translate('page-consumer.request-for-quotation.contact-info.instructions')}
        </Typography>
        <Box direction="column">
          <InputField disabled type="email" label={translate('page-consumer.request-for-quotation.contact-info.email-address')} id="rfq-contact-details-email-address" value={contactDetails.email} onChange={(value) => handleContactDetailsChange('email', value)} />
          <Box gap="xs">
            <InputField disabled={user !== undefined} maxLength={40} label={translate('page-consumer.request-for-quotation.contact-info.first-name')} id="rfq-contact-details-first-name" value={contactDetails.firstName} onChange={(value) => handleContactDetailsChange('firstName', value)} />
            <InputField disabled={user !== undefined} maxLength={80} label={translate('page-consumer.request-for-quotation.contact-info.last-name')} id="rfq-contact-details-last-name" value={contactDetails.lastName} onChange={(value) => handleContactDetailsChange('lastName', value)} />
          </Box>
          <InputField required maxLength={40} label={translate('page-consumer.request-for-quotation.contact-info.company-name')} id="rfq-contact-details-company-name" value={contactDetails.companyName} onChange={(value) => handleContactDetailsChange('companyName', value)} />
          <Dropdown id="rfq-contact-dropdown-country" label={translate('page-consumer.request-for-quotation.contact-info.country')} leadingIcon="" emptyOption={false} value={contactDetails.country} onChange={(value) => handleContactDetailsChange('country', value)} options={countries} />
          <PhoneInputField
            value={contactDetails.phone}
            autocomplete="on"
            countryFilter={[]}
            filterType="include"
            id="rfq-contact-details-phone"
            label={translate('page-consumer.request-for-quotation.contact-info.phone')}
            onChange={handlePhoneChange}
            onError={handlePhoneInputError}
            errorMessage={translate(helperText)}
            error={helperText !== ''}
          />
        </Box>
        <Box items="center" gap="2xs" top="-15px" left="-5px" position="relative">
          <Checkbox id="checkbox-flyout-terms" checked={contactDetails.termsAndConditions} onChange={(state) => handleContactDetailsChange('termsAndConditions', state.checked)} required>
            <Typography variant="body" component="span">
              {translate('page-consumer.request-for-quotation.contact-info.accept-terms')}
            </Typography>
          </Checkbox>
          <Link underline="always" color="primary" href={`${hostName}/my/${String(languageCode)}/legal/terms-of-use`} target="_blank">
            <Typography variant="body" component="span" color="primary">
              {translate('page-consumer.request-for-quotation.contact-info.terms-conditions')}
            </Typography>
          </Link>
        </Box>
      </Box>

      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" items="center" width="100%">
                <Button variant="secondary" onClick={onPreviousStep} leadingIcon={!phone ? 'long-arrow-left' : ''} fullWidth>
                  {translate('page-consumer.request-for-quotation.back')}
                </Button>
                <Button
                  id="rfq-contact-details-delivery-btn"
                  variant="primary"
                  disabled={!contactDetails.termsAndConditions || !contactDetails.companyName || helperText !== '' || !contactDetails.firstName || !contactDetails.lastName}
                  onClick={handleNextStep}
                  trailingIcon={!phone ? 'long-arrow-right' : ''}
                  fullWidth
                >
                  {translate('page-consumer.request-for-quotation.delivery')}
                </Button>
              </Box>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default RfqFlyoutStepContactDetails;
