import { useShoppingCartContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from 'libs/shared/page-elements-radiance/src/lib/providers/translation-client-context-provider';

interface Props {
  token: string;
}

const AddToShoppingCartDuplicateInfo = ({ token }: Props) => {
  const translate = useTranslations();
  const { state, addToCart, resetCartValidation } = useShoppingCartContext();
  const { existingItem, duplicateCartItem } = state;

  const addToShoppingCart = () => {
    if (!duplicateCartItem) return;
    addToCart(duplicateCartItem.lineId, duplicateCartItem.orderableProduct, duplicateCartItem.quantity, token, true);
  };

  return (
    <Box direction="column" gap="lg">
      <Box gap="md">
        <Icon icon="info" />
        <Typography variant="heading-4">{translate('shopping-cart.validation.quantity-warning')}</Typography>
      </Box>
      {duplicateCartItem && existingItem && (
        <Typography>
          {translate('shopping-cart.validation.quantity-override-warning', {
            productName: duplicateCartItem.orderableProduct.description,
            existingQuantity: `${existingItem.quantity.amount} ${existingItem.quantity.unitOfMeasure}`,
            nextQuantity: `${duplicateCartItem.quantity.amount} ${duplicateCartItem.quantity.unitOfMeasure}`
          })}
        </Typography>
      )}
      <Box direction="column" gap="md">
        <Button onClick={addToShoppingCart}>{translate('shopping-cart.validation.continue')}</Button>
        <Button variant="secondary" onClick={resetCartValidation}>
          {translate('shopping-cart.validation.modal-close')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddToShoppingCartDuplicateInfo;
