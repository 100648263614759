import { FC } from 'react';

import { BusinessContext } from '@ravago/shared/page-data/models/config';
import { OrderDocumentType, OrderLineBOL, OrderLineCOA, OrderLineDeliveryNote, OrderLineDetail, OrderLineDocumentResponse, OrderLineInvoice } from '@ravago/shared/page-data/models/elements';
import { CorrelationService, useTranslations, BusinessContextUtils, TrackingUtils } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import notification from '@ravago/shared/radiance/utils/notification/notification';
import { useAuthentication } from '@ravago/shared/authentication';

import { saveAs } from 'file-saver';

import { downloadDocument, requestBol, requestCoa, requestDeliveryNote } from '../../../../../actions';

import CoaDocuments from './coa-documents';
import DeliveryNoteDocuments from './delivery-note-documents';
import InvoiceDocuments from './invoice-documents';

interface Props {
  orderLine: OrderLineDetail;
  documents: OrderLineDocumentResponse | undefined;
  businessContext: BusinessContext;
}

const OrderLineDocuments: FC<Props> = ({ orderLine, documents, businessContext }) => {
  const translate = useTranslations();
  const { accessToken } = useAuthentication();

  const handleDownload = async (document: OrderLineInvoice | OrderLineCOA | OrderLineDeliveryNote, type: OrderDocumentType) => {
    if (!accessToken) return;
    const blob = await downloadDocument(`customer/orders/${orderLine.orderNumber}/documents/${document.id}`, accessToken, CorrelationService.generateCorrelationId('dpcid'));
    if (blob) {
      saveAs(blob, document.extension ? `${document.name}.${document.extension}` : document.name);
      TrackingUtils.track('order_document_download', {
        documentType: type,
        orderNumber: orderLine.orderNumber,
        productId: orderLine.product.pkbId?.toString() || orderLine.product.id.toString(),
        productName: orderLine.product.description,
        orderReference: orderLine.customerOrderReference,
        productReference: orderLine.customerProductReference
      });
    } else {
      notification(translate('page-consumer.order-line-flyout.download-error'));
    }
  };

  const handleRequestChange = async <T extends OrderLineCOA | OrderLineDeliveryNote | OrderLineBOL>(type: OrderDocumentType, document: T) => {
    if (!accessToken) {
      notification(translate('page-consumer.order-line-flyout.request-error'));
      return;
    }
    let result;
    // eslint-disable-next-line default-case
    switch (type) {
      case 'COA':
        result = await requestCoa(accessToken, orderLine.orderNumber, (document as OrderLineCOA).producerLotNr);
        break;
      case 'Delivery Note':
        result = await requestDeliveryNote(accessToken, orderLine.orderNumber, (document as OrderLineDeliveryNote).transportOrderLineNr, (document as OrderLineDeliveryNote).bundledTransportOrderId);
        break;
      case 'Bill of Lading':
        result = await requestBol(accessToken, orderLine.orderNumber, (document as OrderLineBOL).transportOrderLineNr);
        break;
    }
    if (result) {
      notification(translate('page-consumer.order-line-flyout.request-success'));
    } else {
      notification(translate('page-consumer.order-line-flyout.request-error'));
    }
  };

  return (
    <Box direction="column" gap="lg">
      {documents && (
        <>
          <Box justify="between">
            <Typography variant="heading-4" component="h4" color="strong">
              {translate('page-consumer.order-line-flyout.documents')}
            </Typography>
          </Box>
          <InvoiceDocuments documents={documents.invoices} onDownload={handleDownload} />
          <CoaDocuments documents={documents.coa} onDownload={handleDownload} onRequestCoa={(coa) => handleRequestChange('COA', coa)} />
          <DeliveryNoteDocuments
            deliveryNotes={documents.deliveryNotes}
            isUSBusinessContext={BusinessContextUtils.isUSBusinessContext(businessContext)}
            billsOfLading={documents.billsOfLading}
            onDownload={handleDownload}
            onRequestDeliveryNote={(deliveryNote) => handleRequestChange('Delivery Note', deliveryNote)}
            onRequestBol={(bol) => handleRequestChange('Bill of Lading', bol)}
          />
        </>
      )}
    </Box>
  );
};

export default OrderLineDocuments;
