'use client';

import { useContext } from 'react';

import { RibbonContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Ribbon as RadianceRibbon } from '@ravago/shared/radiance/components/ribbon/components/Ribbon/Ribbon';

const Ribbon = () => {
  const { state, dispatch } = useContext(RibbonContext);
  const ribbon = state.ribbon;

  if (!ribbon) return null;

  return (
    <RadianceRibbon variant={ribbon.variant} closable={ribbon.closable} onClose={() => dispatch({ type: 'hide_ribbon' })}>
      {ribbon.message}
    </RadianceRibbon>
  );
};

export default Ribbon;
