import { FC } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { SystemPageLink, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';

interface Props {
  language: string;
  locale: string;
  orderDetailBaseRoute: string;
  callOffOrderDetailBaseRoute: string;
  orderId: string;
  showOrderDetailButton?: boolean;
  showCallOffAgreementButton?: boolean;
  agreementNumber: string | undefined;
}

const OrderLineButtons: FC<Props> = ({ language, locale, orderDetailBaseRoute, callOffOrderDetailBaseRoute, orderId, showOrderDetailButton = false, showCallOffAgreementButton = false, agreementNumber }: Props) => {
  const translate = useTranslations();

  return (
    <Box gap="sm" direction="column">
      {showCallOffAgreementButton && agreementNumber !== undefined && (
        <Box width="100%">
          <SystemPageLink baseRoute={callOffOrderDetailBaseRoute} locale={locale} type={SystemPageType.CALL_OFF_ORDER_DETAIL} slug={agreementNumber}>
            <Button fullWidth variant="primary">
              {translate('page-consumer.order-line-flyout.call-off-agreement')}
            </Button>
          </SystemPageLink>
        </Box>
      )}
      <Box gap={'sm'} width="100%">
        {showOrderDetailButton && (
          <SystemPageLink baseRoute={orderDetailBaseRoute} locale={locale} type={SystemPageType.ORDER_DETAIL} slug={orderId}>
            <Button fullWidth variant={showCallOffAgreementButton ? 'secondary' : 'primary'}>
              {translate('page-consumer.order-line-flyout.order-details')}
            </Button>
          </SystemPageLink>
        )}
        <Link underline="none" style={{ width: '100%' }} href={`/my/${language}/my-prices`} prefetch={false}>
          <Button fullWidth variant="secondary">
            {translate('page-consumer.order-line-flyout.new-quote')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default OrderLineButtons;
