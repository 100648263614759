'use client';

import { FC, PropsWithChildren, useMemo } from 'react';

import { ClientConfig } from '@ravago/shared/page-data/models/config';

import { ConfigContext } from '../context/config-context';

interface Props extends PropsWithChildren {
   config: ClientConfig;
}

export const ConfigProviders: FC<Props> = ({ config, children }) => {
   const value = useMemo(
      () => ({
         config
      }),
      [config]
   );

   return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
