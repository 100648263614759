'use client';

import { FC } from 'react';

import { NavigationDropdown } from '@ravago/shared/page-data/models/config';
import { DataCy } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { NavItem } from '@ravago/shared/radiance/components/navigation/components/NavItem/NavItem';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useAuthentication } from '@ravago/shared/authentication';

import { NavigationUtils } from '../../../../utils/navigation.utils';

interface Props {
  menu?: NavigationDropdown;
  locale: string;
  colorIndex: number;
  onNavigate: () => void;
}

const MegaMenu: FC<Props> = ({ menu, onNavigate, locale, colorIndex }) => {
  const { user } = useAuthentication();

  if (!menu) return null;

  return (
    <BreakpointSwitch includeViews={['desktop']}>
      <Box spacing={{ x: 'lg' }} gap="xl" width="100%" direction="column">
        <Box gap="xl" width="100%">
          {NavigationUtils.filterItemsByAccessLevel(menu.itemsCollection.items, user).map((item) => (
            <Box width="100%" direction="column" key={item.label}>
              {'itemsCollection' in item ? (
                <Box direction="column">
                  <Typography variant="heading-4" color="strong">
                    {item.label}
                  </Typography>
                  <Box direction="column" gap="sm">
                    {NavigationUtils.filterItemsByAccessLevel(item.itemsCollection.items, user).map((subItem) => (
                      <NavItem
                        attributes={{ ...DataCy('mega-menu-item', subItem.label) }}
                        href={NavigationUtils.buildRoute(locale, subItem)}
                        key={subItem.label}
                        color={colorIndex}
                        target={subItem.openInNewTab ? '_blank' : undefined}
                        onClick={onNavigate}
                        icon={subItem.icon}
                        trailingIcon={subItem.openInNewTab ? 'external-link' : undefined}
                      >
                        <Typography variant="body" color="strong">
                          {subItem.label}
                        </Typography>
                        {subItem.description && (
                          <Typography variant="small" color="subtle">
                            {subItem.description}
                          </Typography>
                        )}
                      </NavItem>
                    ))}
                  </Box>
                </Box>
              ) : (
                <NavItem
                  attributes={{ ...DataCy('mega-menu-item', item.label) }}
                  href={NavigationUtils.buildRoute(locale, item)}
                  key={item.label}
                  target={item.openInNewTab ? '_blank' : undefined}
                  color={colorIndex}
                  onClick={onNavigate}
                  icon={item.icon}
                  trailingIcon={item.openInNewTab ? 'external-link' : undefined}
                >
                  <Typography variant="body" color="strong">
                    {item.label}
                  </Typography>
                  {item.description && (
                    <Typography variant="small" color="subtle">
                      {item.description}
                    </Typography>
                  )}
                </NavItem>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </BreakpointSwitch>
  );
};

export default MegaMenu;
