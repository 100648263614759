import { FC } from 'react';

import { OrderLineDetail } from '@ravago/shared/page-data/models/elements';
import { OrderPriceInfoRow } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  orderLine: OrderLineDetail;
}
const OrderLineDetailsTable: FC<Props> = ({ translate, orderLine }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" hover={false} rowDividers={false}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.order-line-flyout.your-order-reference')}
            </Typography>
          </TableCell>
          <TableCell>{orderLine.customerOrderReference || '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.order-line-flyout.quantity')}
            </Typography>
          </TableCell>
          <TableCell>{`${orderLine.quantity.amount} ${orderLine.quantity.unitOfMeasure}`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.order-line-flyout.packaging')}
            </Typography>
          </TableCell>
          <TableCell>{orderLine.packaging}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.order-line-flyout.colour')}
            </Typography>
          </TableCell>
          <TableCell>{orderLine.product.color}</TableCell>
          <TableCell>{translate(`colors.${orderLine.product.color.toLowerCase().replace(' ', '-')}`)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.order-line-flyout.colour-code')}
            </Typography>
          </TableCell>
          <TableCell>{orderLine.product.producerColor}</TableCell>
        </TableRow>
        <OrderPriceInfoRow unitPrice={orderLine.unitPrice} isUnitPriceFixed={orderLine.isUnitPriceFixed ?? true} />
      </TableBody>
    </Table>
  </Box>
);

export default OrderLineDetailsTable;
