import { FC } from 'react';

import { CustomerProps, DeliveryArea, FlyoutProps } from '@ravago/shared/page-data/models/features';
import { ProductFlyoutColorDetails, ProductFlyoutProductTitle, useRequestForQuotationContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import RfqTimeframeOptions from '../fields/rfq-timeframe-options';

interface DeliveryAreaProps extends FlyoutProps, CustomerProps {
  deliveryArea: DeliveryArea;
  countries: DropdownOption[];
  timeframe?: DropdownOption;
  onSetTimeframe: (timeframe: DropdownOption | undefined) => void;
  onDeliveryAreaChange: (field: string, value: string | DropdownOption) => void;
}

const RfqFlyoutStepDeliveryArea: FC<DeliveryAreaProps> = ({ deliveryArea, timeframe, countries, onSetTimeframe, onPreviousStep, onNextStep, phone, title, onDeliveryAreaChange }) => {
  const translate = useTranslations();
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleDeliveryAreaChange = (field: string, value: string | DropdownOption | undefined) => {
    if (value !== undefined) {
      onDeliveryAreaChange(field, value);
    }
  };

  return (
    <>
      <Box direction="column">
        <ProductFlyoutProductTitle title={title} translate={translate} />
        <ProductFlyoutColorDetails value={requestForQuotationState.color} />

        <Box direction="column" gap="md">
          <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.delivery-area')}
          </Typography>
          <Typography component="span" variant="body">
            {translate('page-consumer.request-for-quotation.delivery-area-description')}
          </Typography>
          <Dropdown id="rfq-delivery-area-country" label={translate('page-consumer.request-for-quotation.contact-info.country')} emptyOption={false} value={deliveryArea.country} onChange={(value) => handleDeliveryAreaChange('country', value)} options={countries} />
          <Box gap="xs">
            <InputField label={translate('page-consumer.request-for-quotation.city')} id="rfq-delivery-area-city" value={deliveryArea.city} onChange={(value) => handleDeliveryAreaChange('city', value)} maxLength={40} />
            <InputField label={translate('page-consumer.request-for-quotation.zip-postal-code')} id="rfq-delivery-area-zip" value={deliveryArea.postalCode} onChange={(value) => handleDeliveryAreaChange('postalCode', value)} maxLength={10} />
          </Box>
        </Box>
        <RfqTimeframeOptions timeframe={timeframe} onSetTimeframe={onSetTimeframe} translate={translate} />
      </Box>
      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" items="center" width="100%">
                <Button variant="secondary" onClick={onPreviousStep} leadingIcon={!phone ? 'long-arrow-left' : ''} fullWidth>
                  {translate('page-consumer.request-for-quotation.contact-details')}
                </Button>
                <Button id="rfq-delivery-area-summary-btn" disabled={!timeframe || !Object.values(deliveryArea).every((value) => !!value)} variant="primary" onClick={onNextStep} trailingIcon={!phone ? 'long-arrow-right' : ''} fullWidth>
                  {translate('page-consumer.request-for-quotation.summary')}
                </Button>
              </Box>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default RfqFlyoutStepDeliveryArea;
