'use client';

import { FC, ReactNode, useEffect, useState } from 'react';

import classNames from 'classnames';

import { useBodyOverflowHidden } from '../../../../hooks/useBodyOverflowHidden/useBodyOverflowHidden';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

type Overflow = 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto' | 'initial' | 'inherit';

interface Props {
   open?: boolean;
   title?: string;
   icon?: string;
   closeOnOutsideClick?: boolean;
   closable?: boolean;
   onDialogClose?: () => void;
   dialogBodyOverflowHidden?: boolean;
   contentOverflowX?: Overflow;
   contentOverflowY?: Overflow;
   disableBackdrop?: boolean;
   children: ReactNode | Array<ReactNode>;
   actions?: ReactNode | Array<ReactNode>;
}

export const Dialog: FC<Props> = ({ open = false, title, icon, dialogBodyOverflowHidden = true, closeOnOutsideClick = true, closable = true, onDialogClose, contentOverflowX = 'hidden', contentOverflowY = 'auto', disableBackdrop = false, children, actions }) => {
   const [isOpen, setIsOpen] = useState(open);

   useEffect(() => setIsOpen(open), [open]);
   useBodyOverflowHidden(isOpen);

   /* istanbul ignore next */
   const handleClose = (outsideClick = false) => {
      if (outsideClick && !closeOnOutsideClick) return;

      setIsOpen(false);
      onDialogClose?.();
   };

   if (!isOpen) return null;

   return (
      <div data-testid="dialog-backdrop" onClick={!disableBackdrop ? () => handleClose(true) : undefined} role="presentation" className={classNames('fixed inset-0 flex items-center justify-center empty:hidden z-[9999]', !disableBackdrop && 'bg-component-feedback-dialog-backdrop-color-background')}>
         {isOpen && (
            <div className="flex justify-center">
               <div
                  onClick={(e) => e.stopPropagation()}
                  role="presentation"
                  data-testid="dialog"
                  className="shadow-component-feedback-dialog-container-box-shadow p-component-feedback-dialog-container-spacing-padding gap-component-feedback-dialog-container-spacing-gap min-w-component-feedback-dialog-container-sizing-min-width bg-component-feedback-dialog-container-color-background border-component-feedback-dialog-container-color-border border-component-feedback-dialog-container-border-width rounded-component-feedback-dialog-container-border-radius flex max-h-[calc(100vh-var(--component-feedback-dialog-container-spacing-margin)*2)] max-w-[calc(100vw-var(--component-feedback-dialog-container-spacing-margin)*2)] flex-col items-center"
               >
                  <div data-testid={dialogBodyOverflowHidden ? 'dialog-overflow-hidden' : 'dialog-overflow'} className={classNames('gap-component-feedback-dialog-body-spacing-gap flex w-full flex-col', dialogBodyOverflowHidden && 'overflow-hidden')}>
                     <div className="flex items-center justify-between">
                        <div className="gap-component-feedback-dialog-header-spacing-gap flex items-center">
                           {icon && (
                              <div data-testid="dialog-icon" className="text-component-feedback-dialog-header-start-color-fill">
                                 <Icon icon={icon} />
                              </div>
                           )}
                           {title && (
                              <div data-testid="dialog-title" className="text-component-feedback-dialog-header-middle-color-text">
                                 <Typography variant="heading-4" color="strong">
                                    {title}
                                 </Typography>
                              </div>
                           )}
                        </div>
                        {closable && (
                           <div data-testid="dialog-close" onClick={() => handleClose()} role="presentation" className="text-component-feedback-dialog-header-end-color-fill cursor-pointer">
                              <Icon icon="times" />
                           </div>
                        )}
                     </div>
                     <div style={{ overflowX: contentOverflowX, overflowY: contentOverflowY }} className="text-component-feedback-dialog-body-color-text h-full">
                        {children}
                     </div>
                  </div>
                  {actions && (
                     <div data-testid="dialog-actions" className="gap-component-feedback-dialog-actions-spacing-gap phone:flex-col flex w-full justify-end">
                        {actions}
                     </div>
                  )}
               </div>
            </div>
         )}
      </div>
   );
};
