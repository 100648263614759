import { useShoppingCartContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';

import AddToShoppingCartDuplicateInfo from './add-to-shopping-cart-duplicate-info';
import AddToShoppingCartSuccess from './add-to-shopping-cart-success';
import AddToShoppingCartValidation from './add-to-shopping-cart-validation';

interface Props {
  locale: string;
  token?: string;
}
const ShoppingCartMenu = ({ locale, token }: Props) => {
  const { state: shoppingCartState } = useShoppingCartContext();
  return (
    <Box width="100%" direction="column" spacing="lg">
      {shoppingCartState.type === 'SUCCESS' && <AddToShoppingCartSuccess locale={locale} />}
      {shoppingCartState.type === 'DUPLICATE' && token && <AddToShoppingCartDuplicateInfo token={token} />}
      {shoppingCartState.type === 'ERROR' && shoppingCartState.validationErrors.length > 0 && <AddToShoppingCartValidation />}
    </Box>
  );
};

export default ShoppingCartMenu;
