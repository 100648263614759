'use client';

import { FC, useMemo } from 'react';

import { useConfigContext, useDialogContext, useDocumentShare, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Chip } from '@ravago/shared/radiance/components/chip/components/Chip/Chip';
import { Dialog } from '@ravago/shared/radiance/components/dialog/components/Dialog/Dialog';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { usePathname } from 'next/navigation';

// eslint-disable-next-line no-shadow
const enum CopySource {
  documents = 'documents',
  document = 'document',
  other = 'other'
}

const ShareDialog: FC = () => {
  const translate = useTranslations();
  const { state, dispatch } = useDialogContext();
  const { shareDocumentList, setShareList } = useDocumentShare();
  const { hostName } = useConfigContext();
  const pathname = usePathname();
  const { phone, tabPort } = useBreakpoint();

  const mobile = phone || tabPort;

  const documentsExcludingInternal = useMemo(() => shareDocumentList.documents.filter((doc) => !doc.isInternal), [shareDocumentList.documents]);
  const hasInternalDocuments = useMemo(() => shareDocumentList.documents.some((doc) => doc.isInternal), [shareDocumentList.documents]);

  const documentPathname = useMemo(() => {
    let url;
    if (documentsExcludingInternal && documentsExcludingInternal.length > 0 && documentsExcludingInternal[0].id) {
      url = `${hostName + pathname}?documentIds=${documentsExcludingInternal.flatMap((doc) => doc.id).join(';')}`;
    } else {
      url = hostName + pathname;
    }
    return url;
  }, [documentsExcludingInternal, hostName, pathname]);

  const documentDialogTitle = useMemo(() => {
    if (documentsExcludingInternal.length < 1) {
      return translate('page-consumer.seller-widget.share-page-text');
    }
    if (documentsExcludingInternal.length === 1) {
      return translate('page-consumer.seller-widget.share-document', { detailPageName: shareDocumentList.detailPageName ?? '', documentName: documentsExcludingInternal[0].description ?? '' });
    }
    return translate('page-consumer.seller-widget.share-documents', { detailPageName: shareDocumentList.detailPageName ?? '' });
  }, [documentsExcludingInternal, shareDocumentList.detailPageName, translate]);

  const handleCloseDialog = () => {
    if (dispatch) {
      dispatch({ type: 'close_dialog' });
    }
  };

  const handleRemoveChip = (id: string) => {
    const newShareDocumentList = { ...shareDocumentList };
    newShareDocumentList.documents = shareDocumentList.documents.filter((d) => d.id !== id);
    setShareList(newShareDocumentList);
  };

  const copyUrl = (copySource: CopySource) => {
    let url = '';
    if (copySource === CopySource.document) url = `${hostName}${pathname}?documentIds=${state.shareAnchorInfo?.docId}`;
    if (copySource === CopySource.documents) url = documentPathname;
    if (copySource === CopySource.other) url = `${hostName}${pathname}`;
    navigator.clipboard.writeText(url).then(() => {
      handleCloseDialog();
      notification(translate('page-consumer.seller-widget.copy-toast-body').toString(), { duration: 5000 });
    });
  };

  return (
    <>
      <Dialog
        open={state.idOpenDialog === 'share-page'}
        title={translate('page-consumer.seller-widget.share-page-text')}
        actions={
          <>
            <Button onClick={() => handleCloseDialog()} variant="secondary">
              {translate('page-consumer.seller-widget.cancel')}
            </Button>
            <Button onClick={() => copyUrl(CopySource.other)} variant="primary">
              {translate('page-consumer.seller-widget.copy-link')}
            </Button>
          </>
        }
        icon="share-alt"
        onDialogClose={handleCloseDialog}
        contentOverflowX="visible"
        contentOverflowY="visible"
      >
        <Box direction="column" spacing={{ top: 'md' }} minWidth={mobile ? 'calc(100vw - 180px)' : '560px'}>
          <InputGroup readOnly actionIcon="copy" onActionClick={() => copyUrl(CopySource.other)} id="'widget-share-page-link'" value={hostName + pathname} label={translate('page-consumer.seller-widget.copy-link')} />
        </Box>
      </Dialog>
      <Dialog
        open={state.idOpenDialog === 'share-document'}
        title={translate('page-consumer.seller-widget.share-document', { detailPageName: state.shareAnchorInfo?.detailPageName ?? '', documentName: state.shareAnchorInfo?.docName ?? '' })}
        actions={
          <>
            <Button onClick={() => handleCloseDialog()} variant="secondary">
              {translate('page-consumer.seller-widget.cancel')}
            </Button>
            <Button onClick={() => copyUrl(CopySource.document)} variant="primary">
              {translate('page-consumer.seller-widget.copy-link')}
            </Button>
          </>
        }
        icon="share-alt"
        onDialogClose={handleCloseDialog}
        contentOverflowX="visible"
        contentOverflowY="visible"
      >
        <Box direction="column" spacing={{ top: 'md' }} minWidth={mobile ? 'calc(100vw - 180px)' : '560px'}>
          <InputGroup readOnly actionIcon="copy" onActionClick={() => copyUrl(CopySource.document)} id="'widget-share-page-link'" value={`${hostName + pathname}?documentIds=${state.shareAnchorInfo?.docId}`} label={translate('page-consumer.seller-widget.copy-link')} />
        </Box>
      </Dialog>
      <Dialog
        open={state.idOpenDialog === 'share-documents'}
        title={documentDialogTitle}
        actions={
          <>
            <Button onClick={() => handleCloseDialog()} variant="secondary">
              {translate('page-consumer.seller-widget.cancel')}
            </Button>
            <Button onClick={() => copyUrl(CopySource.documents)} variant="primary">
              {translate('page-consumer.seller-widget.copy-link')}
            </Button>
          </>
        }
        icon="share-alt"
        onDialogClose={handleCloseDialog}
        contentOverflowX="visible"
        contentOverflowY="visible"
      >
        <Box direction="column" spacing={{ top: 'md' }} minWidth={mobile ? 'calc(100vw - 180px)' : '560px'}>
          {shareDocumentList.documents.length > 0 && (
            <Box wrap="wrap" gap="xs">
              {shareDocumentList.documents.map((doc) => (
                <Chip
                  key={doc.description}
                  type={doc.isInternal ? translate('page-consumer.related-documents.internal-use') : undefined}
                  label={doc.description}
                  onDelete={() => {
                    handleRemoveChip(doc.id ?? '');
                  }}
                />
              ))}
            </Box>
          )}
          <InputGroup readOnly actionIcon="copy" onActionClick={() => copyUrl(CopySource.documents)} id="'widget-share-page-link'" value={documentPathname} label={translate('page-consumer.seller-widget.copy-link')} />
          {hasInternalDocuments && (
            <Box direction="row" gap="xs">
              <Icon icon="info-circle" color="error" />
              <Typography variant="body" component="p" color="error">
                {translate('page-consumer.seller-widget.internal-use-warning')}
              </Typography>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ShareDialog;
