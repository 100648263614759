import { useEffect, useState } from 'react';

import { User, UserGroups } from '@ravago/shared/authentication';
import { ContentfulProduct, DynamicPageContentType } from '@ravago/shared/page-data/models/contentful';
import { EmptyState, NotFoundIllustration, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Dialog } from '@ravago/shared/radiance/components/dialog/components/Dialog/Dialog';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { saveAs } from 'file-saver';

import { downloadCompareProductDocuments, getProspectorUrlForSellers } from '../../../../actions';

import ProductCompareTable from './product-compare-table';

interface Props {
  open?: boolean;
  icon?: string;
  totalProducts: number;
  products: ContentfulProduct[];
  locale: string;
  user?: User;
  onDialogClose?: () => void;
  onRemoveSelection?: () => void;
  getDynamicPageUrls: (locale: string, contentType: DynamicPageContentType[]) => Promise<{ [key in DynamicPageContentType]?: string }>;
}

const ProductCompareDialog = ({ open = false, icon = 'balance-scale', totalProducts, products, locale, user, onDialogClose, onRemoveSelection, getDynamicPageUrls }: Props) => {
  const translate = useTranslations();

  const { phone, tabPort } = useBreakpoint();
  const [showLimitedCompareDescription, setShowLimitedCompareDescription] = useState(false);
  const [isLaunchButtonDisabled, setIsLaunchButtonDisabled] = useState(false);
  const [isLaunchButton, setIsLaunchButton] = useState(false);
  const [baseRoute, setBaseRoute] = useState<string>('');

  const mobile = phone || tabPort;

  useEffect(() => {
    setShowLimitedCompareDescription(products.some((product) => !product.ulProductId));
    setIsLaunchButtonDisabled(products.every((product) => !product.ulProductId));
  }, [products]);

  useEffect(() => {
    if (user?.groups?.includes(UserGroups.ravagoSalesRepresentative) || user?.groups?.includes(UserGroups.ravagoGlobalGroupAdmin)) {
      setIsLaunchButton(true);
    }
    const fetchData = async () => {
      const dynamicPageUrls = await getDynamicPageUrls(locale, ['product']);
      setBaseRoute(dynamicPageUrls.product || '');
    };
    fetchData();
  }, [locale, user, getDynamicPageUrls]);

  const onCompareButtonClick = () => {
    if (isLaunchButton) {
      getProspectorUrlForSellers(products).then((url) => window.open(url, '_blank'));
    } else {
      downloadCompareProductDocuments(products.filter((product) => product.ulProductId).map((product) => product.ulProductId!.toString()))
        .then((pdfData) => saveAs(pdfData, 'CompareProducts.pdf'))
        .catch(() => notification(translate('page-consumer.shipment.documents.download-error')));
    }
  };

  return (
    <Dialog
      open={open}
      onDialogClose={onDialogClose}
      title={translate('page-consumer.product-compare.compare-products')}
      icon={icon}
      actions={
        totalProducts > 0 && (
          <Box width={'100%'} justify={showLimitedCompareDescription ? 'between' : 'end'} gap={'xs'} items={mobile ? 'stretch' : 'center'} direction={mobile ? 'column' : 'row'}>
            {showLimitedCompareDescription && (
              <Typography component={'p'} variant={'body'} color={'subtle'}>
                {translate('page-consumer.product-compare.limited-information-description')}
              </Typography>
            )}
            <Box direction={mobile ? 'column' : 'row'} gap={'xs'}>
              <Button variant={'secondary'} onClick={onRemoveSelection}>
                {translate('page-consumer.product-compare.remove-selection')}
              </Button>
              <Button leadingIcon={isLaunchButton ? 'arrow-up-right-from-square' : 'download'} variant={'primary'} disabled={isLaunchButtonDisabled} onClick={onCompareButtonClick}>
                {isLaunchButton ? translate('page-consumer.product-compare.view-detailed-comparison') : translate('page-consumer.product-compare.download-detailed-comparison')}
              </Button>
            </Box>
          </Box>
        )
      }
    >
      {totalProducts > 0 ? (
        <ProductCompareTable locale={locale} baseRoute={baseRoute} productsToCompareList={products} />
      ) : (
        <Box height={'60vh'} width={mobile ? 'auto' : '50vw'} direction={'column'} justify={'center'} items={'center'} gap={'xl'} spacing={{ left: mobile ? 'sm' : '3xl', right: mobile ? 'sm' : '3xl' }}>
          <EmptyState
            title={translate('page-consumer.product-compare.no-products-selected')}
            label={translate('page-consumer.product-compare.no-products-selected-description')}
            image={<NotFoundIllustration />}
            button={
              <Button trailingIcon={'close'} variant={'primary'} onClick={onDialogClose}>
                {translate('page-consumer.product-compare.close')}
              </Button>
            }
          />
        </Box>
      )}
    </Dialog>
  );
};

export default ProductCompareDialog;
