'use client';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import dataAttributes from '../../../../utils/attributes/attributes';
import { Icon } from '../../../icon/components/Icon/Icon';
import { TabProps } from '../../../tabs/components/Tab/Tab';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props extends Omit<TabProps, 'label'> {
   active?: boolean;
   height?: string;
   width?: string;
   attributes?: Record<string, string>;
   onClick?: () => void;
   children: ReactNode;
}

export const NavTab: FC<Props> = ({ active = false, height, width, leadingIcon, trailingIcon, href, target, attributes = {}, onClick, children }) => (
   <button
      data-testid={`nav-tab${active ? '--active' : ''}`}
      {...dataAttributes(attributes)}
      type="button"
      style={{ height, width }}
      className={classNames(
         `hover:bg-component-navigation-navbar-navigation-group-state-layer-color-background-active-hover focus-visible:bg-component-navigation-navbar-navigation-group-state-layer-color-background-active-focused active:bg-component-navigation-navbar-navigation-group-state-layer-color-background-active-pressed disabled:hover:bg-component-navigation-navbar-navigation-group-state-layer-color-background-inactive-hover disabled:focus-visible:bg-component-navigation-navbar-navigation-group-state-layer-color-background-inactive-focused disabled:active:bg-component-navigation-navbar-navigation-group-state-layer-color-background-inactive-pressed z-10 appearance-none bg-transparent transition-colors`,
         active
            ? 'border-b-component-navigation-navbar-navigation-group-container-border-width-active border-b-component-navigation-navbar-navigation-group-container-color-border-active'
            : 'border-b-component-navigation-navbar-navigation-group-container-border-width-inactive border-b-component-navigation-navbar-navigation-group-container-color-border-inactive'
      )}
      onClick={onClick}
   >
      <a
         href={href}
         target={target}
         style={{ height, width }}
         className="min-h-component-navigation-navbar-navigation-group-container-sizing-min-height px-component-navigation-navbar-navigation-group-container-spacing-padding-x-category py-component-navigation-navbar-navigation-group-container-spacing-padding-y gap-component-navigation-navbar-navigation-group-container-spacing-gap box-border flex h-full items-center justify-center"
      >
         {leadingIcon && (
            <div data-testid="nav-tab-leading-icon" className="text-component-navigation-navbar-navigation-group-icon-color-fill">
               <Icon icon={leadingIcon} />
            </div>
         )}
         <div className="text-component-navigation-navbar-navigation-group-label-color-text">
            <Typography color="inherit" whiteSpace="nowrap">
               {children}
            </Typography>
         </div>
         {trailingIcon && (
            <div data-testid="nav-tab-trailing-icon" className="text-component-navigation-navbar-navigation-group-icon-color-fill">
               <Icon icon={trailingIcon} />
            </div>
         )}
      </a>
   </button>
);
