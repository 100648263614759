import { FC, ReactNode } from 'react';

import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';

interface Props {
  children: ReactNode;
}

const RfqImage: FC<Props> = ({ children }) => (
  <Box spacing={{ top: 'xl' }} direction="column" items="center">
    <Box maxWidth="500px">
      <AspectRatio phone="16/9" tablet="16/9" desktop="16/9" width={'100%'}>
        {children}
      </AspectRatio>
    </Box>
  </Box>
);

export default RfqImage;
