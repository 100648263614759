'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { UserGroups, useAuthentication } from '@ravago/shared/authentication';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { AdditionalInfoProps, ContactDetails, Customer, CustomerProps, DeliveryArea, ErrorProps, FlyoutProps, FlyoutSteps, GuestRequestForQuotation, PackagingProps, PreferredDeliveryTimeframe, QuantityProps, RfqStep, VerifiedRequestForQuotation } from '@ravago/shared/page-data/models/features';
import { getCountryCode, getLanguageCode, useFlyoutContext, useRequestForQuotationContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Flyout } from '@ravago/shared/radiance/components/flyout/components/Flyout/Flyout';
import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { Countries, PhoneDetails } from '@ravago/shared/radiance/components/input-field/components/PhoneInputField/PhoneInputField.types';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useSearchParams } from 'next/navigation';
import { firstBy } from 'thenby';

import { getCustomer, requestGuestQuotation, requestQuotation } from '../../../../actions';
import { Country } from '../../../services/contentful/locale.service';

import RfqFlyoutStepAddressDetails from './components/steps/rfq-flyout-step-address-details';
import RfqFlyoutStepContactDetails from './components/steps/rfq-flyout-step-contact-details';
import RfqFlyoutStepDeliveryArea from './components/steps/rfq-flyout-step-delivery-area';
import RfqFlyoutStepFinalMessage from './components/steps/rfq-flyout-step-final-message';
import RfqFlyoutStepQuotationDetails from './components/steps/rfq-flyout-step-quotation-details';
import RfqFlyoutStepRecognizedEmail from './components/steps/rfq-flyout-step-recognized-email';
import RfqFlyoutStepSummary from './components/steps/rfq-flyout-step-summary';
import RfqFlyoutStepVerifyEmail from './components/steps/rfq-flyout-step-verify-email';

interface Props {
  locale: string;
  availableCountries: Country[];
}

const RequestForQuotationFlyout: FC<Props> = ({ locale, availableCountries }: Props) => {
  const translate = useTranslations();
  const countryCode = getCountryCode(locale);

  const { state, dispatch } = useFlyoutContext();
  const { state: requestForQuotationState, dispatch: requestForQuotationDispatch } = useRequestForQuotationContext();
  const { user, accessToken } = useAuthentication();
  const { phone } = useBreakpoint();
  const [quantities, setQuantities] = useState<number[]>([]);
  const [packagingOptions, setPackagingOptions] = useState<DropdownOption[]>([]);
  const [selectedPackagingGroup, setSelectedPackagingGroup] = useState<DropdownOption>();
  const [uom, setUom] = useState('kg');
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [newQuantity, setNewQuantity] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [otherPackagingDescription, setOtherPackagingDescription] = useState('');
  const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState<Address>();
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [timeframe, setTimeframe] = useState<DropdownOption>();
  const [requestQuoteError, setRequestQuoteError] = useState(false);
  const [isDeliveryAddressExpanded, setIsDeliveryAddressExpanded] = useState(false);
  const [deliveryArea, setDeliveryArea] = useState<DeliveryArea>({
    country: { id: '', value: '' },
    city: '',
    postalCode: ''
  });
  const [contactDetails, setContactDetails] = useState<ContactDetails>({
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    country: { id: '', value: '' },
    phone: { phone: '' },
    termsAndConditions: false
  });
  const countries: DropdownOption[] = useMemo(
    () =>
      availableCountries
        .map((country) => ({
          id: country.shortName,
          value: country.fullName
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [availableCountries]
  );
  const searchParams = useSearchParams();

  const [userSteps, setUserSteps] = useState<{ steps: RfqStep[]; currentStep: RfqStep }>({
    steps: [],
    currentStep: RfqStep.QUOTATION_DETAILS
  });
  const verifiedUserSteps: FlyoutSteps = [RfqStep.QUOTATION_DETAILS, RfqStep.ADDRESS_DETAILS, RfqStep.SUMMARY, RfqStep.FINAL_MESSAGE];
  const guestOrPendingUserSteps: FlyoutSteps = [RfqStep.QUOTATION_DETAILS, RfqStep.CONTACT_DETAILS, RfqStep.DELIVERY_AREA, RfqStep.SUMMARY, RfqStep.FINAL_MESSAGE];
  const anonymousUserSteps: FlyoutSteps = [RfqStep.QUOTATION_DETAILS, RfqStep.VERIFY_EMAIL, RfqStep.CONTACT_DETAILS, RfqStep.DELIVERY_AREA, RfqStep.SUMMARY, RfqStep.FINAL_MESSAGE];

  const defineRfqSteps = () => {
    const initialStep = searchParams.get('flyout') === 'open' ? 1 : 0;

    if (requestForQuotationState.userPermissions?.hasVerifiedPermission) {
      setUserSteps({ steps: verifiedUserSteps, currentStep: verifiedUserSteps[initialStep] });
      return;
    }
    if (requestForQuotationState.userPermissions?.hasGuestPermission || requestForQuotationState.userPermissions?.hasPendingPermission) {
      setUserSteps({ steps: guestOrPendingUserSteps, currentStep: guestOrPendingUserSteps[initialStep] });
      return;
    }
    setUserSteps({ steps: anonymousUserSteps, currentStep: anonymousUserSteps[0] });
  };

  const prefillPackagingGroup = () => {
    if (!requestForQuotationState.product) {
      return;
    }

    const packaging = searchParams.get('packaging');

    const options = requestForQuotationState.product.packagingGroups.map((group) => ({
      id: group.id.toString(),
      value: translate(`packaging-groups.${group.description.toLowerCase().replace(' ', '-')}`)
    }));
    setPackagingOptions(options);

    if (packaging) {
      const paramPackaging = options.filter((option) => option.value === packaging);
      setSelectedPackagingGroup(paramPackaging[0]);
    } else {
      const defaultPackaging = requestForQuotationState.product.packagingGroups.find((group) => group.defaultPackaging);
      if (defaultPackaging && !selectedPackagingGroup) {
        const selected: DropdownOption = {
          id: defaultPackaging.id.toString(),
          value: translate(`packaging-groups.${defaultPackaging.description.toLowerCase().replace(' ', '-')}`)
        };
        setSelectedPackagingGroup(selected);
      }
    }
  };

  const prefillQuantities = () => {
    const quantitiesParams = searchParams.get('quantities');
    if (quantitiesParams) {
      const newQuantities = quantitiesParams
        .split('-')
        .filter((_quantity, index) => index <= 4)
        .map((quantity) => parseInt(quantity, 10));
      setQuantities(newQuantities);
    }
  };

  useEffect(() => {
    prefillPackagingGroup();
    prefillQuantities();
  }, [state, requestForQuotationState]);

  useEffect(() => {
    defineRfqSteps();
  }, [requestForQuotationState.userPermissions]);

  useEffect(() => {
    const currentCountry = countries.find((country) => country.id === countryCode)!;
    const countriesList = Object.values(Countries).sort(firstBy('prefix'));
    const phoneCountry = countriesList.find((c) => c.iso2 === currentCountry?.id);
    if (accessToken) {
      if (!user?.groups?.includes(UserGroups.digitalPlatformPendingVerification) && !user?.groups?.includes(UserGroups.digitalPlatformGuest)) {
        getCustomer(accessToken).then((currentCustomer) => {
          setCustomer(currentCustomer);

          if (!currentCustomer) return;

          const deliveryAddress = currentCustomer.addresses.find((address) => address.isDefault && address.type === 'Delivery Address');
          setCustomerDeliveryAddress(deliveryAddress);

          if (!deliveryAddress) {
            setIsDeliveryAddressExpanded(true);
          }

          const uomPreference = currentCustomer.preferences.find((preference) => preference.name === 'UOM_SYSTEM')?.value;
          setUom(uomPreference === 'US customary units' ? 'lb' : 'kg');
        });
      }

      if (user && (user.groups?.includes(UserGroups.digitalPlatformPendingVerification) || user.groups?.includes(UserGroups.digitalPlatformGuest))) {
        setContactDetails({
          ...contactDetails,
          email: user.sub,
          firstName: user.firstname,
          lastName: user.lastname,
          country: currentCountry,
          phone: { countryInfo: phoneCountry, phone: '' }
        });
      }
    } else {
      setContactDetails({
        ...contactDetails,
        country: currentCountry,
        phone: { countryInfo: phoneCountry, phone: '' }
      });
    }

    setDeliveryArea({
      ...deliveryArea,
      country: currentCountry
    });
  }, [accessToken, user]);

  const addQuantity = () => {
    const quantity = parseInt(newQuantity, 10);

    if (Number.isNaN(quantity) || quantity < 1) {
      setHasError(true);
      setErrorText(translate('page-consumer.request-for-quotation.invalid-quantity'));
      return;
    }

    if (quantities.includes(quantity)) {
      setHasError(true);
      setErrorText(translate('page-consumer.request-for-quotation.quantity-already-added-error'));
      return;
    }

    setQuantities((prevQuantities) => [...prevQuantities, quantity].sort((a, b) => a - b));
    setNewQuantity('');
    setHasError(false);
  };

  const removeQuantity = (index: number) => {
    setQuantities((prevQuantities) => prevQuantities.filter((_, i) => i !== index));
    setHasError(false);
  };

  const handleNewQuantityChange = (value: string) => {
    setNewQuantity(value);
    setHasError(false);
  };

  const handleAdditionalInfoChange = (value: string) => {
    setAdditionalInfo(value);
  };

  const handleOtherPackagingDescriptionChange = (value: string) => {
    setOtherPackagingDescription(value);
  };

  const handlePackagingChange = (value?: DropdownOption) => {
    setSelectedPackagingGroup(value);
    if (value?.value !== 'Other') {
      setOtherPackagingDescription('');
    }
  };

  const resetValues = () => {
    const currentCountry = countries.find((country) => country.id === countryCode)!;
    const countriesList = Object.values(Countries).sort(firstBy('prefix'));
    const phoneCountry = countriesList.find((c) => c.iso2 === currentCountry.id);

    setQuantities([]);
    setAdditionalInfo('');
    setOtherPackagingDescription('');
    setDeliveryArea({ country: currentCountry, city: '', postalCode: '' });
    setContactDetails({
      ...contactDetails,
      country: currentCountry,
      companyName: '',
      phone: { countryInfo: phoneCountry, phone: '' },
      termsAndConditions: false
    });
    setRequestQuoteError(false);
    setCustomerDeliveryAddress(customer?.addresses.find((address) => address.isDefault && address.type === 'Delivery Address'));
    setUserSteps({ ...userSteps, currentStep: userSteps.steps[0] });
    setTimeframe(undefined);
    if (requestForQuotationDispatch) {
      requestForQuotationDispatch({ type: 'reset_color' });
    }
  };

  const handleCloseFlyout = () => {
    if (userSteps.currentStep === RfqStep.FINAL_MESSAGE) {
      resetValues();
      const path = window.location.href.split('?')[0];
      window.history.pushState(null, '', path);
    }

    if (dispatch) {
      dispatch({ type: 'close_flyout', value: { flyoutType: 'request-for-quotation', title: undefined } });
    }
  };

  const handleTryAgain = () => {
    setRequestQuoteError(false);
    setUserSteps({ ...userSteps, currentStep: RfqStep.SUMMARY });
  };

  const createQuotationLines = () =>
    quantities.map((qty) => ({
      productId: Number(requestForQuotationState.product?.id),
      quantity: {
        amount: qty,
        unitOfMeasure: uom
      },
      preferredPackagingGroupId: Number(selectedPackagingGroup?.id),
      otherPackagingInfo: otherPackagingDescription,
      colorId: Number(requestForQuotationState.color?.colorId),
      desiredColor: requestForQuotationState.color?.desiredColor || undefined,
      producerColorCode: requestForQuotationState.color?.producerColorCode || undefined
    }));

  const requestQuote = async () => {
    let response: boolean;
    if (accessToken && user && (user.groups.includes(UserGroups.ravagoSalesRepresentative) || user.groups.includes(UserGroups.customerPortal))) {
      const rfqData: VerifiedRequestForQuotation = {
        type: 'QUOTATION',
        preferredDeliveryTimeframe: timeframe?.id as PreferredDeliveryTimeframe,
        additionalInfo,
        deliveryAddressId: customerDeliveryAddress!.id,
        quotationRequestLines: createQuotationLines()
      };

      response = await requestQuotation(accessToken, rfqData as VerifiedRequestForQuotation);
    } else {
      const rfqData: GuestRequestForQuotation = {
        preferredDeliveryTimeframe: timeframe?.id as PreferredDeliveryTimeframe,
        additionalInfo,
        deliveryAddress: {
          country: deliveryArea.country.value,
          city: deliveryArea.city,
          postalCode: deliveryArea.postalCode
        },
        contactInfo: {
          firstName: contactDetails.firstName,
          lastName: contactDetails.lastName,
          email: contactDetails.email,
          language: getLanguageCode(locale),
          phoneNumber: `+${contactDetails.phone.countryInfo?.prefix} ${contactDetails.phone.phone}`,
          company: {
            name: contactDetails.companyName,
            countryIsoCode: contactDetails.country.id
          }
        },
        quotationRequestLines: createQuotationLines()
      };
      response = await requestGuestQuotation(rfqData, accessToken);
    }

    if (!response) {
      setRequestQuoteError(true);
    }
    setUserSteps({ ...userSteps, currentStep: RfqStep.FINAL_MESSAGE });
  };

  const handleAddressChange = (address: Address) => {
    setCustomerDeliveryAddress(address);
    if (customer) {
      const sortedAddresses = customer.addresses.filter((a) => a.id !== address.id);
      setCustomer({ ...customer, addresses: [address, ...sortedAddresses] });
    }
    setIsDeliveryAddressExpanded(false);
  };

  const onNextStep = () => {
    setUserSteps({
      ...userSteps,
      currentStep: userSteps.steps[userSteps.steps.indexOf(userSteps.currentStep) + 1]
    });
  };

  const onPreviousStep = () => {
    setUserSteps({
      ...userSteps,
      currentStep: userSteps.steps[userSteps.steps.indexOf(userSteps.currentStep) - 1]
    });
  };

  const handleContactDetails = (field: string, value: string | PhoneDetails | DropdownOption | boolean) => {
    if (field === 'country') {
      const dropdownCountryOption = value as DropdownOption;
      const countriesList = Object.values(Countries).sort(firstBy('prefix'));
      const phoneCountry = countriesList.find((c) => c.iso2 === dropdownCountryOption.id);

      setContactDetails({
        ...contactDetails,
        country: dropdownCountryOption,
        phone: { ...contactDetails.phone, countryInfo: phoneCountry || contactDetails.phone.countryInfo }
      });
    } else {
      setContactDetails({ ...contactDetails, [field]: value });
    }
  };

  const handleDeliveryAreaChange = (field: string, value: string | DropdownOption) => {
    setDeliveryArea({ ...deliveryArea, [field]: value });
  };

  const flyoutProps: FlyoutProps = {
    title: state.title,
    phone,
    handleCloseFlyout,
    currentStep: userSteps.steps.indexOf(userSteps.currentStep),
    onNextStep,
    onPreviousStep
  };
  const quantityProps: QuantityProps = {
    addQuantity,
    removeQuantity,
    quantities,
    newQuantity,
    handleNewQuantityChange
  };
  const packagingProps: PackagingProps = {
    packagingOptions,
    selectedPackagingGroup,
    otherPackagingDescription,
    handleOtherPackagingDescriptionChange,
    handlePackagingChange
  };
  const errorProps: ErrorProps = { hasError, errorText };
  const customerProps: CustomerProps = {
    customer,
    customerDeliveryAddress,
    handleAddressChange,
    isDeliveryAddressExpanded
  };
  const additionalInfoProps: AdditionalInfoProps = { additionalInfo, handleAdditionalInfoChange };

  return (
    <Flyout position={phone ? 'bottom' : 'right'} width="100%" open={state.idOpenDialog === 'request-for-quotation'} closeOnOutsideClick onFlyoutClose={() => handleCloseFlyout()}>
      <Box direction="column" gap="md" spacing="xs" overflowY="auto" height={'calc(100% - 110px)'}>
        {userSteps.currentStep !== RfqStep.FINAL_MESSAGE && (
          <Box justify="between" items="center">
            <Typography component="h4" variant="heading-4" color="strong">
              {translate('page-consumer.request-for-quotation.request-a-quote')}
            </Typography>
            <IconButton icon={phone ? 'chevron-down' : 'chevron-right'} onClick={() => handleCloseFlyout()} />
          </Box>
        )}

        {userSteps.currentStep === RfqStep.QUOTATION_DETAILS && <RfqFlyoutStepQuotationDetails {...flyoutProps} {...quantityProps} {...packagingProps} {...errorProps} uom={uom} user={user} />}
        {userSteps.currentStep === RfqStep.VERIFY_EMAIL && (
          <RfqFlyoutStepVerifyEmail
            email={contactDetails.email}
            requestForQuotationState={requestForQuotationState}
            quantities={quantities}
            selectedPackagingGroup={selectedPackagingGroup}
            onContactDetailsChange={handleContactDetails}
            onSetUserSteps={(steps, currentStep) => setUserSteps({ steps, currentStep })}
            {...flyoutProps}
          />
        )}
        {userSteps.currentStep === RfqStep.RECOGNIZED_EMAIL && <RfqFlyoutStepRecognizedEmail locale={locale} {...flyoutProps} />}
        {userSteps.currentStep === RfqStep.ADDRESS_DETAILS && <RfqFlyoutStepAddressDetails {...flyoutProps} {...customerProps} timeframe={timeframe} onSetTimeframe={setTimeframe} onToggleDeliveryAddress={setIsDeliveryAddressExpanded} />}
        {userSteps.currentStep === RfqStep.DELIVERY_AREA && <RfqFlyoutStepDeliveryArea {...flyoutProps} {...customerProps} timeframe={timeframe} countries={countries} deliveryArea={deliveryArea} onDeliveryAreaChange={handleDeliveryAreaChange} onSetTimeframe={setTimeframe} />}
        {userSteps.currentStep === RfqStep.CONTACT_DETAILS && <RfqFlyoutStepContactDetails locale={locale} {...flyoutProps} onContactDetailsChange={handleContactDetails} contactDetails={contactDetails} countries={countries} user={user} />}
        {userSteps.currentStep === RfqStep.SUMMARY && (
          <RfqFlyoutStepSummary
            {...flyoutProps}
            {...additionalInfoProps}
            {...customerProps}
            {...packagingProps}
            user={user}
            requestQuote={requestQuote}
            quantities={quantities}
            uom={uom}
            timeframe={timeframe}
            selectedPackagingGroup={selectedPackagingGroup}
            deliveryArea={deliveryArea}
            contactDetails={contactDetails}
          />
        )}
        {userSteps.currentStep === RfqStep.FINAL_MESSAGE && (
          <RfqFlyoutStepFinalMessage {...flyoutProps} languageCode={getLanguageCode(locale)} requestQuoteError={requestQuoteError} isVerified={user?.groups.includes(UserGroups.ravagoSalesRepresentative)} handleTryAgain={handleTryAgain} user={user} />
        )}
      </Box>
    </Flyout>
  );
};

export default RequestForQuotationFlyout;
