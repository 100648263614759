import { FC } from 'react';

import { User } from '@ravago/shared/authentication';
import { FlyoutProps } from '@ravago/shared/page-data/models/features';
import { TrackingUtils, useRequestForQuotationContext, UserUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';

interface FinalMessageProps extends FlyoutProps {
  languageCode: string;
  user: User | undefined;
  requestQuoteError: boolean;
  isVerified?: boolean;
  handleTryAgain: () => void;
}

const RfqFlyoutStepFinalMessage: FC<FinalMessageProps> = ({ languageCode, user, requestQuoteError, isVerified, phone, handleTryAgain, handleCloseFlyout }) => {
  const translate = useTranslations();
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleMyQuotesClick = () => {
    TrackingUtils.track('rfq_click_my_quotes_flyout', {
      productName: requestForQuotationState.product?.description,
      productId: requestForQuotationState.product?.id,
      userLoggedIn: JSON.stringify(!!user),
      userType: UserUtils.getUserType(user)
    });
  };

  return (
    <>
      <Box direction="column">
        {requestQuoteError ? (
          <Box height="60vh" width="auto" direction="column" justify="center" items="center" gap="xl" spacing={{ left: '3xl', right: '3xl' }}>
            <Image src="/images/rfq-error.png" alt={translate('page-consumer.request-for-quotation.error')} title={translate('page-consumer.request-for-quotation.error')} objectFit="contain" />
            <Typography align="center" component="h4" variant="heading-4" color="primary">
              {translate('page-consumer.request-for-quotation.error')}
            </Typography>
            <Typography align="center" component="b" variant="body" color="strong">
              {translate('page-consumer.request-for-quotation.error-message')}
            </Typography>
          </Box>
        ) : (
          <Box height="60vh" width="auto" direction="column" justify="center" items="center" gap="xl" spacing={{ left: '3xl', right: '3xl' }}>
            <Image src="/images/rfq-thank-you.png" alt={translate('page-consumer.request-for-quotation.thank-you')} title={translate('page-consumer.request-for-quotation.thank-you')} objectFit="contain" />
            <Typography align="center" component="h4" variant="heading-4" color="primary">
              {translate('page-consumer.request-for-quotation.thank-you')}
            </Typography>
            <Typography align="center" component="b" variant="body" color="strong">
              {translate('page-consumer.request-for-quotation.thank-you-message')}
            </Typography>
          </Box>
        )}
      </Box>

      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" direction="column" width="100%">
                {requestQuoteError && (
                  <Button variant="primary" fullWidth onClick={() => handleTryAgain()} trailingIcon={!phone ? 'refresh' : ''}>
                    {translate('page-consumer.request-for-quotation.try-again')}
                  </Button>
                )}
                {isVerified && (
                  <Link underline="none" href={`/my/${languageCode}/my-prices`} prefetch={false}>
                    <Button variant="primary" fullWidth onClick={handleMyQuotesClick}>
                      {translate('page-consumer.request-for-quotation.my-quotes')}
                    </Button>
                  </Link>
                )}
                <Button id="rfq-close-btn" variant="secondary" fullWidth onClick={() => handleCloseFlyout()}>
                  {translate('page-consumer.request-for-quotation.close')}
                </Button>
              </Box>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default RfqFlyoutStepFinalMessage;
