import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
  translate: (key: string, placeholders?: { [key: string]: any }) => string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  company?: string;
  phone?: string;
}
const RfqContactInfo: FC<Props> = ({ translate, email, firstName, lastName, company, country, phone }: Props) => (
  <Box direction="column">
    <Table stroked="horizontal" tableLayout="fixed" hover={false}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.contact-info.contact-person')}
            </Typography>
          </TableCell>
          <TableCell>{`${firstName} ${lastName}`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.contact-info.email')}
            </Typography>
          </TableCell>
          <TableCell wordBreak="all">{email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.contact-info.company')}
            </Typography>
          </TableCell>
          <TableCell>{company}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="b" variant="body-highlight" color="primary">
              {translate('page-consumer.request-for-quotation.contact-info.country')}
            </Typography>
          </TableCell>
          <TableCell>{country}</TableCell>
        </TableRow>
        {phone && (
          <TableRow>
            <TableCell>
              <Typography component="b" variant="body-highlight" color="primary">
                {translate('page-consumer.request-for-quotation.contact-info.phone')}
              </Typography>
            </TableCell>
            <TableCell>{phone}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Box>
);

export default RfqContactInfo;
