'use client';

import { FC, useEffect, useState } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { CallOffOrderDetail, Quantity, RequestDelivery } from '@ravago/shared/page-data/models/elements';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Customer } from '@ravago/shared/page-data/models/features';
import { CallOffOrderDeliveryRequest, CallOffProgressBar, DateUtils, DynamicPageLink, getLanguageCode, SystemPageLink, useCallOffAgreementContext, useFlyoutContext, useTranslations, useRequestForQuotationContext, TrackingUtils } from '@ravago/shared/page-elements-radiance-consumer';
import { Avatar } from '@ravago/shared/radiance/components/avatar/components/Avatar/Avatar';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Flyout } from '@ravago/shared/radiance/components/flyout/components/Flyout/Flyout';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import { useAuthentication, UserGroups } from '@ravago/shared/authentication';

import { Link } from 'libs/shared/page-elements-radiance/src/lib/components/shared/link/link';

import { getCallOffOrderDetail, getCustomer, getDynamicPageUrls, getProductByEntryId, getSystemPageUrl, requestDelivery } from '../../../../actions';

import CallOffContactInfo from './components/call-off-contact-info';
import CallOffDeliveryInfo from './components/call-off-delivery-info';
import CallOffProductInfo from './components/call-off-product-info';

interface Props {
  locale: string;
}

const CallOffAgreementFlyout: FC<Props> = ({ locale }) => {
  const translate = useTranslations();
  const language = getLanguageCode(locale);
  const { phone } = useBreakpoint();
  const { user, accessToken } = useAuthentication();
  const { state: flyoutState, dispatch: flyoutDispatch } = useFlyoutContext();
  const { state: callOffAgreementState, dispatch: callOffAgreementDispatch } = useCallOffAgreementContext();
  const { dispatch: requestForQuotationDispatch } = useRequestForQuotationContext();
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState<Address>();
  const [currentStep, setCurrentStep] = useState(1);
  const [productsBaseRoute, setProductsBaseRoute] = useState('');
  const [callOffAgreementBaseRoute, setCallOffAgreementBaseRoute] = useState('');
  const [requestDeliveryError, setRequestDeliveryError] = useState(false);
  const [callOffOrderDetail, setCallOffOrderDetail] = useState<CallOffOrderDetail | undefined>();
  const { deliveryDate, callOffId, quantity, additionalInfo, openedFrom } = callOffAgreementState;

  useEffect(() => {
    const fetchCallOffOrderDetail = async (token: string, id: string) => {
      try {
        setIsLoading(true);
        const [callOffOrder, customerResponse, callOffAgreementRoute] = await Promise.all([getCallOffOrderDetail(token, id), getCustomer(token), getSystemPageUrl(locale, SystemPageType.CALL_OFF_ORDER_DETAIL)]);
        if (callOffOrder) {
          if (callOffOrder.product.pkbId) {
            const [dynamicPageUrls, contentfulProduct] = await Promise.all([getDynamicPageUrls(locale, ['product']), getProductByEntryId(locale, `product-${callOffOrder.product.pkbId}`)]);
            setProductsBaseRoute(dynamicPageUrls.product || '');
            callOffOrder.contentfulProduct = contentfulProduct;
          }
          setCallOffAgreementBaseRoute(callOffAgreementRoute);
          setCallOffOrderDetail(callOffOrder);
        }
        if (customerResponse) {
          setCustomer(customerResponse);
          const defaultAddress = customerResponse.addresses.find((address) => address.isDefault && address.type === 'Legal Address');
          if (defaultAddress) {
            setDeliveryAddress(defaultAddress);
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (callOffAgreementState.callOffOrderDetail) {
      setCallOffOrderDetail(callOffAgreementState.callOffOrderDetail);
      setCustomer(callOffAgreementState.customer);
      setDeliveryAddress(callOffAgreementState.deliveryAddress);
      setCurrentStep(2);
    } else if (callOffId && flyoutState.idOpenDialog === 'call-off-agreement' && accessToken) {
      fetchCallOffOrderDetail(accessToken, callOffId);
      setCurrentStep(1);
    }
  }, [callOffAgreementState.callOffId, callOffAgreementState.callOffOrderDetail]);

  const resetValues = () => {
    callOffAgreementDispatch?.({ type: 'clear_call_off', value: {} });
    requestForQuotationDispatch?.({ type: 'set_quantity', value: { quantity: undefined } });
    setCallOffOrderDetail(undefined);
    setCustomer(undefined);
    setDeliveryAddress(undefined);
    setCurrentStep(1);
  };

  const updateStateForDynamicProductBlock = (updatedQuantity: Quantity) => {
    callOffAgreementDispatch?.({ type: 'set_call_off_additional_info', value: { additionalInfo: additionalInfo || '' } });
    requestForQuotationDispatch?.({ type: 'set_quantity', value: { quantity: { amount: updatedQuantity.amount, unitOfMeasure: updatedQuantity.unitOfMeasure } } });
  };

  const handleFlyoutClose = () => {
    if (openedFrom === 'dynamic-product-block' && quantity && currentStep !== 3) {
      updateStateForDynamicProductBlock(quantity);
    } else if (currentStep === 3 || callOffId) {
      resetValues();
    }
    flyoutDispatch?.({ type: 'close_flyout', value: { flyoutType: 'call-off-agreement', title: undefined } });
  };

  const handleDeliveryRequest = async () => {
    if (callOffOrderDetail && quantity && deliveryDate && accessToken) {
      const request: RequestDelivery = {
        callOffOrderId: callOffOrderDetail.id,
        quantity,
        productReference: callOffOrderDetail.customerProductReferences[0],
        orderReference: callOffOrderDetail.customerOrderReferences[0],
        deliveryDate,
        info: additionalInfo || '',
        packaging: callOffOrderDetail.packagings[0]
      };
      const response = await requestDelivery(accessToken, request);
      if (!response) {
        setRequestDeliveryError(true);
      }
      TrackingUtils.track('click_request_delivery_button', {
        callOffId: callOffOrderDetail.id,
        productId: callOffOrderDetail.product.id.toString(),
        productName: callOffOrderDetail.product.description
      });
      setCurrentStep(3);
    }
  };

  const handleTryAgain = () => {
    setRequestDeliveryError(false);
    setCurrentStep(2);
    handleFlyoutClose();
  };

  return (
    <Flyout position={phone ? 'bottom' : 'right'} width="100%" open={flyoutState.idOpenDialog === 'call-off-agreement'} closeOnOutsideClick onFlyoutClose={handleFlyoutClose}>
      {callOffOrderDetail ? (
        <Box direction="column" gap="md" spacing={{ top: 'none', right: 'md', bottom: 'md', left: 'md' }} overflowY="auto" height={currentStep === 2 ? 'calc(100% - 100px)' : '100%'}>
          <Box justify="between" items="center">
            <Typography component="span" variant="body-highlight" color="strong">
              {currentStep === 1 ? `${translate('page-consumer.call-off-agreement-flyout.call-off-agreement')} ${callOffOrderDetail.id}` : translate('page-consumer.call-off-agreement-flyout.request-delivery')}
            </Typography>
            <IconButton icon="times" onClick={handleFlyoutClose} />
          </Box>
          <Divider />
          {currentStep === 1 && (
            <Box direction="column">
              <CallOffProgressBar callOffOrderDetail={callOffOrderDetail} showLabel showSupportingText />
              <Box direction="column" gap="md">
                <Typography component="span" variant="body-highlight">
                  {translate('page-consumer.call-off-agreement-flyout.request-delivery')}
                </Typography>
                <CallOffOrderDeliveryRequest callOffOrderDetail={callOffOrderDetail} deliveryAddress={deliveryAddress} showDeliveryAddress={false} customer={customer} onClick={() => setCurrentStep(2)} />
              </Box>
              <Box direction="column" gap="md">
                <Box items="center">
                  <Avatar size="large" shape="square" src={callOffOrderDetail.contentfulProduct?.brand?.producer?.logo?.url} />
                  <Box direction="column" gap="none">
                    {callOffOrderDetail.contentfulProduct ? (
                      <DynamicPageLink
                        id={`dynamic-link__call-off-agreement__step-1__${callOffOrderDetail.contentfulProduct.slug}`}
                        locale={locale}
                        baseRoute={{
                          url: productsBaseRoute,
                          noFollow: callOffOrderDetail.contentfulProduct.seoMetadata?.noFollow
                        }}
                        slug={callOffOrderDetail.contentfulProduct.slug}
                      >
                        <Typography component="span" variant="body-highlight" color="primary">
                          {callOffOrderDetail.product.description}
                        </Typography>
                      </DynamicPageLink>
                    ) : (
                      <Typography component="span" variant="body-highlight" color="primary">
                        {callOffOrderDetail.product.description}
                      </Typography>
                    )}
                    {callOffOrderDetail.customerProductReferences?.length > 0 && (
                      <Typography component="span" variant="body">
                        {callOffOrderDetail.customerProductReferences.join(' • ')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <CallOffProductInfo translate={translate} callOffOrderDetail={callOffOrderDetail} showTotalQuantity />
              <Box gap="sm" width="100%">
                <SystemPageLink baseRoute={callOffAgreementBaseRoute} locale={locale} type={SystemPageType.CALL_OFF_ORDER_DETAIL} slug={callOffOrderDetail.id}>
                  <Button fullWidth variant="primary">
                    {translate('page-consumer.call-off-agreement-flyout.view-call-off')}
                  </Button>
                </SystemPageLink>
                <Link underline="none" style={{ width: '100%' }} href={`/my/${language}/my-prices`} prefetch={false}>
                  <Button fullWidth variant="secondary">
                    {translate('page-consumer.order-line-flyout.new-quote')}
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
          {currentStep === 2 && (
            <Box direction="column">
              <Box direction="column" gap="none">
                <Box items="center">
                  <Avatar size="large" shape="square" src={callOffOrderDetail.contentfulProduct?.brand?.producer?.logo?.url} />
                  <Box direction="column" gap="none">
                    {callOffOrderDetail.contentfulProduct ? (
                      <DynamicPageLink
                        id={`dynamic-link__call-off-agreement__step-2__${callOffOrderDetail.contentfulProduct.slug}`}
                        locale={locale}
                        baseRoute={{
                          url: productsBaseRoute,
                          noFollow: callOffOrderDetail.contentfulProduct.seoMetadata?.noFollow
                        }}
                        slug={callOffOrderDetail.contentfulProduct.slug}
                      >
                        <Typography component="span" variant="body-highlight" color="primary">
                          {callOffOrderDetail.product.description}
                        </Typography>
                      </DynamicPageLink>
                    ) : (
                      <Typography component="span" variant="body-highlight" color="primary">
                        {callOffOrderDetail.product.description}
                      </Typography>
                    )}
                    {callOffOrderDetail.customerProductReferences && (
                      <Typography component="span" variant="body">
                        {callOffOrderDetail.customerProductReferences.join(' • ')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box direction="column" gap="xl">
                <Box direction="column" gap="sm">
                  <Typography component="span" variant="body-highlight">
                    {translate('page-consumer.call-off-agreement-flyout.delivery-information')}
                  </Typography>
                  {deliveryDate && quantity && deliveryAddress && <CallOffDeliveryInfo translate={translate} deliveryAddress={deliveryAddress} deliveryDate={DateUtils.localizeDate(deliveryDate)} quantity={quantity} additionalInfo={additionalInfo || '-'} />}
                </Box>
                <Box direction="column" gap="sm">
                  <Typography component="span" variant="body-highlight">
                    {translate('page-consumer.call-off-agreement-flyout.product-information')}
                  </Typography>
                  <CallOffProductInfo translate={translate} callOffOrderDetail={callOffOrderDetail} />
                </Box>
                <Box direction="column" gap="sm">
                  <Typography component="span" variant="body-highlight">
                    {translate('page-consumer.call-off-agreement-flyout.contact-information')}
                  </Typography>
                  {user && customer && <CallOffContactInfo translate={translate} companyName={customer.name} email={user.sub} firstName={user.firstname} lastName={user.lastname} country={deliveryAddress?.countryName} />}
                </Box>
              </Box>
            </Box>
          )}
          {currentStep === 3 && (
            <Box direction="column">
              {requestDeliveryError ? (
                <Box height="60vh" width="auto" direction="column" justify="center" items="center" gap="xl" spacing={{ left: '3xl', right: '3xl' }}>
                  <Image src="/images/rfq-error.png" alt={translate('page-consumer.request-for-quotation.error')} title={translate('page-consumer.request-for-quotation.error')} objectFit="contain" />
                  <Typography align="center" component="h4" variant="heading-4" color="primary">
                    {translate('page-consumer.call-off-agreement-flyout.error')}
                  </Typography>
                  <Typography align="center" component="b" variant="body" color="strong">
                    {translate('page-consumer.call-off-agreement-flyout.error-message')}
                  </Typography>
                </Box>
              ) : (
                <Box height="60vh" width="auto" direction="column" justify="center" items="center" gap="xl" spacing={{ left: '3xl', right: '3xl' }}>
                  <Image src="/images/rfq-thank-you.png" alt={translate('page-consumer.request-for-quotation.thank-you')} title={translate('page-consumer.request-for-quotation.thank-you')} objectFit="contain" />
                  <Typography align="center" component="h4" variant="heading-4" color="primary">
                    {translate('page-consumer.call-off-agreement-flyout.thank-you')}
                  </Typography>
                  <Typography align="center" component="b" variant="body" color="strong">
                    {translate('page-consumer.call-off-agreement-flyout.thank-you-message')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {currentStep > 1 && (
            <Box position="absolute" insetX={0} bottom={0} items="center">
              <BottomSheet
                position="relative"
                header={
                  <Box gap="none" items="center">
                    {currentStep === 2 && (
                      <Box gap="xs" width="100%">
                        <Button variant="secondary" onClick={() => (callOffAgreementState.callOffId ? setCurrentStep(1) : handleFlyoutClose())} fullWidth leadingIcon={!phone ? 'long-arrow-left' : ''}>
                          {translate('page-consumer.call-off-agreement-flyout.back')}
                        </Button>
                        <Button variant="primary" onClick={handleDeliveryRequest} fullWidth trailingIcon={!phone ? 'long-arrow-right' : ''} disabled={user?.groups?.includes(UserGroups.ravagoGlobalViewer)}>
                          {translate('page-consumer.call-off-agreement-flyout.send-request')}
                        </Button>
                      </Box>
                    )}
                    {currentStep === 3 && (
                      <Box gap="xs" direction="column" width="100%">
                        {requestDeliveryError && (
                          <Button variant="primary" fullWidth onClick={handleTryAgain} trailingIcon={!phone ? 'refresh' : ''}>
                            {translate('page-consumer.call-off-agreement-flyout.try-again')}
                          </Button>
                        )}
                        <Button variant="secondary" fullWidth onClick={handleFlyoutClose}>
                          {translate('page-consumer.call-off-agreement-flyout.close')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                }
              />
            </Box>
          )}
        </Box>
      ) : (
        isLoading && (
          <Box direction="column" items="center">
            <Spinner />
          </Box>
        )
      )}
    </Flyout>
  );
};

export default CallOffAgreementFlyout;
