import { FC } from 'react';

import { Customer } from '@ravago/shared/page-data/models/features';
import { DataCy, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Avatar } from '@ravago/shared/radiance/components/avatar/components/Avatar/Avatar';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useAuthentication, UserGroups } from '@ravago/shared/authentication';

interface Props {
  isOpen: boolean;
  currentCustomer?: Customer;
  onClick?: () => void;
}

const AuthenticationButton: FC<Props> = ({ isOpen, currentCustomer, onClick }) => {
  const translate = useTranslations();

  const { user } = useAuthentication();

  const canViewCustomer =
    user && (user.groups.includes(UserGroups.customerPortal) || user.groups.includes(UserGroups.ravagoSalesRepresentative) || user.groups.includes(UserGroups.ravagoGlobalViewer) || user.groups.includes(UserGroups.ravagoGlobalEditor) || user.groups.includes(UserGroups.ravagoGlobalGroupAdmin));

  return (
    <>
      <Divider orientation="vertical" />
      <Box attributes={{ ...DataCy('authentication-button', user ? user.firstname : 'login') }} gap="sm" items="center" onClick={onClick}>
        <Avatar size="extra-small" variant="account" title={user ? `${user.firstname} ${user.lastname}` : undefined} fallback={!user ? <Icon icon="user" size="small-highlight" color="inherit" /> : undefined} shadow={false} />

        <BreakpointSwitch includeViews={['desktop', 'tab-land']}>
          {user ? (
            <Box direction="column" gap="none">
              <Typography>{user.firstname}</Typography>
              {currentCustomer && canViewCustomer && <Typography variant="small">{currentCustomer.name}</Typography>}
            </Box>
          ) : (
            <Typography>{translate('page-consumer.header.login')}</Typography>
          )}
        </BreakpointSwitch>

        <Icon color="primary" icon={isOpen ? 'chevron-up' : 'chevron-down'} />
      </Box>
    </>
  );
};

export default AuthenticationButton;
