import { FC } from 'react';

import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { CustomerProps, FlyoutProps } from '@ravago/shared/page-data/models/features';
import { ProductFlyoutColorDetails, ProductFlyoutProductTitle, useRequestForQuotationContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { RadioGroup } from '@ravago/shared/radiance/components/radio/components/RadioGroup/RadioGroup';
import { DotsStepper } from '@ravago/shared/radiance/components/stepper/components/DotsStepper/DotsStepper';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import RfqAddressPicker from '../fields/rfq-address-picker';
import RfqTimeframeOptions from '../fields/rfq-timeframe-options';

interface AddressDetailsProps extends FlyoutProps, CustomerProps {
  timeframe?: DropdownOption;
  onSetTimeframe: (timeframe: DropdownOption | undefined) => void;
  onToggleDeliveryAddress?: (expanded: boolean) => void;
}

const RfqFlyoutStepAddressDetails: FC<AddressDetailsProps> = ({ title, customer, timeframe, onSetTimeframe, isDeliveryAddressExpanded, customerDeliveryAddress, onToggleDeliveryAddress, handleAddressChange, phone, currentStep, onNextStep, onPreviousStep }) => {
  const translate = useTranslations();
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleDeliveryAddressExpand = (expanded: boolean) => {
    onToggleDeliveryAddress?.(expanded);
  };

  const handlePreviousStep = () => {
    onPreviousStep();
  };

  return (
    <>
      <Box direction={'column'}>
        <ProductFlyoutProductTitle title={title} translate={translate} />
        <ProductFlyoutColorDetails value={requestForQuotationState.color} />
        <RfqTimeframeOptions timeframe={timeframe} onSetTimeframe={onSetTimeframe} translate={translate} />

        {customer?.addresses && (
          <Box gap={'md'} direction={'column'}>
            <Box direction="row" gap="2xs" items="center">
              <Typography component="span" variant="body-highlight">
                {translate('page-consumer.request-for-quotation.delivery')}
              </Typography>
              <Tooltip align="start" pointers text={translate('page-consumer.request-for-quotation.tooltip.delivery')}>
                <Box>
                  <Icon icon="info-circle" />
                </Box>
              </Tooltip>
            </Box>
            <Box gap="md" direction="column">
              <RadioGroup gap="md" name="customer-delivery-address" value={customerDeliveryAddress} onChange={handleAddressChange}>
                {(isDeliveryAddressExpanded
                  ? customer.addresses
                  : customer.addresses.filter((address: Address) => {
                      if (customerDeliveryAddress) {
                        return address.id === customerDeliveryAddress.id;
                      }
                      return address.isDefault;
                    })
                )
                  .filter((address: Address) => address.type === 'Delivery Address')
                  .map((address: Address) => (
                    <RfqAddressPicker key={address.id} address={address} selectedAddress={customerDeliveryAddress} translate={translate} />
                  ))}
              </RadioGroup>
              <Button variant="tertiary" fullWidth onClick={() => handleDeliveryAddressExpand(!isDeliveryAddressExpanded)} trailingIcon={isDeliveryAddressExpanded ? 'chevron-up' : 'chevron-down'}>
                {isDeliveryAddressExpanded ? translate('page-consumer.request-for-quotation.show-less') : translate('page-consumer.request-for-quotation.show-more')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" items="center" width="100%">
                <Button variant="secondary" onClick={() => handlePreviousStep()} leadingIcon={!phone ? 'long-arrow-left' : ''} fullWidth id="rfq-back-to-quantity-btn">
                  {translate('page-consumer.request-for-quotation.quantity')}
                </Button>
                <Button disabled={!timeframe || !customerDeliveryAddress} variant="primary" onClick={onNextStep} trailingIcon={!phone ? 'long-arrow-right' : ''} fullWidth id="rfq-address-details-summary-btn">
                  {translate('page-consumer.request-for-quotation.summary')}
                </Button>
              </Box>
              {!requestForQuotationState.userPermissions?.hasGuestPermission && !requestForQuotationState.userPermissions?.hasPendingPermission && <DotsStepper readOnly numberOfSteps={3} currentStep={currentStep} />}
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default RfqFlyoutStepAddressDetails;
