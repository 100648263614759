import { FC } from 'react';

import { User, UserGroups } from '@ravago/shared/authentication';
import { AdditionalInfoProps, ContactDetails, CustomerProps, DeliveryArea, FlyoutProps, PackagingProps } from '@ravago/shared/page-data/models/features';
import { ProductFlyoutColorDetails, ProductFlyoutProductTitle, TrackingUtils, useRequestForQuotationContext, UserUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { DotsStepper } from '@ravago/shared/radiance/components/stepper/components/DotsStepper/DotsStepper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import RfqContactInfo from '../fields/rfq-contact-info';
import RfqDeliveryInfo from '../fields/rfq-delivery-info';
import RfqProductDetails from '../fields/rfq-product-details';

interface SummaryProps extends FlyoutProps, AdditionalInfoProps, CustomerProps, PackagingProps {
  user?: User;
  requestQuote: () => Promise<void>;
  quantities: number[];
  uom: string;
  contactDetails: ContactDetails;
  deliveryArea: DeliveryArea;
  timeframe?: DropdownOption;
  selectedPackagingGroup?: DropdownOption;
}

const RfqFlyoutStepSummary: FC<SummaryProps> = ({
  title,
  phone,
  currentStep,
  onPreviousStep,
  user,
  requestQuote,
  quantities,
  uom,
  customer,
  timeframe,
  selectedPackagingGroup,
  customerDeliveryAddress,
  otherPackagingDescription,
  additionalInfo,
  deliveryArea,
  contactDetails,
  handleAdditionalInfoChange
}) => {
  const translate = useTranslations();
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleQuoteRequest = () => {
    requestQuote().then(() => {
      TrackingUtils.track('rfq_click_request_quote_flyout', {
        productName: requestForQuotationState.product?.description,
        productId: requestForQuotationState.product?.id,
        userLoggedIn: JSON.stringify(!!user),
        userType: UserUtils.getUserType(user)
      });
    });
  };

  return (
    <>
      <Box direction="column">
        <ProductFlyoutProductTitle title={title} translate={translate} />
        <ProductFlyoutColorDetails value={requestForQuotationState.color} />
        <Box direction="column" gap="sm">
          <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.additional-information')}
          </Typography>
          <InputField label={translate('page-consumer.request-for-quotation.optional')} id="rfq-additional-information" supportText={translate('page-consumer.request-for-quotation.additional-information-support')} maxLength={250} value={additionalInfo} onChange={handleAdditionalInfoChange} />
        </Box>
        <Box direction="column" gap="sm">
          <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.product-details')}
          </Typography>
          <RfqProductDetails translate={translate} quantities={quantities} uom={uom} packaging={selectedPackagingGroup?.value} otherPackagingDescription={otherPackagingDescription} />
        </Box>
        <Box direction="column" gap="sm">
          <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.your-contact-information')}
          </Typography>
          {requestForQuotationState.userPermissions?.hasVerifiedPermission && user && customer ? (
            <RfqContactInfo translate={translate} email={user.sub} firstName={user.firstname} lastName={user.lastname} company={customer.name} country={customerDeliveryAddress?.countryName || '-'} />
          ) : (
            <RfqContactInfo
              translate={translate}
              email={contactDetails.email}
              firstName={contactDetails.firstName}
              lastName={contactDetails.lastName}
              company={contactDetails.companyName}
              country={contactDetails.country.value || '-'}
              phone={contactDetails.phone.phone ? `+${contactDetails.phone.countryInfo?.prefix} ${contactDetails.phone.phone}` : '-'}
            />
          )}
        </Box>
        <Box direction="column" gap="sm">
          <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.your-delivery-information')}
          </Typography>
          <RfqDeliveryInfo translate={translate} isVerified={requestForQuotationState.userPermissions?.hasVerifiedPermission} address={customerDeliveryAddress?.htmlFormat} deliveryArea={deliveryArea} timeframe={timeframe?.value} />
        </Box>
      </Box>

      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Box gap="xs" items="center" width="100%">
                <Button variant="secondary" onClick={onPreviousStep} leadingIcon={!phone ? 'long-arrow-left' : ''} fullWidth>
                  {translate('page-consumer.request-for-quotation.delivery')}
                </Button>
                <Button variant="primary" onClick={handleQuoteRequest} trailingIcon={!phone ? 'long-arrow-right' : ''} disabled={user?.groups?.includes(UserGroups.ravagoGlobalViewer)} fullWidth>
                  {translate('page-consumer.request-for-quotation.request-quote')}
                </Button>
              </Box>
              {requestForQuotationState.userPermissions?.hasVerifiedPermission && <DotsStepper readOnly numberOfSteps={3} currentStep={currentStep} />}
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default RfqFlyoutStepSummary;
