'use client';

import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { useBodyOverflowHidden } from '../../../../hooks/useBodyOverflowHidden/useBodyOverflowHidden';
import { FabButton } from '../FabButton/FabButton';

interface Props extends PropsWithChildren {
   badge?: string;
   closeOnOutsideClick?: boolean;
   container?: boolean;
   divided?: boolean;
   label?: string;
   leadingIcon?: string;
   open?: boolean;
   onClick?: () => void;
   onClose?: () => void;
   onOpen?: () => void;
   onToggle?: (open: boolean) => void;
}

export const FabMenu: FC<Props> = ({ badge, closeOnOutsideClick = true, container = true, divided = true, label, leadingIcon, open = false, onClick, onClose, onOpen, onToggle, children }) => {
   const [isOpen, setIsOpen] = useState(open);

   const actionsRef = useRef<HTMLDivElement>(null);
   const buttonRef = useRef<HTMLDivElement>(null);

   useBodyOverflowHidden(isOpen);

   /* istanbul ignore next */
   const handleOpen = () => {
      setIsOpen(true);
      onOpen?.();
      onToggle?.(true);
   };

   /* istanbul ignore next */
   const handleClose = () => {
      setIsOpen(false);
      onClose?.();
      onToggle?.(false);
   };

   useEffect(() => setIsOpen(open), [open]);

   useEffect(() => {
      /* istanbul ignore next */
      const listenForOutsideClick = (event: MouseEvent) => {
         if (!isOpen || !closeOnOutsideClick) return;

         const actionClicked = actionsRef.current?.children && Array.from(actionsRef.current?.children).some((action) => action.contains(event.target as Node));
         const buttonClicked = buttonRef.current?.contains(event.target as Node);

         if (!actionClicked && !buttonClicked) handleClose();
      };

      document.addEventListener('mousedown', listenForOutsideClick);

      return () => {
         document.removeEventListener('mousedown', listenForOutsideClick);
      };
   }, [closeOnOutsideClick, isOpen]);

   return (
      <>
         <div data-testid="fab-menu-backdrop" className={`fixed inset-0 transition-colors ${isOpen ? 'bg-component-input-fab-container-backdrop-color-background z-50' : 'pointer-events-none -z-10 bg-transparent'}`} />
         <div
            data-testid={`fab-menu${isOpen ? '--open' : ''}${!container ? '--no-container' : ''}`}
            className={classNames(
               'gap-component-input-fab-container-container-spacing-gap flex flex-col items-end justify-end bottom-component-input-fab-container-container-spacing-padding-desktop phone:bottom-component-input-fab-container-container-spacing-padding-mobile tab-port:bottom-component-input-fab-container-container-spacing-padding-mobile tab-land:bottom-component-input-fab-container-container-spacing-padding-mobile relative z-[60]',
               { 'container inset-x-0': container },
               {
                  'right-component-input-fab-container-container-spacing-padding-desktop phone:right-component-input-fab-container-container-spacing-padding-mobile tab-port:right-component-input-fab-container-container-spacing-padding-mobile tab-land:right-component-input-fab-container-container-spacing-padding-mobile':
                     !container
               },
               { 'pointer-events-none': open }
            )}
         >
            {isOpen && (
               <div ref={actionsRef} data-testid="fab-menu--actions" className="gap-component-input-fab-container-actions-spacing-gap pr-component-input-fab-container-actions-spacing-padding-right pointer-events-auto absolute bottom-[80px] flex w-fit flex-col items-end transition-all">
                  {children}
               </div>
            )}

            <div ref={buttonRef} className="pointer-events-auto absolute bottom-0">
               <FabButton open={isOpen} leadingIcon={leadingIcon} divided={divided} onClick={onClick} onClose={handleClose} onOpen={handleOpen} badge={badge}>
                  {label}
               </FabButton>
            </div>
         </div>
      </>
   );
};
