import { FC } from 'react';

import { User } from '@ravago/shared/authentication';
import { ErrorProps, FlyoutProps, PackagingProps, QuantityProps } from '@ravago/shared/page-data/models/features';
import { ProductFlyoutColorDetails, ProductFlyoutProductTitle, TrackingUtils, useRequestForQuotationContext, UserUtils, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Dropdown } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { DotsStepper } from '@ravago/shared/radiance/components/stepper/components/DotsStepper/DotsStepper';
import { Sublevel } from '@ravago/shared/radiance/components/sublevel/components/Sublevel/Sublevel';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface QuotationDetailsProps extends FlyoutProps, QuantityProps, PackagingProps, ErrorProps {
  uom: string;
  user: User | undefined;
}

const RfqFlyoutStepQuotationDetails: FC<QuotationDetailsProps> = ({
  title,
  packagingOptions,
  hasError,
  errorText,
  selectedPackagingGroup,
  quantities,
  uom,
  removeQuantity,
  newQuantity,
  addQuantity,
  handleNewQuantityChange,
  otherPackagingDescription,
  handleOtherPackagingDescriptionChange,
  handlePackagingChange,
  currentStep,
  onNextStep,
  user
}) => {
  const translate = useTranslations();
  const { state: requestForQuotationState } = useRequestForQuotationContext();

  const handleNextStep = () => {
    TrackingUtils.track('rfq_click_continue_flyout', {
      productName: requestForQuotationState.product?.description,
      productId: requestForQuotationState.product?.id,
      userLoggedIn: JSON.stringify(!!user),
      userType: UserUtils.getUserType(user)
    });
    onNextStep();
  };

  return (
    <>
      <Box direction="column">
        <ProductFlyoutProductTitle title={title} translate={translate} />

        <ProductFlyoutColorDetails value={requestForQuotationState.color} />

        <Box direction="column" gap="md">
          <Typography component="span" variant="body-highlight">
            {translate('page-consumer.request-for-quotation.packaging')}
          </Typography>
          <Dropdown options={packagingOptions} id="rfq-packaging-dropdown" label={translate('page-consumer.request-for-quotation.your-packaging-preferences')} value={selectedPackagingGroup} onChange={handlePackagingChange} emptyOption={false} />
          {selectedPackagingGroup?.value === 'Other' && (
            <Sublevel>
              <InputField
                label={translate('page-consumer.request-for-quotation.describe-packaging-details')}
                supportText={translate('page-consumer.request-for-quotation.other-packaging-description')}
                id="rfq-other-packaging-description"
                value={otherPackagingDescription}
                onChange={handleOtherPackagingDescriptionChange}
              />
            </Sublevel>
          )}
        </Box>

        <Box gap="md" direction="column">
          <Box direction="row" gap="2xs" items="center">
            <Typography component="span" variant="body-highlight">
              {translate('page-consumer.request-for-quotation.quantity')}
            </Typography>
            <Tooltip align="start" pointers text={translate('page-consumer.request-for-quotation.tooltip.quantity')}>
              <Box>
                <Icon icon="info-circle" />
              </Box>
            </Tooltip>
          </Box>
          {quantities.map((quantity, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box direction="row" gap="3xs" justify="between" items="center" key={index}>
              <Typography component="span">
                {quantity} {uom}
              </Typography>
              <IconButton variant="danger" icon="trash" onClick={() => removeQuantity(index)} />
            </Box>
          ))}

          {quantities.length < 5 && (
            <InputField type="number" label={translate('page-consumer.request-for-quotation.enter-quantity')} id="rfq-add-quantity" suffix={uom} value={newQuantity} min={1} max={999999999} error={hasError} errorMessage={errorText} onChange={handleNewQuantityChange} onEnter={addQuantity} />
          )}
        </Box>

        <Button variant="secondary" leadingIcon={quantities.length === 5 ? 'cancel' : 'plus'} onClick={addQuantity} disabled={quantities.length === 5} id="rfq-flyout-add-quantity-btn">
          {quantities.length === 5 ? translate('page-consumer.request-for-quotation.max-quantity-breaks-added') : translate('page-consumer.request-for-quotation.add-quantity')}
        </Button>
      </Box>

      <Box position="absolute" gap="none" items="center" insetX={0} bottom={0}>
        <BottomSheet
          position="relative"
          header={
            <Box direction="column" justify="center" items="center" height="80px">
              <Button disabled={quantities.length === 0 || !selectedPackagingGroup || !requestForQuotationState.color} fullWidth variant="primary" onClick={handleNextStep} trailingIcon="long-arrow-right" id="rfq-quantity-continue-btn">
                {quantities.length === 0 ? translate('page-consumer.request-for-quotation.enter-quantity-to-continue') : translate('page-consumer.request-for-quotation.continue')}
              </Button>
              {requestForQuotationState.userPermissions?.hasVerifiedPermission && <DotsStepper readOnly numberOfSteps={3} currentStep={currentStep} />}
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default RfqFlyoutStepQuotationDetails;
