'use client';

import { FC, useCallback, useMemo } from 'react';

import { BusinessContext, NavigationDropdown, NavigationItem } from '@ravago/shared/page-data/models/config';
import { DataCy, useConfigContext } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { NavItem } from '@ravago/shared/radiance/components/navigation/components/NavItem/NavItem';
import { NavTab } from '@ravago/shared/radiance/components/navigation/components/NavTab/NavTab';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useAuthentication } from '@ravago/shared/authentication';

import { usePathname } from 'next/navigation';

import { NavigationUtils } from '../../../../utils/navigation.utils';

interface Props {
  businessContext: BusinessContext;
  locale: string;
  currentDropdown?: NavigationDropdown;
  onNavigate?: () => void;
  onDropdownClick?: (id: string) => void;
}

const Navigation: FC<Props> = ({ businessContext, locale, onNavigate, onDropdownClick, currentDropdown }) => {
  const pathname = usePathname();

  const { user } = useAuthentication();
  const { okta, linkLegacyDigitalPlatform, isPublic } = useConfigContext();

  const rlpHref = businessContext.registrationLandingPage?.page ? `/${locale}/${businessContext.registrationLandingPage.page.route ?? ''}` : businessContext.registrationLandingPage?.externalUrl ?? '';

  const colorIndex = businessContext.header?.navigationItemsCollection.items.findIndex((item) => 'sys' in item && item.sys.id === currentDropdown?.sys?.id) ?? 0;

  const getSubRoutes = (item: NavigationDropdown) =>
    item.itemsCollection.items.flatMap((subItem) => {
      if ('itemsCollection' in subItem) {
        return subItem.itemsCollection.items.map((subSubItem) => NavigationUtils.buildRoute(locale, subSubItem));
      }

      return [NavigationUtils.buildRoute(locale, subItem)];
    });

  const filteredNavItemsAndDropdowns = useMemo(() => NavigationUtils.filterItemsByAccessLevel(businessContext.header?.navigationItemsCollection.items || [], user), [businessContext, user]);

  const filteredNavItemsAndGroups = useMemo(() => (currentDropdown ? NavigationUtils.filterItemsByAccessLevel(currentDropdown.itemsCollection.items, user) : []), [currentDropdown, businessContext, user]);

  const dropdownActive = useCallback((item: NavigationDropdown) => item.sys.id === currentDropdown?.sys?.id || getSubRoutes(item).includes(pathname), [currentDropdown, pathname]);

  return (
    <>
      <BreakpointSwitch includeViews={['desktop']}>
        <Box gap="none" height="100%">
          {filteredNavItemsAndDropdowns.map((item) =>
            'itemsCollection' in item ? (
              <NavTab
                attributes={{ ...DataCy('navigation-tab', item.label) }}
                height="calc(100% + var(--component-navigation-tab-container-border-width-active) - 1px)"
                key={(item as NavigationDropdown).sys.id}
                trailingIcon={(item as NavigationDropdown).sys.id === currentDropdown?.sys?.id ? 'chevron-up' : 'chevron-down'}
                active={dropdownActive(item as NavigationDropdown)}
                onClick={() => onDropdownClick?.((item as NavigationDropdown).sys.id)}
              >
                {item.label}
              </NavTab>
            ) : (
              <NavTab
                attributes={{ ...DataCy('navigation-tab', item.label) }}
                height="calc(100% + var(--component-navigation-tab-container-border-width-active) - 1px)"
                key={item.page?.route ?? item.externalUrl ?? 'current'}
                href={NavigationUtils.buildRoute(locale, item)}
                trailingIcon={item.openInNewTab ? 'external-link' : undefined}
                target={item.openInNewTab ? '_blank' : undefined}
                onClick={onNavigate}
                active={NavigationUtils.buildRoute(locale, item) === pathname}
              >
                {item.label}
              </NavTab>
            )
          )}
        </Box>
      </BreakpointSwitch>
      <BreakpointSwitch includeViews={['phone', 'tab-port', 'tab-land']}>
        {!currentDropdown && (
          <Box direction="column" height="100%" maxHeight="100%" justify="start" gap="sm" overflowY="auto">
            {filteredNavItemsAndDropdowns.map((item, index) =>
              'itemsCollection' in item ? (
                <NavItem attributes={{ ...DataCy('navigation-item', item.label) }} key={(item as NavigationDropdown).sys.id} size="lg" color={index} onClick={() => onDropdownClick?.((item as NavigationDropdown).sys.id)} icon={item.icon} trailingIcon="chevron-right" fullWidth>
                  {item.label}
                </NavItem>
              ) : (
                <NavItem
                  attributes={{ ...DataCy('navigation-item', item.label) }}
                  href={NavigationUtils.buildRoute(locale, item)}
                  key={item.page?.route ?? item.externalUrl ?? ''}
                  size="lg"
                  color={index}
                  target={item.openInNewTab ? '_blank' : undefined}
                  onClick={onNavigate}
                  icon={item.icon}
                  trailingIcon={item.openInNewTab ? 'external-link' : undefined}
                >
                  {item.label}
                </NavItem>
              )
            )}

            {!user && linkLegacyDigitalPlatform && okta && !isPublic && businessContext.registrationLandingPage?.page && (
              <NavItem
                attributes={{ ...DataCy('navigation-registration-link', businessContext.registrationLandingPage.label) }}
                href={rlpHref}
                size="lg"
                icon={businessContext.registrationLandingPage.icon}
                color={businessContext.header?.navigationItemsCollection.items.length || 0}
                target={businessContext.registrationLandingPage.openInNewTab ? '_blank' : undefined}
                onClick={onNavigate}
                trailingIcon={businessContext.registrationLandingPage.openInNewTab ? 'external-link' : undefined}
              >
                {businessContext.registrationLandingPage.label}
              </NavItem>
            )}
          </Box>
        )}

        {currentDropdown && (
          <Box direction="column" height="100%" width="100%" gap="md" overflowY="auto">
            <Box height="100%" width="100%" overflowY="auto" direction="column" gap="lg">
              {filteredNavItemsAndGroups.map((item) =>
                'itemsCollection' in item ? (
                  <Box key={item.label} gap="sm" direction="column" width="100%">
                    <Typography variant="body-highlight">{item.label}</Typography>
                    <Box key={item.label} gap="xs" direction="column">
                      {NavigationUtils.filterItemsByAccessLevel(item.itemsCollection.items, user).map((subItem) => (
                        <NavItem
                          attributes={{ ...DataCy('navigation-item', (subItem as NavigationItem).label) }}
                          href={NavigationUtils.buildRoute(locale, subItem)}
                          key={(subItem as NavigationItem).label}
                          color={colorIndex}
                          target={(subItem as NavigationItem).openInNewTab ? '_blank' : undefined}
                          onClick={onNavigate}
                          icon={(subItem as NavigationItem).icon}
                          trailingIcon={(subItem as NavigationItem).openInNewTab ? 'external-link' : undefined}
                        >
                          <Typography variant="body-highlight" color="strong">
                            {(subItem as NavigationItem).label}
                          </Typography>
                          {(subItem as NavigationItem).description && (
                            <Typography variant="small" color="subtle">
                              {(subItem as NavigationItem).description}
                            </Typography>
                          )}
                        </NavItem>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <NavItem
                    attributes={{ ...DataCy('navigation-item', item.label) }}
                    href={item.page?.route ? `/${locale}/${item.page.route}` : item.externalUrl ?? ''}
                    key={item.label}
                    target={item.openInNewTab ? '_blank' : undefined}
                    color={colorIndex}
                    onClick={onNavigate}
                    icon={item.icon}
                    trailingIcon={item.openInNewTab ? 'external-link' : undefined}
                  >
                    <Typography variant="body-highlight" color="strong">
                      {item.label}
                    </Typography>
                    {item.description && (
                      <Typography variant="small" color="subtle">
                        {item.description}
                      </Typography>
                    )}
                  </NavItem>
                )
              )}
            </Box>
          </Box>
        )}
      </BreakpointSwitch>
    </>
  );
};

export default Navigation;
