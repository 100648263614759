'use client';

import { FC, HTMLAttributeAnchorTarget, useMemo } from 'react';

import { Customer } from '@ravago/shared/page-data/models/features';
import { DataCy, useConfigContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Alert } from '@ravago/shared/radiance/components/alert/components/Alert/Alert';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { NavItem } from '@ravago/shared/radiance/components/navigation/components/NavItem/NavItem';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import { useAuthentication, UserGroups } from '@ravago/shared/authentication';

import { Features } from '../../../../models/config/feature.model';
import NavigationService from '../../../../services/navigation/navigation.service';

interface ProfileNavItem {
  key: string;
  label: string;
  href?: string;
  leadingIcon: string;
  target?: HTMLAttributeAnchorTarget;
}

interface Props {
  locale: string;
  features: Features;
  adminPlatformUrl?: string;
  currentCustomer?: Customer;
  isFirstLogin?: boolean;
  myOrdersUrl?: string;
  myInvoicesUrl?: string;
  myRegistrationNumbersUrl?: string;
  myVatNumbersUrl?: string;
  myAddressesUrl?: string;
  onClick?: () => void;
  onTechKnowledgeBaseClick?: () => void;
  onCustomerSwitchClick?: () => void;
  onFirstLoginDismiss?: () => void;
}

export const ProfileMenu: FC<Props> = ({ locale, features, adminPlatformUrl, currentCustomer, isFirstLogin, myOrdersUrl, myInvoicesUrl, myRegistrationNumbersUrl, myAddressesUrl, myVatNumbersUrl, onClick, onTechKnowledgeBaseClick, onCustomerSwitchClick, onFirstLoginDismiss }) => {
  const translate = useTranslations();

  const { phone } = useBreakpoint();

  const { user, logout } = useAuthentication();

  const { academySSO } = useConfigContext();

  const isGuestOrPending = user && (user.groups.includes(UserGroups.digitalPlatformGuest) || user.groups.includes(UserGroups.digitalPlatformPendingVerification));
  const isSalesRep = user && user.groups.includes(UserGroups.ravagoSalesRepresentative);
  const canSwitchCustomer = user && (user.groups.includes(UserGroups.ravagoSalesRepresentative) || user.groups.includes(UserGroups.ravagoGlobalViewer) || user.groups.includes(UserGroups.ravagoGlobalEditor) || user.groups.includes(UserGroups.ravagoGlobalGroupAdmin));

  const handleClick = (item: ProfileNavItem) => {
    if (item.key === 'tech-knowledge-base') onTechKnowledgeBaseClick?.();

    onClick?.();
  };

  const items = useMemo(() => {
    const baseItems: Array<ProfileNavItem> = [];

    if (features['my-prices'] && features['limited-my-prices']) {
      baseItems.push({
        key: 'my-products',
        label: translate('page-consumer.header.account.my-products'),
        href: `/my/${NavigationService.getLanguage(locale)}/my-prices`,
        leadingIcon: 'briefcase'
      });
    }

    if (features['my-prices'] && !features['limited-my-prices']) {
      baseItems.push({
        key: 'my-products-and-prices',
        label: translate('page-consumer.header.account.my-products-and-prices'),
        href: `/my/${NavigationService.getLanguage(locale)}/my-prices`,
        leadingIcon: 'tags'
      });
    }

    if (features['public-order-redesign-redirect'] !== undefined) {
      baseItems.push({
        key: 'my-orders',
        label: translate('page-consumer.header.account.my-orders'),
        href: features['public-order-redesign-redirect'] ? `/${locale}/${myOrdersUrl}` : `/my/${NavigationService.getLanguage(locale)}/my-orders`,
        leadingIcon: 'box-alt'
      });
    }

    if (features['my-invoices']) {
      baseItems.push({
        key: 'my-profile-invoices',
        label: translate('page-consumer.header.account.my-invoices'),
        leadingIcon: 'file-invoice',
        href: `/${locale}/${myInvoicesUrl}`
      });
    }

    if (features['tech-knowledge-base']) {
      baseItems.push({
        key: 'tech-knowledge-base',
        label: translate('page-consumer.header.account.tech-knowledge-base'),
        leadingIcon: 'user-hard-hat'
      });
    }

    if (!isGuestOrPending) {
      baseItems.push({
        key: 'my-profile-addresses',
        label: translate('page-consumer.header.account.addresses'),
        leadingIcon: 'location-dot',
        href: features['public-addresses-redirect'] ? `/${locale}/${myAddressesUrl}` : `/my/${NavigationService.getLanguage(locale)}/my-profile/addresses`
      });

      baseItems.push({
        key: 'my-profile-registration-numbers',
        label: translate('page-consumer.header.account.registration-numbers'),
        leadingIcon: 'money-check-pen',
        href: features['public-registration-numbers-redirect'] ? `/${locale}/${myRegistrationNumbersUrl}` : `/my/${NavigationService.getLanguage(locale)}/my-profile/registration-numbers`
      });

      if (features['vat-info']) {
        baseItems.push({
          key: 'my-profile-vat-numbers',
          label: translate('page-consumer.header.account.vat-numbers'),
          leadingIcon: 'scale-balanced',
          href: features['public-vat-numbers-redirect'] ? `/${locale}/${myVatNumbersUrl}` : `/my/${NavigationService.getLanguage(locale)}/my-profile/vat-numbers`
        });
      }

      baseItems.push({
        key: 'my-profile-portal-users',
        label: translate('page-consumer.header.account.portal-users'),
        leadingIcon: 'users',
        href: `/my/${NavigationService.getLanguage(locale)}/my-profile/portal-users`
      });
    }

    if (!isGuestOrPending && isSalesRep && adminPlatformUrl) {
      baseItems.push({
        key: 'admin-portal',
        label: translate('page-consumer.header.account.admin-portal'),
        leadingIcon: 'user-shield',
        href: adminPlatformUrl,
        target: '_blank'
      });
    }

    if (
      features['ravago-academy'] &&
      academySSO &&
      !user?.groups.includes(UserGroups.digitalPlatformPendingVerification) &&
      !user?.groups.includes(UserGroups.ravagoSalesRepresentative) &&
      !user?.groups.includes(UserGroups.ravagoGlobalViewer) &&
      !user?.groups.includes(UserGroups.ravagoGlobalEditor) &&
      !user?.groups.includes(UserGroups.ravagoGlobalGroupAdmin)
    ) {
      baseItems.push({
        key: 'academy',
        label: translate('page-consumer.header.account.academy'),
        leadingIcon: 'book',
        href: academySSO,
        target: '_blank'
      });
    }

    return baseItems;
  }, [translate, locale, features, academySSO, isGuestOrPending, isSalesRep, myOrdersUrl]);

  const handleSignOut = () => {
    onClick?.();
    logout();
  };

  const handleCustomerSwitchClick = () => {
    onCustomerSwitchClick?.();
    onClick?.();
  };

  if (!user) return null;

  return (
    <Box gap="sm" spacing={{ x: !phone ? 'sm' : 'none' }} direction="column" width={!phone ? '408px' : '100%'} height="100%" maxHeight={!phone ? '70vh' : '100vh'} overflowY="auto">
      <Box height="100%" overflowY="auto" gap="sm" direction="column">
        {isFirstLogin && (
          <Alert headline={translate('page-consumer.header.first-login.header')} variant="info" closable onClose={onFirstLoginDismiss}>
            {translate('page-consumer.header.first-login.info')}
          </Alert>
        )}

        {items.map((item) => (
          <NavItem attributes={{ ...DataCy('profile-menu-item', item.label) }} key={item.key} color={3} size="md" icon={item.leadingIcon} href={item.href} target={item.target} onClick={() => handleClick(item)}>
            {item.label}
          </NavItem>
        ))}
      </Box>
      <Divider />
      {canSwitchCustomer && (
        <NavItem attributes={{ ...DataCy('profile-menu-item-switch-customer') }} size="md" icon="repeat" color={4} onClick={handleCustomerSwitchClick}>
          <Typography variant="body-highlight">{translate('page-consumer.header.account.switch-customer')}</Typography>
          {currentCustomer && <Typography variant="small">{currentCustomer.name}</Typography>}
        </NavItem>
      )}
      <NavItem attributes={{ ...DataCy('profile-menu-item-sign-out') }} size="md" icon="sign-out" color={4} onClick={handleSignOut}>
        {translate('page-consumer.header.account.sign-out')}
      </NavItem>
    </Box>
  );
};

export default ProfileMenu;
