import { FC } from 'react';

import { LocalizedRoutes } from '@ravago/shared/page-data/models/pages';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { Country, Language } from '../../../../services/contentful/locale.service';
import CountrySwitch from '../../country-switch/country-switch';
import LanguageSwitch from '../../language-switch/language-switch';

interface Props {
  locale: string;
  availableCountries: Array<Country>;
  availableLanguages: Array<Language>;
  localizedRoutes?: LocalizedRoutes;
}

const CountryLanguageMenu: FC<Props> = ({ locale, availableCountries, availableLanguages, localizedRoutes }) => {
  const { phone, tabPort } = useBreakpoint();

  return (
    <Box gap="xl" spacing={{ x: !phone && !tabPort ? 'lg' : 'none' }} direction="column" width={!phone && !tabPort ? '408px' : '100%'} height="100%">
      {availableCountries.length > 1 && <CountrySwitch locale={locale} countries={availableCountries} />}
      {availableLanguages.length > 1 && <LanguageSwitch locale={locale} languages={availableLanguages} localizedRoutes={localizedRoutes} />}
    </Box>
  );
};

export default CountryLanguageMenu;
