'use client';

import { FC, useState } from 'react';

import { useDialogContext, useDialogFormContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Dialog } from '@ravago/shared/radiance/components/dialog/components/Dialog/Dialog';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputArea } from '@ravago/shared/radiance/components/input-field/components/InputArea/InputArea';

interface Props {
  open: boolean;
  title?: string;
}

const RequestMissingDocumentsForm: FC<Props> = ({ open, title }: Props) => {
  const translate = useTranslations();
  const [comment, setComment] = useState<string>('');
  const [category, setCategory] = useState<DropdownOption | undefined>(undefined);
  const { state, dispatch } = useDialogContext();
  const { setFeedback } = useDialogFormContext();

  const handleOnCommentChange = (value: string) => {
    setComment(value);
  };

  const handleOnCategoryChange = (option?: DropdownOption) => {
    if (option) setCategory(option);
  };

  const handleCloseDialog = () => {
    if (dispatch) {
      dispatch({ type: 'close_dialog' });
      setCategory(undefined);
      setComment('');
    }
  };

  const sendRequestDocumentForm = () => {
    if (dispatch && category && comment) {
      setFeedback({ feedback: comment, category });
      handleCloseDialog();
    }
  };

  return (
    <Dialog
      open={open}
      title={translate('page-consumer.request-missing-documents-form.title', { productName: title ?? '' })}
      actions={
        <>
          <Button onClick={() => handleCloseDialog()} variant="secondary">
            {translate('page-consumer.request-missing-documents-form.cancel')}
          </Button>
          <Button disabled={!category || !comment} onClick={() => sendRequestDocumentForm()} variant="primary">
            {translate('page-consumer.request-missing-documents-form.submit-request')}
          </Button>
        </>
      }
      onDialogClose={() => handleCloseDialog()}
      closeOnOutsideClick={false}
      contentOverflowX="visible"
      contentOverflowY="visible"
      dialogBodyOverflowHidden={false}
    >
      <Box direction="column" spacing={{ top: 'md' }} minWidth={'560px'}>
        <Dropdown
          emptyOption={false}
          label={translate('page-consumer.request-missing-documents-form.category')}
          disabled={!state.requestDocumentFormCategories}
          error={!state.requestDocumentFormCategories}
          errorMessage={!state.requestDocumentFormCategories ? translate('page-consumer.request-missing-documents-form.categories-failed-to-load') : ''}
          id="'form-category-dropdown'"
          value={category}
          options={state.requestDocumentFormCategories ?? []}
          onChange={handleOnCategoryChange}
        />
        <Box direction="column" maxHeight={'400px'} overflowY={'hidden'} spacing={{ top: '2xs' }}>
          <InputArea id="'form-details-input'" value={comment} label={translate('page-consumer.request-missing-documents-form.details')} onChange={handleOnCommentChange} />
        </Box>
      </Box>
    </Dialog>
  );
};

export default RequestMissingDocumentsForm;
