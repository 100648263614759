'use client';

import { FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { OrderLineContext } from '../context/order-line-context';
import { OrderLineReducer, initialState } from '../reducers/order-line-reducer';

export const OrderLineProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(OrderLineReducer, initialState);

   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

   return <OrderLineContext.Provider value={contextValue}>{children}</OrderLineContext.Provider>;
};
