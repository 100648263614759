'use client';

import { PropsWithChildren, useEffect } from 'react';

import { useAuthentication } from '@ravago/shared/authentication';

const LOCAL_STORAGE_KEY = 'okta_id';

const GTMUserProvider = ({ children }: PropsWithChildren) => {
  const { user, loading } = useAuthentication();

  useEffect(() => {
    if (loading) return;

    if (!user && localStorage.getItem(LOCAL_STORAGE_KEY)) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else if (user && 'uid' in user && typeof user.uid === 'string' && localStorage.getItem(LOCAL_STORAGE_KEY) !== user.uid) {
      localStorage.setItem(LOCAL_STORAGE_KEY, user.uid);
    }
  }, [user, loading]);

  return children;
};

export default GTMUserProvider;
