import { FC, ReactNode, useMemo } from 'react';

import classnames from 'classnames';

interface Props {
   variant?: 'primary' | 'secondary' | 'dropdown';
   size?: 'small' | 'medium' | 'large';
   height?: string;
   showGutter?: boolean;
   container?: boolean;
   left?: ReactNode;
   right?: ReactNode;
   fullWidth?: boolean;
   overflow?: 'auto' | 'visible';
}

export const Toolbar: FC<Props> = ({ variant = 'primary', height, size, showGutter = true, left, right, container = false, fullWidth = false, overflow = 'auto' }) => {
   const visualSize = useMemo(() => {
      if (height) return undefined;
      if (size) return size;

      const sizeMapping: { [key in typeof variant]?: Props['size'] } = {
         primary: 'medium',
         secondary: 'medium',
         dropdown: undefined
      };

      return sizeMapping[variant];
   }, [height, size, variant]);

   return (
      <div
         data-testid="toolbar-box"
         className={classnames(
            'box-border',
            fullWidth && 'w-full',
            visualSize === 'small' && 'h-component-navigation-navbar-toolbar-container-sizing-min-height-sm',
            visualSize === 'medium' && 'h-component-navigation-navbar-toolbar-container-sizing-min-height-md',
            visualSize === 'large' && 'h-component-navigation-navbar-toolbar-container-sizing-min-height-lg',
            variant === 'dropdown' && 'w-fit',
            variant === 'dropdown' && overflow === 'auto' && 'rounded-b-component-navigation-navbar-toolbar-container-border-radius-bottom-dropdown shadow-theme-box-shadow-elevation-1',
            variant !== 'dropdown' && 'border-component-navigation-navbar-toolbar-container-color-border border-b-component-navigation-navbar-toolbar-container-border-width w-full',
            overflow === 'auto' && 'overflow-y-auto'
         )}
         style={{ height }}
      >
         <div data-testid={`toolbar${container ? '--container' : ''}${fullWidth ? '--full-width' : ''}`} className="size-full">
            <div
               data-testid={`toolbar--${variant}${visualSize ? `--${visualSize}` : ''}${showGutter ? '--gutter' : ''}`}
               className={classnames(
                  'size-full',
                  variant === 'primary' && 'bg-component-navigation-navbar-toolbar-container-color-background-default',
                  variant === 'secondary' && 'bg-component-navigation-navbar-toolbar-container-color-background-alternate',
                  variant === 'dropdown' && 'bg-theme-color-surface-1 py-component-navigation-navbar-toolbar-container-spacing-padding-y-dropdown',
                  variant === 'dropdown' && overflow === 'visible' && 'rounded-b-component-navigation-navbar-toolbar-container-border-radius-bottom-dropdown shadow-theme-box-shadow-elevation-1',
                  showGutter && !container && variant !== 'dropdown' && 'px-component-navigation-navbar-toolbar-container-spacing-padding-x-default',
                  showGutter && !container && variant === 'dropdown' && 'px-component-navigation-navbar-toolbar-container-spacing-padding-x-dropdown'
               )}
            >
               <div className={`flex size-full items-center justify-between ${container ? 'container' : 'w-full'}`}>
                  {left && (
                     <div data-testid="toolbar-left" className={classnames('flex h-full justify-start', { 'flex-1': variant === 'dropdown' || !right })}>
                        {left}
                     </div>
                  )}
                  {right && (
                     <div data-testid="toolbar-right" className={classnames('flex h-full justify-end', { 'flex-1': variant === 'dropdown' || !left })}>
                        {right}
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};
