import { User, UserGroups } from '@ravago/shared/authentication';
import { NavigationDropdown, NavigationGroup, NavigationItem } from '@ravago/shared/page-data/models/config';

export class NavigationUtils {
  static filterItemsByAccessLevel<T extends NavigationItem | NavigationGroup | NavigationDropdown>(items: Array<T>, user?: User): Array<T> {
    return items.filter((item) => {
      if (item.accessType === 'Public' || !item.accessType) return true;

      if (!user) return false;
      if (item.accessType === 'CSR Only' && user.groups.includes(UserGroups.ravagoSalesRepresentative)) return true;

      return item.accessType === 'Authenticated Only';
    }) as Array<T>;
  }

  static buildRoute(locale: string, navigationItem: NavigationItem) {
    let route = navigationItem.page?.route ? `/${locale}/${navigationItem.page.route}` : navigationItem.externalUrl ?? '';
    if (navigationItem.slug) {
      route = route.replace(':slug', navigationItem.slug);
    }
    if (navigationItem.queryParameters) {
      route += `?${navigationItem.queryParameters}`;
    }
    return route;
  }
}
