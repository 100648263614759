'use client';

import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

import { useExpandOnScroll } from './hooks/use-expanded';

interface Props extends PropsWithChildren {
   badge?: string;
   divided?: boolean;
   leadingIcon?: string;
   open?: boolean;
   onClick?: () => void;
   onClose?: () => void;
   onOpen?: () => void;
}

const expandTimeoutDuration = 100;

export const FabButton: FC<Props> = ({ open = false, leadingIcon, divided = false, onClick, onClose, onOpen, children, badge }) => {
   const [isOpen, setIsOpen] = useState(open);
   const isExpanded = useExpandOnScroll(expandTimeoutDuration);

   const handleOpen = () => {
      setIsOpen(true);
      if (onOpen) onOpen();
      else onClick?.();
   };

   const handleClose = () => {
      setIsOpen(false);
      onClose?.();
   };

   useEffect(() => setIsOpen(open), [open]);

   const buttonContent = (
      <div
         className={`pl-component-input-fab-button-container-left-spacing-padding-left py-component-input-fab-button-container-left-spacing-padding-y gap-component-input-fab-button-container-left-spacing-gap inline-flex items-center transition-all
                  ${divided ? 'pr-component-input-fab-button-container-left-spacing-padding-right-action' : 'pr-component-input-fab-button-container-left-spacing-padding-right-default'}
                  ${/* istanbul ignore next */ !divided && !isExpanded ? 'pr-0' : ''}
              `}
      >
         {leadingIcon && (
            <div data-testid="fab-button--leading-icon" className="text-component-input-fab-button-icon-color-fill contents">
               <Icon icon={leadingIcon} color="inherit" />
            </div>
         )}
         {children && (
            <div data-testid="fab-button--label" className={`text-component-input-fab-button-label-color-text overflow-hidden text-left transition-all ${/* istanbul ignore next */ !isExpanded ? '-ml-component-input-fab-button-container-left-spacing-gap w-0 opacity-0' : ''}`}>
               <Typography variant="body-highlight" color="inherit" maxLines="1" whiteSpace="nowrap">
                  {children}
               </Typography>
            </div>
         )}
         {badge && divided && (
            <div data-testid="fab-button--badge" className="contents">
               <Badge variant="secondary">{badge}</Badge>
            </div>
         )}
      </div>
   );

   if (!divided)
      return (
         <button
            type="button"
            data-testid={`fab-button${isOpen ? '--open' : ''}`}
            onClick={isOpen ? handleClose : handleOpen}
            className="min-h-component-input-fab-button-container-sizing-min-height bg-component-input-fab-button-container-color-background rounded-component-input-fab-button-container-border-radius shadow-component-input-fab-button-container-elevation hover:after:bg-component-input-fab-button-state-layer-left-color-background-hover focus-visible:after:bg-component-input-fab-button-state-layer-left-color-background-focused active:after:bg-component-input-fab-button-state-layer-left-color-background-pressed after:rounded-component-input-fab-button-container-border-radius relative box-border inline-flex items-center transition-all after:absolute after:inset-0 after:transition-all"
         >
            {!isOpen && buttonContent}
            <div className="min-w-component-input-fab-button-container-right-sizing-min-width text-component-input-fab-button-icon-color-fill p-component-input-fab-button-container-right-spacing-padding box-border flex aspect-square h-full items-center justify-center">
               <Icon icon={isOpen ? 'times' : 'chevron-up'} color="inherit" />
            </div>
         </button>
      );

   return (
      <div
         data-testid={`fab-button--divided${isOpen ? '--open' : ''}`}
         className="min-h-component-input-fab-button-container-sizing-min-height bg-component-input-fab-button-container-color-background rounded-component-input-fab-button-container-border-radius shadow-component-input-fab-button-container-elevation after:rounded-component-input-fab-button-container-border-radius relative box-border inline-flex transition-all"
      >
         {!isOpen && (
            <>
               <button
                  data-testid="fab-button--action"
                  type="button"
                  onClick={onClick}
                  className="hover:after:bg-component-input-fab-button-state-layer-left-color-background-hover focus-visible:after:bg-component-input-fab-button-state-layer-left-color-background-focused active:after:bg-component-input-fab-button-state-layer-left-color-background-pressed after:rounded-l-component-input-fab-button-container-border-radius relative items-center transition-all after:absolute after:inset-0 after:transition-all"
               >
                  {buttonContent}
               </button>
               <hr className="border-r-component-input-fab-button-divider-sizing-width border-r-component-input-fab-button-divider-color-fill h-auto border-t-0" />
            </>
         )}

         <button
            type="button"
            onClick={isOpen ? handleClose : handleOpen}
            className={`min-w-component-input-fab-button-container-right-sizing-min-width text-component-input-fab-button-icon-color-fill p-component-input-fab-button-container-right-spacing-padding hover:after:bg-component-input-fab-button-state-layer-right-color-background-hover focus-visible:after:bg-component-input-fab-button-state-layer-right-color-background-focused active:after:bg-component-input-fab-button-state-layer-right-color-background-pressed relative box-border flex aspect-square h-full items-center justify-center after:absolute after:inset-0  after:transition-all ${
               isOpen ? 'after:rounded-component-input-fab-button-container-border-radius' : 'after:rounded-r-component-input-fab-button-container-border-radius'
            }`}
         >
            <Icon icon={isOpen ? 'times' : 'chevron-up'} color="inherit" />
         </button>
      </div>
   );
};
